import React from 'react';

class CustomQueueForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
    };
  }

  handleFieldChange = (field, val) => {
    const value = val !== '' ? val : `Queued orders__${new Date().toISOString()}`;
    this.setState({ name: value });
    this.props.onChange(value);
  };

  render() {
    const { name } = this.state;

    return (
      <div className="fieldEditor">
        <label htmlFor="limit">
          Custom Queue Name
          <input
            type="text"
            name="name"
            id="name"
            value={name}
            onChange={event => this.handleFieldChange('name', event.target.value)}
          />
        </label>
      </div>
    );
  }
}

export default CustomQueueForm;
