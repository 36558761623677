import React from 'react';

const {
  Formatters: { ImageFormatter },
} = require('react-data-grid-addons');

const MerchantLogoFormatter = ({ value }) => {
  if (!value) {
    return null;
  }

  let image = value;

  if (typeof value === 'number') {
    image = value === 2 ? 'paypal' : 'adyen';
  }

  const methodLogo = `https://checkoutshopper-live.adyen.com/checkoutshopper/images/logos/${image}.svg`;
  return (
    <span style={{ height: '26px', overflow: 'hidden', display: 'block' }}>
      <ImageFormatter value={methodLogo} />
    </span>
  );
};

export default MerchantLogoFormatter;
