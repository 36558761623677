import React, { Component } from 'react';
import DataGrid from 'components/forms/DataGrid';
import { ConvertTimestamp2Date } from 'components/helpers/formatters';
import DataUtils from 'components/helpers/utils/data';
import eventSchedulerActions from 'actions/eventSchedulerActions';

class DetailScheduledEventsTab extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        key: 'triggerAt',
        name: 'triggerAt',
        width: 100,
        sortable: true,
        filterable: true,
        resizable: true,
        formatter: ConvertTimestamp2Date,
      },
      {
        key: 'orderId',
        name: 'orderId',
        width: 170,
        sortable: true,
        filterable: true,
        resizable: true,
      },
      {
        key: 'event',
        name: 'event',
        width: 180,
        sortable: true,
        filterable: true,
        resizable: true,
      },
    ];
    this.state = {
      rows: undefined,
    };
  }

  async componentDidMount() {
    try {
      const data = await eventSchedulerActions.getEvents(this.props.uid);
      const rows = [];
      Object.keys(data).forEach(key => {
        rows.push({ orderId: key, ...DataUtils.flattenObject(data[key]) });
      });
      console.info(rows);
      rows.sort(DataUtils.dynamicSort('triggerAt'));
      this.props.count(rows.length);

      this.setState({
        rows,
      });
    } catch (e) {
      console.error(e);
    }
  }

  render() {
    return <DataGrid columns={this.columns} rows={this.state.rows} print={false} csv={false} />;
  }
}

export default DetailScheduledEventsTab;
