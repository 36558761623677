import React, { Component } from 'react';
import { IStoryStatus } from '@klikkie/klikkie-schemas/lib/interfaces/Story';
import renderActions from 'actions/renderActions';
import fulfillmentActions from 'actions/fulfillmentActions';
import './_FulfillmentContentPanelAlbum.scss';
import storyActions from 'actions/photobookActions';
import { cloudStoragePicturesURL, storagePictures } from 'config/base';
import Spinner from 'components/helpers/Spinner';
import TableEmpty from 'components/helpers/TableEmpty';
import { GenericCopyButton } from 'components/buttons';
import FulfillmentContentPanelAlbumEditor from './FulfillmentContentPanelAlbumEditor';

class FulfillmentContentPanelAlbum extends Component {
  constructor(props) {
    super(props);
    this.state = {
      json: null,
      editMode: true,
      selectedSpread: 'cover',
      link: null,
      story: null,
    };
  }

  componentDidMount = async () => {
    try {
      const { album, storyId } = this.props;
      const story = await this.fetchStory(storyId);
      const albumWithFallback = window.location.href.includes('photobook')
        ? `albums/${story.userId}/${story.id}/${story.id}_Peecho.pdf`
        : album;
      try {
        const link = albumWithFallback ? await storagePictures.ref(albumWithFallback).getDownloadURL() : null;
        this.setState({ link });
      } catch (error) {
        console.error('Failed to load PDF', error);
      }
    } catch (error) {
      console.error(error);
    }
  };

  fetchStory = async storyId => {
    try {
      const { selectedSpread } = this.state;
      const story = await storyActions.getStory(storyId);
      this.setState({ story });
      this.getEditPage(selectedSpread);
      return story;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  componentDidUpdate = prevProps => {
    if (prevProps.storyId !== this.props.storyId) {
      this.fetchStory(this.props.storyId);
    }
    if (prevProps.album !== this.props.album) {
      storagePictures
        .ref(this.props.album)
        .getDownloadURL()
        .then(link => {
          this.setState({ link });
        })
        .catch(error => {
          console.error('Failed to load album link', error);
        });
    }
  };

  currentTimestamp = () => {
    const date = new Intl.DateTimeFormat('en', {
      day: 'numeric',
      month: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hourCycle: 'h23',
    }).format(new Date());
    return date;
  };

  renderPhotoBookSpread = async () => {
    const { id: queueId, storyId } = this.props;
    const { selectedSpread } = this.state;

    try {
      await renderActions.renderPhotoBookSpread(storyId, selectedSpread);

      this.props.toastOpen('Rendering finished.');
      await fulfillmentActions.updateQueue(queueId, {
        comment: `Photo book spread rendered successfully ${this.currentTimestamp()}`,
      });
      return this.setState({ editMode: false });
    } catch (error) {
      console.error(error);
      await fulfillmentActions.updateQueue(queueId, {
        comment: `Photo book spread render failed ${this.currentTimestamp()} ${JSON.stringify(error)}`,
      });
      return this.props.toastOpen(error, 'error');
    }
  };

  renderPhotoBook = async () => {
    const { storyId, toastOpen } = this.props;

    try {
      toastOpen('Started rendering, grab a ☕!');
      const link = await renderActions.renderPhotoBook(storyId);
      this.setState({ link }, () => {
        this.setState({ editMode: false });
      });

      return toastOpen('Rendering finished.');
    } catch (error) {
      console.error(error);
      return toastOpen(error, 'error');
    }
  };

  updatePage = async json => {
    try {
      const { toastOpen, storyId } = this.props;
      const { selectedSpread } = this.state;
      await storyActions.updateStoryPage(storyId, selectedSpread, json);
      this.setState({
        json,
      });

      toastOpen('Saved page and started rendering (10 seconds)...');
      return this.renderPhotoBookSpread();
    } catch (error) {
      console.error(error);
      return this.props.toastOpen(error, 'error');
    }
  };

  updateCover = async pdfCover => {
    try {
      const { storyId } = this.props;
      const newStory = { pdfCover };

      await storyActions.updateStory(storyId, newStory);

      return this.props.toastOpen('Saved cover');
    } catch (error) {
      return this.props.toastOpen(error, 'error');
    }
  };

  sendToSupplier = async (render, idempotent) => {
    try {
      const { id } = this.props;
      this.props.toastOpen('Resending album to Supplier.');

      await fulfillmentActions.sendOrderToSupplier(id, render, idempotent);

      return this.props.toastOpen('Album sent to Supplier');
    } catch (error) {
      return this.props.toastOpen(error, 'error');
    }
  };

  getEditPage = async pageId => {
    try {
      const { storyId } = this.props;
      this.setState({ json: null });
      const json =
        pageId === 'cover'
          ? await storyActions.getStoryCover(storyId)
          : (await storyActions.getStoryPage(storyId, pageId))[0];
      this.setState({
        json,
        selectedSpread: pageId,
      });
    } catch (error) {
      console.error(error);
    }
  };

  getPrevPage = () => {
    const { selectedSpread } = this.state;
    const { story } = this.state;
    if (selectedSpread === 'cover') return this.getEditPage(story.order[story.order.length - 1]);

    const currentIndex = story.order.indexOf(selectedSpread);
    const nextPageIndex = currentIndex - 1;
    const nextPage = nextPageIndex === -1 ? 'cover' : story.order[nextPageIndex];

    return this.getEditPage(nextPage);
  };

  getNextPage = () => {
    const { selectedSpread } = this.state;
    const { story } = this.state;
    if (selectedSpread === 'cover') return this.getEditPage(story.order[0]);
    const currentIndex = story.order.indexOf(selectedSpread);
    const nextPageIndex = currentIndex + 1;
    const nextPage = nextPageIndex === story.order.length ? 'cover' : story.order[nextPageIndex];

    return this.getEditPage(nextPage);
  };

  changeStoryStatus = async () => {
    const {
      story: { id, status },
    } = this.state;
    const nextStatus = status === IStoryStatus.Ready ? IStoryStatus.Closed : IStoryStatus.Ready;
    const [nextStory] = await storyActions.updateStory(id, { status: nextStatus });
    this.setState({ story: nextStory });
  };

  resubmitOrder = async () => {
    const { orderApiId } = this.props;
    try {
      this.props.toastOpen('Resubmitting order...');
      await fulfillmentActions.addOrderToQueue(orderApiId);
      window.location.reload();
    } catch (error) {
      this.props.toastOpen(error, 'error');
    }
  };

  render() {
    const { editMode, selectedSpread, json, link, story } = this.state;
    const { userId, id } = this.props;

    return story ? (
      <div className="FulfillmentContentPanelAlbum">
        <div className="taskbar">
          <span className="header">PHOTO BOOK</span>
          {story?.id && <GenericCopyButton title="Copy story id" text="Story id" value={story.id} />}
          <button className="button" type="button" onClick={() => this.setState({ editMode: !editMode })}>
            {editMode ? 'Edit V̶i̶e̶w̶' : 'E̶d̶i̶t̶ View'}
          </button>
          <button className="button" type="button" onClick={this.changeStoryStatus}>
            {story && story.status === IStoryStatus.Ready ? 'Close 🔒' : 'Open 🔓'}
          </button>
          {!id && (
            <button className="button" type="button" onClick={() => this.renderPhotoBook()}>
              Render
            </button>
          )}
          <a
            className="button"
            type="button"
            href={`https://console.cloud.google.com/firestore/data/panel/storiesV2/${story.id}/pages/${selectedSpread}`}
            target="_blank"
            rel="noreferrer"
          >
            DB
          </a>
          <a
            className="button"
            type="button"
            href={`${cloudStoragePicturesURL}/albums/${userId}/${story.id}`}
            target="_blank"
            rel="noreferrer"
          >
            Open Files
          </a>
          <a
            className="button"
            href={`https://www.peecho.com/dashboard/orders?page=0&query=${id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Dashboard
          </a>
        </div>
        {!editMode && link && (
          <div className="preview">
            <iframe
              src={`${link}#view=Fit&page=${story.order.findIndex(e => e === selectedSpread) * 2 + 2}`}
              title="title"
            />
          </div>
        )}
        {!editMode && !link && <TableEmpty />}
        {editMode && (
          <div className="editor">
            <div className="pages">
              <button type="button" onClick={this.getPrevPage}>
                Previous page
              </button>

              <select value={selectedSpread} onChange={val => this.getEditPage(val.target.value)}>
                <option value="cover" key="cover_option" id="cover">
                  Cover
                </option>
                {story?.order &&
                  story.order.map((pageId, i) => {
                    return (
                      <option value={pageId} key={`${pageId}_option`}>
                        Spread {i + 1}
                      </option>
                    );
                  })}
                {!story?.order && (
                  <option disabled key="nopages_option">
                    No pages found
                  </option>
                )}
              </select>
              <button type="button" onClick={this.getNextPage}>
                Next page
              </button>
            </div>
            {!json && <Spinner />}
            {json && (
              <FulfillmentContentPanelAlbumEditor json={json} saveCover={this.updateCover} savePage={this.updatePage} />
            )}
          </div>
        )}
      </div>
    ) : (
      <div>
        <h1>Photobook not added to fulfillment order</h1>
        <button type="button" onClick={this.resubmitOrder}>
          Resubmit order
        </button>
      </div>
    );
  }
}

export default FulfillmentContentPanelAlbum;
