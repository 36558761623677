import React from 'react';
import countries from 'i18n-iso-countries';
import dymoActions from 'actions/dymoActions';

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

class ProductQueuePrintCouponLabelButton extends React.Component {
  notify = () => {
    console.info(`\n\n${this.props.value}`);
    this.ticket.showModal();
  };

  // send to print
  printLabels = async () => {
    try {
      const { data } = this.props;
      if (!data || data.length === 0) return alert('Please select a row in order to print the label(s)');
      console.info('PRINT LABEL', data);
      const printResults = await Promise.all(data.map(this.printLabel));
      const printedLabels = printResults.filter(Boolean).length;

      return setTimeout(() => {
        this.props.toastOpen(`🖨 ${printedLabels}/${data.length} address labels are sent to the printer`);
        clearTimeout();
      }, 2000);
    } catch (error) {
      console.error(error);
      return this.props.toastOpen(error, 'error');
    }
  };

  generateLabel = row => {
    const address = row.address.address ? row.address : row;
    const country = countries.getName(address.countryCode, 'en').toUpperCase() || '';
    const label = {
      sku: row.packageId ? row.packageId : '',
      oid: row.orderId ? row.orderId : '',
      uid: row.userId ? row.userId : '',
      street: address.address2 && address.address2 !== '' ? `${address.address}-${address.address2}` : address.address,
      city: address.city.toUpperCase() || address.municipality || address.municipalityName || '',
      zipcode: address.countryCode === 'NL' ? this.formatNLZipcode(address.zipcode) : address.zipcode || '',
      fullname: address.fullname || '',
      country: address.countryCode === 'NL' ? '' : country,
      couponCode: row.couponCode || '',
      ids: row.ids || '',
      kix: row.kix,
      id: row.id || '',
    };

    return label;
  };

  formatNLZipcode = zipcode =>
    zipcode
      ? `${zipcode.substring(0, 4)} ${zipcode
          .replace(' ', '')
          .substring(4, 6)
          .toUpperCase()}`
      : '';

  printLabel = async data => {
    try {
      if (!data.address && !data.street) throw new Error(`No address found for order ${data.orderId}`);
      const row = data.address.address ? data.address : data;
      row.packageId = data.packageId;
      row.couponCode = data.couponCode;
      row.kix = data.metadata ? data.metadata.kix : '';
      const label = this.generateLabel(row);
      label.ids = data.id;
      dymoActions.printCodeLabel(window, label, this.props.labelType);
      return true;
    } catch (error) {
      console.error(error);
      this.props.toastOpen(error, 'error');
      return false;
    }
  };

  render() {
    return (
      <span>
        <button type="button" className="button" onClick={this.printLabels}>
          Print Labels {this.props.name}
        </button>
      </span>
    );
  }
}
export default ProductQueuePrintCouponLabelButton;
