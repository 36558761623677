import React from 'react';

class GenericStatusSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: '',
    };
  }

  changeStatus = event => {
    const status = event.target.value;
    if (status === '') return;
    const r = window.confirm(`Are you sure you want to change the status to ${status}?`);
    if (!r) return;

    this.props.changeStatus(status);
    this.setState({ status: '' });
  };

  render() {
    return (
      <select id="select" onChange={this.changeStatus} value={this.state.status}>
        <option value="">Change status</option>
        {this.props.exclude !== 'pending' && <option value="pending">Pending</option>}
        {this.props.exclude === 'pending' && <option value="pending">Rerender &amp; Pending</option>}
        {this.props.exclude !== 'fulfilled' && <option value="fulfilled">Fulfilled</option>}
        {this.props.exclude !== 'rejected' && <option value="rejected">Rejected</option>}
      </select>
    );
  }
}

export default GenericStatusSelect;
