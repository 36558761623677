import api from 'components/helpers/utils/api';
import { apiBaseUrl, database } from 'config/base';
import countries from 'i18n-iso-countries';

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

export default {
  buildAddressObject(data) {
    if (data) {
      const address = {};
      address.fullname = this.exists(data, 'fullname') || data.fullname;
      address.zipcode = this.exists(data, 'zipcode') || data.zipcode;
      address.address = data.address || '';
      if (this.exists(data, 'address2')) {
        address.address2 = data.address2;
      }
      address.city = data.city;
      address.region = data.region ? data.region : undefined;
      address.countryCode = this.exists(data, 'countryCode') || data.countryCode;
      return address;
    }
    return false;
  },

  buildGiftAddressDymoLabel(address) {
    let addressStr = '';

    if (address.fullname) {
      addressStr += `${address.fullname}\n`;
    } else {
      addressStr += address.name;
      addressStr += address.surname ? ` ${address.surname}\n` : '\n';
    }

    addressStr += `${address.street}`;
    addressStr += address.houseNumber ? ` ${address.houseNumber}` : '';
    addressStr += address.houseNumberAddition ? `-${address.houseNumberAddition} \n` : '\n';

    addressStr += `${address.zipcode}  ${address.city}\n`;
    addressStr += address.region ? `${address.region}\n` : '';

    addressStr += address.country;

    return addressStr;
  },

  exists(node, childNode) {
    return node === undefined ? '' : node[childNode] || '';
  },

  capitalize(s) {
    return s && s[0].toUpperCase() + s.slice(1);
  },

  getAddresses(userId) {
    return api.fetchAsAdmin('GET', `${apiBaseUrl}/addresses/${userId}`);
  },

  getAddress(userId, addressId) {
    return api.fetchAsAdmin('GET', `${apiBaseUrl}/addresses/${userId}/${addressId}`).then(r => r[0]);
  },

  getDutchAddressFromPostcodeNL(addressString) {
    return api.fetchAsAdmin('GET', `${apiBaseUrl}/postcode/autocomplete/NLD/${addressString}`);
  },

  createAddress(userId, address) {
    return api.fetchAsAdmin('POST', `${apiBaseUrl}/addresses/${userId}/`, address);
  },

  updateAddress(userId, addressId, address) {
    return api.fetchAsAdmin('PUT', `${apiBaseUrl}/addresses/${userId}/${addressId}`, address);
  },

  deleteAddress(userId, addressId) {
    return api.fetchAsAdmin('DELETE', `${apiBaseUrl}/addresses/${userId}/${addressId}`);
  },
  async setUserActiveAddress(userId, addressId) {
    return database
      .ref('/user')
      .child(userId)
      .child('metadata')
      .update({ activeAddress: addressId })
      .catch(console.error);
  },
  async updateActiveAddress(userId, newAddress) {
    return database
      .ref('user')
      .child(userId)
      .child('address')
      .update(newAddress)
      .catch(console.error);
  },
};
