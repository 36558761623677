import React from 'react';
import { consoleUrl } from 'config/base';

const PhotobookDbLink = props => {
  return (
    <a href={`${consoleUrl}firestore/data/~2FstoriesV2~2F${props.value}`} target="_blank" rel="noopener noreferrer">
      {props.value}
    </a>
  );
};

export default PhotobookDbLink;
