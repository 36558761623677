/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-return-assign */
/* eslint-disable vars-on-top */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
export default {
  dynamicSort(property, sortOrder = 1) {
    let newProperty = property;
    if (newProperty[0] === '-') {
      sortOrder = -1;
      newProperty = newProperty.substr(1);
    }
    return (a, b) => {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      // eslint-disable-next-line no-nested-ternary
      const result = a[newProperty] < b[newProperty] ? -1 : a[newProperty] > b[newProperty] ? 1 : 0;
      return result * sortOrder;
    };
  },
  dynamicSortMultiple() {
    /*
     * save the arguments object as it will be overwritten
     * note that arguments object is an array-like object
     * consisting of the names of the properties to sort by
     */
    // eslint-disable-next-line prefer-rest-params
    const props = arguments;
    return (obj1, obj2) => {
      let i = 0;
      let result = 0;
      const numberOfProperties = props.length;
      /* try getting a different result from 0 (equal)
       * as long as we have extra properties to compare
       */
      while (result === 0 && i < numberOfProperties) {
        result = this.dynamicSort(props[i])(obj1, obj2);
        i += 1;
      }
      return result;
    };
  },
  flattenObject(data) {
    const result = {};
    function recurse(cur, prop) {
      if (Object(cur) !== cur) {
        result[prop] = cur;
      } else if (Array.isArray(cur)) {
        for (let i = 0, l = cur.length; i < l; i += 1) recurse(cur[i], `${prop}[${i}]`);
        if (cur.length === 0) result[prop] = [];
      } else {
        let isEmpty = true;
        for (const p in cur) {
          isEmpty = false;
          recurse(cur[p], prop ? `${prop}.${p}` : p);
        }
        if (isEmpty && prop) {
          result[prop] = {};
        }
      }
    }
    recurse(data, '');
    return result;
  },
  unflattenObject(data) {
    const result = {};
    for (const i in data) {
      const keys = i.split('.');
      keys.reduce(
        (r, e, j) => r[e] || (r[e] = isNaN(Number(keys[j + 1])) ? (keys.length - 1 === j ? data[i] : {}) : []),
        result,
      );
    }
    return result;
  },
};
