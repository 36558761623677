import React, { Component } from 'react';
import orderActions from 'actions/orderActions';
import picturesActions from 'actions/picturesActions';
import fulfillmentActions from 'actions/fulfillmentActions';

class FulfillmentContentPanelOriginalPictures extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pictures: {},
    };
  }

  componentDidMount = () => this.getData();

  getData = async () => {
    const { userId, orderId } = this.props;
    const pictureOrder = await orderActions.getPictureOrder(userId, orderId);

    if (pictureOrder.length === 0 || !pictureOrder[0] || !pictureOrder[0].pictures) return;
    const { pictures } = pictureOrder[0];
    Object.entries(pictures).map(async ([key, value]) => {
      const userPic = await picturesActions.getUserPicture(userId, value.userPictureId);
      if (!userPic) return alert(`User picture not found: ${key} ${value.userPictureId}`);
      pictures[key].userPicture = await picturesActions.getDownloadURL(userPic.originalRef);
      pictures[key].croppedPicture = await picturesActions.getDownloadURL(pictures[key].renderedRef);
      return this.setState({ pictures });
    });
  };

  deleteRenderedRef = async (key, value) => {
    try {
      const { userId, orderId } = this.props;
      const data = {
        pictures: {
          [key]: value,
        },
      };
      data.pictures[key].renderedRef = '';
      delete data.pictures[key].croppedPicture;
      delete data.pictures[key].userPicture;
      await orderActions.putPictureOrder(userId, orderId, data);
      this.props.toastOpen(`Succesfully removed the cropped version of ${key}`);
      this.getData();
    } catch (error) {
      console.error(error);
    }
  };

  renderSinglePicture = async key => {
    try {
      const { userId, orderId, fulfillmentId } = this.props;

      this.props.toastOpen('start rendering ...');

      await fulfillmentActions.renderSingleImageOfPictureOrder(userId, orderId, fulfillmentId, key);
      this.props.toastOpen('image rendered.');

      window.location.reload();
      return '';
    } catch (error) {
      return this.props.toastOpen(error, 'error');
    }
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUpdate = async newProps => {
    if (newProps.userId === this.props.userId) return;
    if (newProps.orderId === this.props.orderId) return;
    await this.getData();
  };

  render() {
    const { pictures } = this.state;

    return (
      <>
        {Object.keys(pictures).length < 1 && <div>We could not find any pictures</div>}

        <table style={{ border: '1px solid black', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <th style={{ border: '1px solid black', borderCollapse: 'collapse' }}>Key</th>
              <th style={{ border: '1px solid black', borderCollapse: 'collapse' }}>TemplateId</th>
              <th style={{ border: '1px solid black', borderCollapse: 'collapse' }}>Cropped Photo</th>
              <th style={{ border: '1px solid black', borderCollapse: 'collapse' }}>Original Photo</th>
              <th style={{ border: '1px solid black', borderCollapse: 'collapse' }}>render</th>
            </tr>
          </thead>
          <tbody>
            {pictures &&
              Object.entries(pictures).map(([key, value]) => (
                <tr key={key}>
                  <td style={{ border: '1px solid black', borderCollapse: 'collapse' }}>{key}</td>
                  <td style={{ border: '1px solid black', borderCollapse: 'collapse' }}>{value.templateId}</td>

                  <td
                    style={{ border: '1px solid black', borderCollapse: 'collapse' }}
                    className="picture-container-small"
                    key={key + value.templateId}
                  >
                    {value.renderedRef && value.renderedRef.length > 0 && (
                      <span>
                        <a href={value.croppedPicture} target="_blank" rel="noopener noreferrer">
                          <img src={value.croppedPicture} alt={`Original renderedRef ${value.templateId}`} />
                        </a>
                        <button type="button" onClick={() => this.deleteRenderedRef(key, value)}>
                          Delete
                        </button>
                      </span>
                    )}
                  </td>
                  <td
                    style={{ border: '1px solid black', borderCollapse: 'collapse' }}
                    className="picture-container-small"
                    key={key + value}
                  >
                    {value.userPicture && (
                      <a href={value.userPicture} target="_blank" rel="noopener noreferrer">
                        <img src={value.userPicture} alt={`Original userPicture ${value.templateId}`} />
                      </a>
                    )}
                  </td>
                  <td style={{ border: '1px solid black', borderCollapse: 'collapse' }}>
                    <button
                      type="button"
                      onClick={() => {
                        this.renderSinglePicture(key);
                      }}
                    >
                      Render again
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </>
    );
  }
}

export default FulfillmentContentPanelOriginalPictures;
