/* eslint-disable max-classes-per-file */
import React, { Component } from 'react';
import { Row } from 'react-data-grid';
import DataGrid from 'components/forms/DataGrid';
import { ConvertTimestamp2Date } from 'components/helpers/formatters';
import photobookActions from 'actions/photobookActions';
import PhotobookDbLink from 'components/helpers/formatters/photobookDbLink';
import PhotobookLink from 'components/helpers/formatters/photoBookLink';
import GenericButtonSplit from 'components/buttons/GenericButtonSplit';

// Custom Formatter component
class RowRenderer extends React.Component {
  getRowStyle = () => ({
    backgroundColor: this.getRowBackground(),
  });

  getRowBackground = () => {
    let color = '';

    if (this.props.row.received === '❌') {
      color = '#f3b2b2';
    }

    if (this.props.row.received === '✅') {
      color = '#c3f3e4';
    }

    return color;
  };

  render() {
    return (
      <div style={this.getRowStyle()}>
        <Row
          ref={node => {
            this.row = node;
          }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...this.props}
        />
      </div>
    );
  }
}

class DetailPhotobooksTab extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        key: 'createdAt',
        name: 'Created',
        width: 100,
        sortable: true,
        filterable: true,
        resizable: true,
        formatter: ConvertTimestamp2Date,
      },
      {
        key: 'updatedAt',
        name: 'Updated',
        width: 100,
        sortable: true,
        filterable: true,
        resizable: true,
        formatter: ConvertTimestamp2Date,
      },
      {
        key: 'title',
        name: 'Title',
        width: 170,
        sortable: true,
        filterable: true,
        resizable: true,
      },
      {
        key: 'id',
        name: 'Id',
        width: 180,
        sortable: true,
        filterable: true,
        resizable: true,
        formatter: PhotobookDbLink,
      },
      {
        key: 'spreads',
        name: 'Pages',
        width: 180,
        sortable: true,
        filterable: true,
        resizable: true,
      },
      {
        key: 'preview',
        name: 'Preview',
        width: 180,
        sortable: true,
        filterable: true,
        resizable: true,
        formatter: PhotobookLink,
      },
    ];
    this.state = {
      rows: undefined,
    };
  }

  async componentDidMount() {
    try {
      const rows = await photobookActions.getByUserId(this.props.uid);
      this.props.count(rows.length);

      this.setState({
        rows,
      });
    } catch (e) {
      console.error(e);
    }
  }

  copyPhotoBook = async () => {
    try {
      if (!this.state.selection || this.state.selection.length === 0) {
        return this.props.toastOpen('Please select a row in order to copy a photo book', 'error');
      }
      this.state.selection.forEach(row => {
        photobookActions.copyStory(row.id);
        this.props.toastOpen(`Photo book copied ${row.id}`);
      });
      return true;
    } catch (error) {
      console.error(error);
      return this.props.toastOpen(error);
    }
  };

  deletePhotoBook = async () => {
    try {
      if (!this.state.selection || this.state.selection.length === 0) {
        return this.props.toastOpen('Please select a row in order to delete a photobook', 'error');
      }
      this.state.selection.forEach(row => {
        photobookActions.deleteStory(row.id);
        this.props.toastOpen(`Photo book deleted ${row.id}`);
      });
      return true;
    } catch (error) {
      console.error(error);
      return this.props.toastOpen(error);
    }
  };

  render() {
    return (
      <span>
        <DataGrid
          columns={this.columns}
          rows={this.state.rows}
          print={false}
          rowRenderer={RowRenderer}
          enableCellSelect
          getSelection={selection => this.setState({ selection })}
          extraTools={
            <span>
              <GenericButtonSplit />
              <button type="button" className="button" onClick={this.deletePhotoBook}>
                Delete Photo Book
              </button>
              <button type="button" className="button" onClick={this.copyPhotoBook}>
                Copy Photo Book
              </button>
            </span>
          }
        />
      </span>
    );
  }
}

export default DetailPhotobooksTab;
