import api from 'components/helpers/utils/api';
import { apiBaseUrl, database, config } from 'config/base';

export default {
  async getActiveTotal() {
    const data = await api.fetchAsAdmin(
      'GET',
      `${apiBaseUrl}/customerio/siteId/${config.customerioSiteId}/segment/${config.customerioSegment}/count`,
    );
    return data;
  },
  async getChurnTotal() {
    const responseData = await api.fetchAsAdmin('GET', `${apiBaseUrl}/statistics/churns`);
    return Object.values(responseData).length;
  },
  async getPausedTotal() {
    const responseData = await api.fetchAsAdmin('GET', `${apiBaseUrl}/statistics/paused`);
    return Object.values(responseData).length;
  },
  getSubscriptionsAPI() {
    return api.fetchAsAdmin('GET', `${apiBaseUrl}/subscriptions`);
  },
  async getSubscriptionsDB() {
    const subscriptionsSnap = await database.ref('Subscriptions').once('value');
    return subscriptionsSnap.val();
  },
  churnSubscriptionsAndRemoveUserData(userId) {
    return api.fetchAsAdmin('PUT', `${apiBaseUrl}/pubsub/user/${userId}/remove`);
  },
  pauseSubscription(userId) {
    return api.fetchAsAdmin('PUT', `${apiBaseUrl}/subscriptions/${userId}/pause`);
  },
  unpauseSubscription(userId) {
    return api.fetchAsAdmin('PUT', `${apiBaseUrl}/subscriptions/${userId}/unpause`);
  },
  churnSubscription(userId) {
    return api.fetchAsAdmin('PUT', `${apiBaseUrl}/subscriptions/${userId}/churn`);
  },
  getSubscription(userId) {
    return api.fetchAsAdmin('GET', `${apiBaseUrl}/subscriptions/${userId}`).then(s => s[0]);
  },
  getSubscriptionLogs(userId) {
    return api.fetchAsAdmin('GET', `${apiBaseUrl}/subscriptions/${userId}/eventLog`);
  },
  triggerRenewal(day) {
    return api.fetchAsAdmin('POST', `${apiBaseUrl}/subscriptions/renewals/date/${day}`);
  },
  triggerRenewalByUser(renewStatus) {
    return api.fetchAsAdmin('POST', `${apiBaseUrl}/subscriptions/renewals`, renewStatus);
  },
  getSubscriptionsByDay(day) {
    return api.fetchAsAdmin('GET', `${apiBaseUrl}/subscriptions/renewals/byDate/${day}`);
  },
  getSubscriptionsV2ByDay(day) {
    return api.fetchAsAdmin('GET', `${apiBaseUrl}/subscriptions/v2/cycle/day/${day}`);
  },
  async updateSubscription(userId, newSubscription) {
    const filteredSubscription = {
      updatedAt: Date.now(),
    };
    if ('packageId' in newSubscription) {
      filteredSubscription.packageId = newSubscription.packageId;
    }
    if ('monthlyRecurrenceDay' in newSubscription) {
      filteredSubscription.monthlyRecurrenceDay = parseInt(newSubscription.monthlyRecurrenceDay, 10);
    }
    if ('activeOrder' in newSubscription) {
      filteredSubscription.activeOrder = newSubscription.activeOrder;
    }
    if ('prepaidUntil' in newSubscription) {
      filteredSubscription.prepaidUntil = newSubscription.prepaidUntil !== '' ? newSubscription.prepaidUntil : null;
    }
    return api.fetchAsAdmin('PUT', `${apiBaseUrl}/subscriptions/${userId}`, filteredSubscription);
  },
};
