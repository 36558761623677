import React from 'react';

import EditCoupon from '../coupons/EditCoupon';

class CouponModalEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: undefined,
      endDate: undefined,
      submitDate: undefined,
      uid: '',
      oid: '',
      loaded: false,
      visibleContent: 1, // 2
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false);
  }

  componentWillUnmount = () => {
    document.removeEventListener('keydown', this.escFunction, false);
    const blankState = {};
    Object.keys(this.state).forEach(stateKey => {
      blankState[stateKey] = undefined;
    });
    this.setState(blankState);
  };

  escFunction = event => {
    if (event.keyCode === 27) {
      this.cancelDetail();
    }
  };

  cancelDetail = () => {
    if (this.props.close) {
      this.props.close();
    }
  };

  clickHandler = () => {
    if (this.props.handler) {
      this.props.handler();
    }
  };

  saveHandler = response => {
    if (this.props.save) {
      this.props.save(response);
    }
  };

  render() {
    console.info(this.props.row);
    return (
      <div className="couponModal  true ">
        <div className="couponModal-container">
          <button type="button" className="close" onClick={this.cancelDetail}>
            &times;
          </button>
          <EditCoupon couponData={this.props.row} save={this.saveHandler} />
          <div className="modal-controls">
            {/* <button className="ok-btn" onClick={this.cancelDetail}>
              Close
            </button> */}
          </div>
        </div>
      </div>
    );
  }
}
export default CouponModalEdit;
