import { apiFunctionsUrl, apiBaseUrl, auth, database, locizeEnv } from 'config/base';
import DataUtils from 'components/helpers/utils/data';
import Users from './Users';
import FulfillmentOrders from './FulfillmentOrders';

async function call(method, requestInfo, bodyData) {
  try {
    const isDev = locizeEnv === 'development';
    const token = await this.auth.currentUser.getIdToken();
    const requestBody = {
      method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    if (bodyData) requestBody.body = JSON.stringify(bodyData);
    const response = await fetch(requestInfo, requestBody);
    if (isDev) console.info({ requestInfo, requestBody, bodyData });
    const contentType = response.headers.get('content-type');
    const isJson = contentType && contentType.indexOf('application/json') !== -1;
    let data = '';
    if (isJson) {
      const json = await response.json();
      data = json.data ? json.data : json;
    } else {
      data = await response.text();
    }
    if (isDev) console.info({ response, data });
    if (!response.ok) throw new Error(data.message);
    if (response.status === 204) return [];

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
export default class Klikkie {
  constructor() {
    this.apiFunctionsUrl = apiFunctionsUrl;
    this.apiBaseUrl = apiBaseUrl;
    this.auth = auth;
    this.database = database;
    this.DataUtils = DataUtils;
    this.call = call;
    /**
     * Creates a new user object
     *
     * @param {String} userId optional
     */
    this.users = userId => new Users({ ...this, users: { userId } });
    this.fulfillment = fulfillmentOrderId =>
      new FulfillmentOrders({ ...this, fulfillmentOrder: { fulfillmentOrderId } });
  }
}
