import React from 'react';
import { MdSync } from 'react-icons/md';
import draftActions from 'actions/draftActions';

class ImportDraftsButton extends React.Component {
  import = async () => {
    try {
      this.props.toastOpen('Starting drafts import...');
      await draftActions.import(this.props.userId);
      this.props.toastOpen('Drafts imported');
    } catch (error) {
      this.props.toastOpen(error);
    }
  };

  render() {
    return (
      <span>
        <button type="button" className="button" onClick={this.import}>
          <MdSync /> Import Drafts
        </button>
      </span>
    );
  }
}

export default ImportDraftsButton;
