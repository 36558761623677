import { database } from 'config/base';
import oa from 'actions/orderActions';

export default {
  churnStatistics() {
    const data = [];
    return database
      .ref('/Statistics/Churns')
      .once('value', snapshot => {
        if (snapshot.val() != null) {
          snapshot.forEach(childSnapshot => {
            const churns = childSnapshot.val();
            churns.createdAt = oa.compileDate(churns.createdAt);
            churns.churnedAt = oa.compileDate(churns.churnedAt);
            churns.subscriptionCreatedAt = oa.compileDate(churns.subscriptionCreatedAt);
            if (churns.comment === '' || churns.comment === undefined) {
              churns.comment = '-';
            }
            data.push(churns);
          });
        }
      })
      .then(() => data);
  },
  async addStatistic(data, type) {
    try {
      if (type !== 'Churns' && type !== 'Paused') throw new Error('Wrong type');
      return database
        .ref('Statistics')
        .child(type)
        .push(data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  pauseStatistics() {
    const data = [];
    return database
      .ref('/Statistics/Paused')
      .once('value', snapshot => {
        if (snapshot.val() != null) {
          snapshot.forEach(childSnapshot => {
            const paused = childSnapshot.val();
            paused.createdAt = oa.compileDate(paused.createdAt);
            paused.pausedAt = oa.compileDate(paused.pausedAt);
            paused.subscriptionCreatedAt = oa.compileDate(paused.subscriptionCreatedAt);
            if (paused.comment === '' || paused.comment === undefined) {
              paused.comment = '-';
            }
            data.push(paused);
          });
        }
      })
      .then(() => data);
  },
};
