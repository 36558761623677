import React, { Component } from 'react';
import DataGrid from 'components/forms/DataGrid';
import { ConvertTimestamp2Date, TagFormatter, PhotobookLink } from 'components/helpers/formatters';
import draftActions from 'actions/draftActions';
import { Filters } from 'react-data-grid-addons';
import ImportDraftsButton from 'components/buttons/ImportDraftsButton';

const { MultiSelectFilter, SingleSelectFilter } = Filters;

class DetailDraftsTab extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        key: 'createdAt',
        name: 'Created',
        width: 100,
        sortable: true,
        filterable: true,
        resizable: true,
        formatter: ConvertTimestamp2Date,
      },
      {
        key: 'updatedAt',
        name: 'Updated',
        width: 100,
        sortable: true,
        filterable: true,
        resizable: true,
        formatter: ConvertTimestamp2Date,
      },
      {
        key: 'draftId',
        name: 'Id',
        width: 180,
        sortable: true,
        filterable: true,
        resizable: true,
      },
      {
        key: 'editorType',
        name: 'Editor Type',
        width: 170,
        sortable: true,
        filterable: true,
        resizable: true,
        filterRenderer: SingleSelectFilter,
        formatter: TagFormatter,
      },
      {
        key: 'sku',
        name: 'Sku',
        width: 120,
        sortable: true,
        filterable: true,
        resizable: true,
      },
      {
        key: 'status',
        name: 'Status',
        width: 120,
        sortable: true,
        filterable: true,
        resizable: true,
        filterRenderer: MultiSelectFilter,
        formatter: TagFormatter,
      },
      {
        key: 'state',
        name: 'State',
        width: 120,
        sortable: true,
        filterable: true,
        resizable: true,
        filterRenderer: SingleSelectFilter,
        formatter: TagFormatter,
      },
      {
        key: 'editorId',
        name: 'Preview',
        width: 180,
        sortable: true,
        filterable: true,
        resizable: true,
        formatter: ({ value, row }) => {
          const formatter =
            row.type === 'album' ? (
              <PhotobookLink row={row} value={value} />
            ) : (
              <button type="button" onClick={() => window.open(`/pictureOrder/${row.userId}/${value}`, '_blank')}>
                View Picture Order
              </button>
            );
          return formatter;
        },
      },
    ];
    this.state = {
      rows: undefined,
    };
  }

  async componentDidMount() {
    try {
      const rows = await draftActions.list(this.props.uid);
      this.props.count(rows.length);

      this.setState({
        rows,
      });
    } catch (e) {
      console.error(e);
    }
  }

  render() {
    return (
      <span>
        <DataGrid
          columns={this.columns}
          rows={this.state.rows}
          print={false}
          enableCellSelect
          // getSelection={selection => this.setState({ selection })}
          extraTools={
            <span>
              <ImportDraftsButton toastOpen={this.props.toastOpen} userId={this.props.uid} />
            </span>
          }
        />
      </span>
    );
  }
}

export default DetailDraftsTab;
