import React, { Component } from 'react';
import subscriptionActions from 'actions/subscriptionActions';

class SubscriptionList extends Component {
  constructor() {
    super();
    this.state = {
      active: '',
    };
  }

  async componentDidMount() {
    try {
      const active = await subscriptionActions.getActiveTotal();
      this.setState({ active });
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    return (
      <ul className="subscriptionList">
        <li>
          Active subscriptions: <span>{this.state.active.toLocaleString()}</span>
        </li>
      </ul>
    );
  }
}

export default SubscriptionList;
