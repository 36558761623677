import React from 'react';
import Iframe from 'react-iframe';
import renderActions from 'actions/renderActions';
import Spinner from 'components/helpers/Spinner';

class StoryPagePDF extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      url: null,
    };
  }

  componentDidMount = async () => {
    const { match } = this.props;
    const { storyId, pageId } = match.params;

    try {
      const render = await renderActions.renderStoryPagePDF(storyId, pageId);

      this.setState({
        url: URL.createObjectURL(render),
      });
    } catch (error) {
      console.error(error);
    }
  };

  getCellActions = column => {
    if (column.key === 'actions') {
      return [
        {
          icon: 'icon ion-md-cog',
          actions: [],
        },
      ];
    }
    return false;
  };

  render() {
    return (
      <div>
        {!this.state.url && (
          <div style={{ top: '200px', left: '50%', position: 'absolute' }}>
            {/* <img className="preloader" src="https://www.klikkie.nl/assets/img/loader.gif" alt="" /> */}
            <Spinner />
          </div>
        )}
        {this.state.url && (
          <Iframe
            url={this.state.url}
            position="absolute"
            width="100%"
            id="myId"
            className="StoryPagePDF"
            height="100%"
            styles={{ height: '25px' }}
          />
        )}
      </div>
    );
  }
}
export default StoryPagePDF;
