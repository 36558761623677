import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import { ProfileFormatter, Status, TagFormatter } from 'components/helpers/formatters';
import DataGrid from 'components/forms/DataGrid';
import orderActions from 'actions/orderActions';
import ConfirmModal from 'components/helpers/ConfirmModal';
import 'react-datepicker/dist/react-datepicker.css';
import { GenericButtonSplit } from 'components/buttons';
import subscriptionActions from 'actions/subscriptionActions';
import { checkoutUrl } from 'config/base';

const {
  Filters: {
    //   NumericFilter,
    // AutoCompleteFilter
    MultiSelectFilter,
    SingleSelectFilter,
  },
} = require('react-data-grid-addons');

class Charges extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        key: 'date',
        name: 'Charge date',
        width: 140,
        resizable: true,
      },
      {
        key: 'units',
        name: 'Units',
        width: 50,
        sortable: true,
        filterable: true,
        resizable: true,
      },
      {
        key: 'userId',
        name: 'User',
        width: 250,
        sortable: true,
        filterable: true,
        resizable: true,
        filterRenderer: MultiSelectFilter,
        getRowMetaData: row => row,
        formatter: (
          <ProfileFormatter
            detailOpen={userId => this.props.detailOpen(userId)}
            toastOpen={(message, level) => this.props.toastOpen(message, level)}
          />
        ),
      },
      {
        key: 'order',
        name: 'Order',
        sortable: true,
        filterable: true,
        resizable: true,
        width: 100,
        filterRenderer: SingleSelectFilter,
        formatter: Status,
      },
      {
        key: 'pictureOrder',
        name: 'P. Order',
        sortable: true,
        filterable: true,
        resizable: true,
        width: 100,
        filterRenderer: SingleSelectFilter,
        formatter: Status,
      },
      {
        key: 'paid',
        name: 'Payment',
        sortable: true,
        filterable: true,
        resizable: true,
        width: 100,
        filterRenderer: SingleSelectFilter,
        formatter: Status,
      },
      {
        key: 'pspStatus',
        name: 'PSP Status',
        sortable: true,
        filterable: true,
        resizable: true,
        width: 220,
        filterRenderer: SingleSelectFilter,
        formatter: TagFormatter,
      },
      {
        key: 'actions',
        name: '',
        width: 90,
      },
    ];
    this.state = {
      rows: undefined,
      func: '',
      modalTitle: '',
      modalMessage: '',
      startDate: new Date(),
      selection: [],
    };
  }

  componentDidMount = () => {
    const { startDate } = this.state;
    const date = startDate.toISOString().split('T')[0];
    this.getChargesByDay(date);
  };

  getChargesByDay = async startDate => {
    try {
      const rows = await subscriptionActions.getSubscriptionsByDay(startDate);
      this.setState({ rows });
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  handleDateChange = startDate => {
    const date = new Date(startDate).toISOString().split('T')[0];
    this.getChargesByDay(date);
    this.setState({ startDate });
  };

  getCellActions = (column, row) => {
    if (column.key === 'actions') {
      return [
        {
          icon: 'icon ion-md-more',
          actions: [
            {
              text: 'Copy orderId',
              callback: () => this.copyText(row.orderId),
            },
            {
              text: 'Copy userId',
              callback: () => this.copyText(row.userId),
            },
            {
              text: 'Charge subscription month',
              callback: () => this.chargeAccount(row),
            },
            {
              text: 'Give free prepaid month',
              callback: () => this.prepayMonth(row),
            },
            {
              text: 'Get payment link',
              callback: async () => {
                const { orderId } = row;
                // eslint-disable-next-line
                const id = isNaN(orderId) ? (await orderActions.getOrderAPIOrder(orderId)).id : orderId;
                this.copyText(`${checkoutUrl}/?id=${id}`);
              },
            },
          ],
        },
      ];
    }
    return false;
  };

  copyText = text => {
    navigator.clipboard.writeText(text);
    this.props.toastOpen(`'${text}' copied to clipboard`);
  };

  // modal settings
  openModal = () => {
    this.Modal.showModal();
  };

  modalHandler = async value => {
    try {
      if (!value) return;
      const result = await this.state.func();
      this.props.toastOpen(result);
    } catch (error) {
      console.error(error);
      this.props.toastOpen(error);
    }
  };

  chargeAccount = row => {
    const { orderId } = row;
    const { startDate } = this.state;
    this.setState(
      {
        modalTitle: `Charge subscription month`,
        modalMessage: `Do you want to charge ${startDate} for selected users?`,
        func: () => orderActions.chargeAccount(orderId),
      },
      () => {
        this.openModal();
      },
    );
  };

  triggerRenews = async () => {
    const { startDate } = this.state;
    const date = new Date(startDate).toISOString().split('T')[0];

    return this.setState(
      {
        modalTitle: `Run renews for ${date}`,
        modalMessage: `Retry unsucceeded renews. It can take a while. Reload the page after some time to see status updated.`,
        func: () => {
          try {
            subscriptionActions.triggerRenewal(date);
          } catch (error) {
            console.error(error);
          }
          return `Running renews ${date}. Reload the page after 5 minutes`;
        },
      },
      () => {
        this.openModal();
      },
    );
  };

  chargeAccounts = () => {
    const { startDate, selection } = this.state;
    if (selection.length < 1) return this.props.toastOpen('Select at least one row');
    return this.setState(
      {
        modalTitle: `Charge subscription month`,
        modalMessage: `Do you want to charge ${startDate} for selected users?`,
        func: () => Promise.all(selection.map(row => orderActions.chargeAccount(row.orderId))),
      },
      () => {
        this.openModal();
      },
    );
  };

  prepayMonth = row => {
    const { userId } = row;
    this.setState(
      {
        modalTitle: `Give free prepaid month`,
        modalMessage: `Do you want to add a free month for selected users?`,
        func: () => orderActions.freeOrder(userId),
      },
      () => {
        this.openModal();
      },
    );
  };

  prepayMonths = () => {
    const { selection } = this.state;
    if (selection.length < 1) return this.props.toastOpen('Select at least one row');
    return this.setState(
      {
        modalTitle: `Give free prepaid month`,
        modalMessage: `Do you want to add a free month for selected users?`,
        func: () => Promise.all(selection.map(row => orderActions.freeOrder(row.userId))),
      },
      () => {
        this.openModal();
      },
    );
  };

  render() {
    return (
      <span>
        <ConfirmModal
          ref={instance => {
            this.Modal = instance;
          }}
          handler={this.modalHandler}
          title={this.state.modalTitle}
          message={this.state.modalMessage}
        />

        <DataGrid
          name={this.props.name}
          columns={this.columns}
          rows={this.state.rows}
          actions={this.getCellActions}
          getSelection={selection => this.setState({ selection })}
          toastOpen={this.props.toastOpen}
          print={false}
          extraTools={
            <span>
              <DatePicker
                todayButton="Today"
                selected={this.state.startDate}
                onChange={this.handleDateChange}
                value={null}
              />
              <button
                type="button"
                className="button"
                data-tip={`Run all the renews for ${this.state.startDate.toLocaleString(undefined, {
                  weekday: 'short',
                  year: 'numeric',
                  month: '2-digit',
                  day: 'numeric',
                })}`}
                onClick={this.triggerRenews}
              >
                Run renews
              </button>
              <GenericButtonSplit />
              <button type="button" className="button" onClick={this.chargeAccounts}>
                Charge subscription month
              </button>
              <GenericButtonSplit />
              <button type="button" className="button" onClick={this.prepayMonths}>
                Give free prepaid month
              </button>
            </span>
          }
        />
      </span>
    );
  }
}
export default Charges;
