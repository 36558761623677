import React, { Component } from 'react';
import sa from 'actions/statisticsActions';
import DataGrid from 'components/forms/DataGrid';
import { Flag } from 'components/helpers/formatters';

const {
  Filters: { MultiSelectFilter },
} = require('react-data-grid-addons');

class ChurnReasons extends Component {
  constructor() {
    super();
    this.columns = [
      {
        key: 'profile',
        name: '',
        width: 50,
        sortable: true,
        filterable: true,
        resizable: true,
      },
      {
        key: 'countryCode',
        name: '',
        width: 60,
        sortable: true,
        filterable: true,
        resizable: true,
        formatter: <Flag size="24px" />,
      },
      {
        key: 'packageId',
        name: 'Package',
        width: 180,
        sortable: true,
        filterable: true,
        resizable: true,
        // formatter: productPackageFormatter,
      },
      {
        key: 'subscriptionCreatedAt',
        name: 'Member Since',
        width: 120,
        sortable: true,
        filterable: true,
        resizable: true,
      },
      {
        key: 'churnedAt',
        name: 'Churned At',
        width: 120,
        sortable: true,
        filterable: true,
        resizable: true,
      },
      {
        key: 'reason',
        name: 'reason',
        width: 350,
        filterable: true,
        filterRenderer: MultiSelectFilter,
      },
      {
        key: 'comment',
        name: 'comments',
        width: 900,
      },
    ];
    this.state = {
      rows: [],
    };
  }

  componentDidMount = async () => {
    try {
      const rows = await sa.churnStatistics();
      this.setState({ rows });
    } catch (error) {
      console.error(error);
    }
  };

  getCellActions = (column, row) => {
    if (column.key === 'profile') {
      return [
        {
          icon: 'icon ion-md-person',
          text: 'open',
          callback: () => this.props.detailOpen(row.userId),
        },
      ];
    }
    return false;
  };

  render() {
    return (
      <div>
        <DataGrid
          rows={this.state.rows}
          columns={this.columns}
          print={false}
          name={this.props.name}
          actions={this.getCellActions}
        />
      </div>
    );
  }
}

export default ChurnReasons;
