import React, { Component } from 'react';
import orderActions from 'actions/orderActions';
import DataGrid from 'components/forms/DataGrid';
import Klikkie from '_klikkie';
import {
  AddressIdSelector,
  createOrderLink,
  ConvertTimestamp2Date,
  Status,
  RowRendererDetailPictureOrderTab,
} from 'components/helpers/formatters';
import DataUtils from 'components/helpers/utils/data';
import {
  PictureOrderChangeStatusButton,
  PictureOrderCreateButton,
  PictureOrderRemoveButton,
  PictureOrderResetButton,
  SubscriptionFreeMonthButton,
  GenericButtonSplit,
} from 'components/buttons';
import ResetOrder from 'components/helpers/ResetOrder';
import TagFormatter from 'components/helpers/formatters/TagFormatter';

const klikkie = new Klikkie();
const {
  Filters: {
    // NumericFilter,
    // AutoCompleteFilter
    // MultiSelectFilter
    SingleSelectFilter,
  },
} = require('react-data-grid-addons');

class DetailPictureOrdersTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: undefined,
      selection: [],
      addresses: undefined,
    };
    this.columns = [
      {
        key: 'orderId',
        name: 'orderId',
        width: 350,
        sortable: true,
        filterable: true,
        resizable: true,
        formatter: createOrderLink,
        getRowMetaData: row => row,
      },
      {
        key: 'sku',
        name: 'SKU',
        width: 50,
        sortable: true,
        filterable: true,
        resizable: true,
      },
      {
        key: 'photoCount',
        name: 'Photo Count',
        width: 100,
        sortable: true,
        filterable: true,
        resizable: true,
      },
      {
        key: 'addressId',
        name: 'addressId',
        width: 250,
        sortable: true,
        filterable: true,
        resizable: true,
        getRowMetaData: row => {
          return { addresses: this.state.addresses, changeHandler: this.changeAddress, ...row };
        },
        formatter: AddressIdSelector,
      },
      {
        key: 'createdAt',
        name: 'Created',
        width: 100,
        sortable: true,
        filterable: false,
        resizable: true,
        formatter: ConvertTimestamp2Date,
      },
      {
        key: 'platform',
        name: 'Platform',
        width: 100,
        sortable: true,
        filterable: true,
        resizable: true,
        formatter: TagFormatter,
      },
      {
        key: 'version',
        name: 'Platform Version',
        width: 150,
        sortable: true,
        filterable: true,
        resizable: true,
      },
      {
        key: 'status',
        name: 'Status',
        width: 60,
        sortable: true,
        filterable: true,
        resizable: true,
        filterRenderer: SingleSelectFilter,
        formatter: Status,
      },
    ];
  }

  componentDidMount = () => this.fetchData();

  // // eslint-disable-next-line no-unused-vars
  // onGridRowsUpdated = async ({ fromRow, toRow, updated }) => {
  //   try {
  //     const newRow = Object.assign(this.state.rows[fromRow], updated);

  //     await orderActions.updatePictureOrder(this.props.uid, newRow.orderId, { addressId: newRow.addressId });
  //     const { rows } = this.state;
  //     delete rows[fromRow];
  //     this.setState({ rows }, () => {
  //       rows[fromRow] = newRow;
  //       this.setState({ rows });
  //     });
  //     this.props.toastOpen('Row saved');
  //   } catch (error) {
  //     console.error(error);
  //     this.props.toastOpen(error);
  //   }
  // };

  changeAddress = async (orderId, addressId) => {
    try {
      const data = {
        addressId,
      };
      const { uid: userId } = this.props;

      await orderActions.putPictureOrder(userId, orderId, data);
      return this.props.toastOpen('Address updated');
    } catch (error) {
      console.error(error);
      return this.props.toastOpen(error, 'error');
    }
  };

  getAddresses = async () => {
    const { uid: userId } = this.props;
    const addresses = await klikkie
      .users(userId)
      .addresses()
      .get();
    this.setState({ addresses });
  };

  async fetchData() {
    try {
      this.setState({ rows: undefined });
      this.getAddresses();
      const rows = await orderActions.getPictureOrders(this.props.uid);

      rows.sort(DataUtils.dynamicSort('createdAt', -1));
      rows.forEach(async element => {
        try {
          let elm = element;
          elm.platform = element?.metadata?.platform || '-';
          elm.sku = element?.metadata?.sku || 200;
          elm.photoCount = Object.values(element.pictures).filter(
            ({ userPictureId }) => userPictureId && userPictureId.length > 0,
          ).length;
          if (elm.metadata) {
            elm = Object.assign(elm, elm.metadata);
          }
        } catch (error) {
          console.error(error);
          this.props.toastOpen(error);
        }
      });

      this.setState({ rows });
      const length = rows ? rows.length : 0;
      this.props.count(length);
    } catch (error) {
      console.error(error);
      this.props.toastOpen(error);
    }
  }

  render() {
    const { uid: userId } = this.props;
    return (
      <span>
        <DataGrid
          columns={this.columns}
          rows={this.state.rows}
          print={false}
          getSelection={selection => this.setState({ selection })}
          enableCellSelect
          onGridRowsUpdated={this.onGridRowsUpdated}
          extraTools={
            <span>
              {this.state.rows && (
                <span>
                  <ResetOrder data={this.state.selection} />
                  <GenericButtonSplit />
                  <PictureOrderChangeStatusButton data={this.state.selection} updateHandler={() => this.fetchData()} />
                  <PictureOrderRemoveButton userId={userId} data={this.state.selection} />
                  <GenericButtonSplit />
                </span>
              )}
              <PictureOrderCreateButton uid={userId} toastOpen={this.props.toastOpen} />
              <GenericButtonSplit />
              <SubscriptionFreeMonthButton uid={userId} />
              <GenericButtonSplit />
              <PictureOrderResetButton userId={userId} data={this.state.selection} />
              {/* <DeleteDuplicateOrder data={this.state.selection} /> */}
              {/* <PictureOrderReprintButton data={this.state.selection} userId={userId} /> */}
            </span>
          }
          hideCsvButton
          rowRenderer={RowRendererDetailPictureOrderTab}
        />
      </span>
    );
  }
}

export default DetailPictureOrdersTab;
