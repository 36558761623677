/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-string-refs */
import React, { Component } from 'react';
import moment from 'moment';
import '../../styles/_coupon.scss';
import couponActions from 'actions/couponActions';
import orderActions from 'actions/orderActions';
import { COUPONTYPES, DiscountType } from '@klikkie/klikkie-schemas/lib/interfaces/Coupon';

class EditCoupon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      redeem: null,
      couponType: null,
      discountType: null,
    };
  }

  componentDidMount = () => {
    const { couponCode } = this.props.couponData;

    couponActions
      .readCoupon(couponCode)
      .then(([data]) => this.loadCoupon(data))
      .catch(() => this.loadCoupon(this.props.couponData));
  };

  loadCoupon = coupon => {
    this.setState({
      couponType: coupon.couponType,
      discountType: coupon.discountType || DiscountType.Fixed,
      ...(coupon.redeemDate && { redeem: coupon.redeemDate }),
    });

    if (coupon) {
      this.refs.code.value = coupon.couponCode || '';
      this.refs.discount.value =
        (coupon.discountType === DiscountType.Percent ? coupon.discount : this.convertToMoney(coupon.discount)) || '';
      this.refs.limit.value = coupon.limitToPackageFilter || '';
      this.refs.couponRedeemLimit.value = coupon.redeemLimit || '';
      this.refs.expiresAt.value = orderActions.compileDate(coupon.expiresAt) || '';
    }
  };

  convertToMoney = value => {
    let amount = '';
    if (value !== undefined && value !== '') {
      amount = Number(value / 100).toFixed(2);
    }
    return amount;
  };

  convertToPercent = value => {
    let amount = '';
    if (value !== undefined && value !== '') {
      amount = `${Number(value).toFixed(2)}%`;
    }
    return amount;
  };

  humanDateToTimestamp = humanDate => moment(humanDate).format();

  sendDiscount = e => {
    this.setState({ error: false });
    e.preventDefault();
    if (
      this.refs.code.value === '' ||
      this.refs.discount.value === '' ||
      this.refs.couponRedeemLimit.value === '' ||
      !Object.values(COUPONTYPES).includes(this.state.couponType)
    ) {
      this.setState({ error: true });
      return;
    }

    const uppercaseCode = this.refs.code.value.toUpperCase();
    const limitToPackages = this.refs.limit.value
      ? this.refs.limit.value.split(',').map(i => {
          if (i === '') {
            return '';
          }
          return Number(i);
        })
      : [];

    const expiresAt = this.refs.expiresAt.value ? new Date(this.refs.expiresAt.value).getTime() : null;

    let sanitizedValue = this.refs.discount.value;
    if (this.state.discountType === DiscountType.Percent) {
      [sanitizedValue] = sanitizedValue.split('.');
      sanitizedValue = Number.parseInt(sanitizedValue, 10);
      if (sanitizedValue > 100) {
        sanitizedValue = 100;
      } else if (sanitizedValue < 0) {
        sanitizedValue = 0;
      }
    } else {
      // XXX: remove decimal separator from discount value due to rounding errors that can occur when multiplying dollar value to get cent value
      sanitizedValue = sanitizedValue.replace(/[^0-9]/g, '');
      sanitizedValue = Number.parseInt(sanitizedValue, 10);
    }

    couponActions
      .updateCoupon(uppercaseCode, {
        couponType: this.state.couponType,
        discount: sanitizedValue,
        discountType: this.state.discountType,
        limitToPackages,
        redeemLimit: parseInt(this.refs.couponRedeemLimit.value, 10),
        updatedAt: new Date().getTime(),
        expiresAt,
      })
      .then(body => {
        this.props.save(body[0].couponCode);
      })
      .catch(alert);
  };

  clearHandler = e => {
    e.preventDefault();
    this.setState({ redeem: {} });
  };

  handleFocus = event => event.target.select();

  getYesterdayDate = () => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    return yesterday.getTime();
  };

  deactivateCoupon = event => {
    this.refs.expiresAt.value = orderActions.compileDate(this.getYesterdayDate());
    event.preventDefault();
  };

  onCouponTypeChanged = event => {
    this.setState({
      couponType: event.currentTarget.value,
    });
  };

  onDiscountTypeChanged = event => {
    this.setState({
      discountType: event.currentTarget.value,
    });
  };

  render() {
    return (
      <div className="coupon-form">
        {/* {this.props.couponData && <h2>Update Coupon</h2>} */}
        <form>
          <fieldset>
            <label htmlFor="name">
              Code
              <input
                ref="code"
                type="text"
                name="code"
                id="couponName"
                disabled
                style={{ textTransform: 'uppercase' }}
                placeholder="Couponcode without spaces"
              />
            </label>
            <p />
          </fieldset>
          <fieldset>
            <span>Coupon type *</span>
            <label htmlFor="couponType_discount">
              <input
                type="radio"
                id="couponType_discount"
                name="couponType"
                value={COUPONTYPES.discount}
                checked={this.state.couponType === COUPONTYPES.discount}
                onChange={this.onCouponTypeChanged}
              />
              Discount
            </label>
            <label htmlFor="couponType_voucher">
              <input
                type="radio"
                id="couponType_voucher"
                name="couponType"
                value={COUPONTYPES.voucher}
                checked={this.state.couponType === COUPONTYPES.voucher}
                onChange={this.onCouponTypeChanged}
              />
              Voucher
            </label>
            <p>Choice a coupon type.</p>
          </fieldset>
          <fieldset>
            <span>Discount type *</span>
            <label htmlFor="discountType_fixed">
              <input
                type="radio"
                id="discountType_fixed"
                name="discountType"
                value={DiscountType.Fixed}
                checked={this.state.discountType === DiscountType.Fixed}
                onChange={this.onDiscountTypeChanged}
              />
              Fixed
            </label>
            <label htmlFor="discountType_percent">
              <input
                type="radio"
                id="discountType_percent"
                name="discountType"
                value={DiscountType.Percent}
                checked={this.state.discountType === DiscountType.Percent}
                onChange={this.onDiscountTypeChanged}
              />
              Percent
            </label>
            <p>Choose a coupon type.</p>
          </fieldset>
          <fieldset>
            <label htmlFor="discount">
              Discount *
              <input ref="discount" type="number" name="discount" id="couponDiscount" required="required" />
              <p>
                {this.state.discountType === DiscountType.Fixed
                  ? 'Add a discount in euro: 5,00 is €5,00 and 7,95 is €7,95'
                  : 'Add a discount in percent: 10 is 10% and 50.5 is 50% (no half-percents for you!)'}
              </p>
            </label>
          </fieldset>
          <fieldset>
            <label htmlFor="limit">
              Redeem Limit *
              <input
                ref="couponRedeemLimit"
                type="text"
                name="couponRedeemLimit"
                id="couponRedeemLimit"
                required="required"
                defaultValue="1"
                onFocus={this.handleFocus}
              />
              <p>How many times may this coupon be used</p>
            </label>
          </fieldset>
          <fieldset>
            <label htmlFor="limit">
              Expires At
              <input
                ref="expiresAt"
                type="text"
                name="expiresAt"
                id="expiresAt"
                required="required"
                defaultValue="1"
                onFocus={this.handleFocus}
              />
              <p>How many times may this coupon be used</p>
            </label>
          </fieldset>
          <fieldset>
            <label htmlFor="limit">
              Package Limit
              <input ref="limit" type="text" name="limit" id="couponLimit" onFocus={this.handleFocus} />
              <p>Add a limit based on productPackageId</p>
            </label>
          </fieldset>

          {this.state.redeem !== null && this.state.redeem !== 'no date' && (
            <fieldset>
              <label htmlFor="redeem">
                Redeem
                <button type="button" id="redeem" onClick={this.clearHandler.bind(this)}>
                  Remove Redeemdate
                </button>
                <p>Clears redeem date and reopens couponCode</p>
              </label>
            </fieldset>
          )}
          {this.state.error && <p className="error">fill al inputs</p>}
          <button type="button" className="button secondary" onClick={this.deactivateCoupon} id="couponButton">
            Deactivate coupon
          </button>
          <button type="submit" className="button" onClick={this.sendDiscount} id="couponButton">
            Save coupon
          </button>
        </form>
      </div>
    );
  }
}
export default EditCoupon;
