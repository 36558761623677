import React, { Component } from 'react';
import './styles/build.css';
import { Route, NavLink, BrowserRouter } from 'react-router-dom';
import { IconContext } from 'react-icons';
import { MdMenu, MdClose } from 'react-icons/md';
import { auth } from 'config/base';
import Login from 'components/forms/Login';
import Modal from 'components/helpers/Modal';
import { userRoles } from 'config/userRoles';
import SearchUser from 'components/helpers/SearchUser';
import LoggedInUser from 'components/helpers/LoggedInUser';
import Klikkie from '_klikkie';
import Menu from 'components/helpers/Menu';
import DetailModal from 'components/detail/DetailModal';
import Message from 'components/helpers/Message';
import logo from './img/klikkie-no-logo.png';
import packageJson from '../package.json';

const klikkie = new Klikkie();
const key = Object.keys(localStorage).find(e => e.match(/firebase:authUser/));
const data = JSON.parse(localStorage.getItem(key));
class App extends Component {
  constructor() {
    super();

    this.state = {
      checkedAuth: false,
      logged_in: false,
      menu: false,
      detailModal: { show: false, userId: '', tab: 'profile' },
      role: 'customerService',
      noHeader: false,
    };
  }

  componentDidMount = () => {
    this.checkAuth();
    if (window.location.pathname.indexOf('user/') === -1 && window.location.pathname.indexOf('/download') === -1) {
      this.setState({ noHeader: true });
    }
    if (data === null) {
      this.setState({ logged_in: false });
    }
  };

  checkAuth = () => {
    auth.onAuthStateChanged(async user => {
      try {
        let sessionTimeout = null;
        if (user === null) {
          // User is logged out.
          // Clear the session timeout.
          if (sessionTimeout) clearTimeout(sessionTimeout);
          sessionTimeout = null;
          return this.setState({ logged_in: false, checkedAuth: true });
        }
        // User is logged in.
        // Fetch the decoded ID token and create a session timeout which signs the user out.
        const idTokenResult = await user.getIdTokenResult();
        // Make sure all the times are in milliseconds!
        const authTime = idTokenResult.claims.auth_time * 1000;
        const sessionDuration = 1000 * 60 * 60 * 24; // 24 hours
        const millisecondsUntilExpiration = sessionDuration - (Date.now() - authTime);
        await auth.setPersistence('local');
        await this.checkAdmin(user);
        sessionTimeout = setTimeout(() => auth.signOut(), millisecondsUntilExpiration);
        return this.setState({ logged_in: true, user, checkedAuth: true });
      } catch (error) {
        console.error(error);
        throw error;
      }
    });
  };

  checkAdmin = async () => {
    try {
      const { uid } = await auth.currentUser;
      const role = await klikkie.users(uid).isAdmin();

      if (!role) {
        this.setState({ noadmin: 'no admin rights', logged_in: false, checkedAuth: true });
        await auth.signOut();
        document.location.href = '/login';
      }
      this.setState({ role });
    } catch (error) {
      console.error(error);
      this.toast.showModal(error);
      throw error;
    }
  };

  toggleMenu = () => {
    const { menu } = this.state;
    this.setState({ menu: !menu });
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 1000);
  };

  detailOpenHandler = (userId, tab) => {
    this.setState({ detailModal: { show: true, userId, tab } });
  };

  detailCloseHandler = () => {
    this.setState({ detailModal: { show: false, tab: 'profile' } });
  };

  render() {
    window.onload = () => {
      window.parent.postMessage(
        {
          message: 'reamaze:resize',
          height: 1200,
        },
        '*',
      );
    };
    return (
      <BrowserRouter forceRefresh>
        <div className="App">
          <Message
            ref={instance => {
              this.toast = instance;
            }}
          />
          {this.state.detailModal.show && (
            <DetailModal
              uid={this.state.detailModal.userId}
              tab={this.state.detailModal.tab}
              user={this.state.user}
              close={this.detailCloseHandler}
              toastOpen={this.toast.showModal}
            />
          )}
          <Modal
            key="logout"
            ref={instance => {
              this.modal = instance;
            }}
            handler={this.logout}
            title="Signing off"
            message="Are you sure you want to logout from the dashboard?"
          />
          {this.state.logged_in === false && this.state.checkedAuth === true && (
            <Login
              callback={this.checkAdmin}
              message={this.state.noadmin}
              toastOpen={(message, level) => this.toast.showModal(message, level)}
            />
          )}
          {this.state.logged_in && (
            <div key="wrapper_1" className="wrapper">
              {this.state.noHeader && (
                <span>
                  <div className="headerWrapper">
                    <header>
                      <span className="left">
                        <button type="button" className="showMenu" onClick={this.toggleMenu}>
                          <IconContext.Provider value={{ size: '24px', color: '#5f6368' }}>
                            {this.state.menu ? <MdClose /> : <MdMenu />}
                          </IconContext.Provider>
                        </button>
                        <NavLink to="/" className="klikkie">
                          <div className="logo">
                            <img src={logo} alt="Klikkie" />v{packageJson.version}
                          </div>
                        </NavLink>
                      </span>
                      <SearchUser detailOpen={this.detailOpenHandler} />
                      <span className="right">
                        <LoggedInUser
                          role={this.state.role}
                          user={this.state.user}
                          toastOpen={(message, level) => this.toast.showModal(message, level)}
                          detailOpen={this.detailOpenHandler}
                        />
                      </span>
                    </header>
                  </div>
                </span>
              )}
              <div className="container">
                <Menu role={this.state.role} active={this.state.menu} />
                <span className="page">
                  {userRoles[this.state.role] &&
                    userRoles[this.state.role].map(route => (
                      <Route
                        key={route.path}
                        path={route.path}
                        exact
                        render={props => (
                          <route.component
                            name={route.title}
                            match={props.match}
                            history={props.history}
                            location={props.location}
                            role={this.state.role}
                            user={this.state.user}
                            toastOpen={this.toast.showModal}
                            detailOpen={this.detailOpenHandler}
                          />
                        )}
                      />
                    ))}
                </span>
              </div>
            </div>
          )}
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
