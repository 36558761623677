import React from 'react';
import Modal from 'components/helpers/Modal';

import userActions from 'actions/userActions';
import subscriptionActions from 'actions/subscriptionActions';

class SubscriptionHardChurnButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  componentDidMount = () => {
    userActions
      .hasRights()
      .then(value => {
        this.setState({ show: value });
      })
      .catch();
  };

  setPaid = () => {
    this.setState({ show: false });
    subscriptionActions
      .churnSubscriptionsAndRemoveUserData(this.props.uid)
      .then(() => {
        alert(`Subscription churned and data deleted from user ${this.props.uid}`);
      })
      .catch(error => {
        console.error(error);
        alert(`Error churning and deleting user ${this.props.uid} data`);
      });
  };

  render() {
    return (
      <span>
        {this.state.show && (
          <button type="button" className="button" onClick={this.modal.showModal}>
            <span role="img" aria-label="emoji">
              ❌{' '}
            </span>
            Churn subscription and delete data
          </button>
        )}
        <Modal
          ref={instance => {
            this.modal = instance;
          }}
          handler={this.setPaid}
          title="Churn subscription and delete data"
          message={`Churn subscription and delete data from user ${this.props.uid}?`}
        />
      </span>
    );
  }
}
export default SubscriptionHardChurnButton;
