import { SchemaUsers } from '@klikkie/klikkie-schemas';
import Addresses from './Addresses';

export default class Users {
  constructor(klikkie) {
    this.klikkie = klikkie;
    this.addresses = addressId => new Addresses({ ...this, addresses: { addressId } });
    const requiredParams = ['userId'];
    this.hasParams = () => {
      requiredParams.map(p => {
        if (!this.klikkie.users[p]) {
          throw new Error(`Parameter: ${p} is required`);
        }
        return true;
      });
    };
    this.validate = data => {
      const notValidObject = SchemaUsers.validate(data).error;
      if (notValidObject) throw new Error(notValidObject);

      return true;
    };
  }

  /**
   * Create a new user
   *
   * @param {Object} data The data of the new user
   * @returns {Promise<Object>} A promise to the user object
   * @memberof Users
   */
  create(data) {
    this.validate(data);
    return this.klikkie.call('PUT', `${this.klikkie.apiBaseUrl}/users`, data);
  }

  /**
   * Get an existing user
   *
   * @returns {Promise<Object>} A promise to the user object
   * @memberof Users
   */
  get() {
    this.hasParams();

    return this.klikkie.call('GET', `${this.klikkie.apiBaseUrl}/users/${this.klikkie.users.userId}`);
  }

  /**
   * Update the properties of a user
   *
   * @param {*} data The data to change
   * @returns {Promise<Object>} A promise to the user object
   * @memberof Users
   */
  update(data) {
    this.hasParams();
    this.validate(data);

    return this.klikkie.call('PUT', `${this.klikkie.apiBaseUrl}/users/${this.klikkie.users.userId}`, data);
  }

  /**
   * Delete the user
   *
   * @returns {Promise}
   * @memberof Users
   */
  delete() {
    this.hasParams();
    return this.klikkie.call('DELETE', `${this.klikkie.apiBaseUrl}/auth/${this.klikkie.users.userId}`);
  }

  /**
   * Checks whether the user is an Admin
   *
   * @returns {Promise<String>} A promise to the adminRole
   * @memberof Users
   */
  async isAdmin() {
    const user = await this.get();

    if (!user.isAdmin) return false;

    return user.adminRole;
  }

  /**
   * Sets a user to the specified admin role
   *
   * @param {('Printer'|'Admin'|false)} role
   * @returns {Promise<Object>} A promise to the user object
   * @memberof Users
   */
  async setAdmin(role) {
    const roles = ['Printer', 'Admin', false];
    this.hasParams();
    if (!roles.includes(role)) {
      throw Error(`Unknown role [${role}]. Role should be one of roles (${roles.toString()})`);
    }
    const data = {
      isAdmin: !!role,
      adminRole: role,
    };

    return this.update(data);
  }

  /**
   * Resets the password of the user
   *
   * @returns {Promise<String>} A promise with the new password
   * @memberof Users
   */
  async resetPassword() {
    this.hasParams();

    const { password } = await this.klikkie.call(
      'PUT',
      `${this.klikkie.apiBaseUrl}/auth/${this.klikkie.users.userId}/resetPassword`,
    );
    return password;
  }

  async getAdmins() {
    try {
      const snapshot = await this.klikkie.database
        .ref('user')
        .orderByChild('isAdmin')
        .equalTo(true)
        .once('value');
      if (!snapshot.exists()) throw Error('No admins found');
      const admins = snapshot.val();
      const rows = Object.entries(admins).map(([userId, user]) => {
        const userFlat = this.klikkie.DataUtils.flattenObject(user);
        userFlat.userId = userId;
        return userFlat;
      });

      return rows;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}
