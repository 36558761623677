import React from 'react';

const TableLoader = () => {
  return (
    <table className="table-loader">
      <tbody>
        <tr>
          <td className="td-1">
            <span />
          </td>
          <td className="td-2">
            <span />
          </td>
          <td className="td-3">
            <span />
          </td>
          <td className="td-4" />
          <td className="td-5">
            <span />
          </td>
        </tr>
        <tr>
          <td className="td-1">
            <span />
          </td>
          <td className="td-2">
            <span />
          </td>
          <td className="td-3">
            <span />
          </td>
          <td className="td-4" />
          <td className="td-5">
            <span />
          </td>
        </tr>
        <tr>
          <td className="td-1">
            <span />
          </td>
          <td className="td-2">
            <span />
          </td>
          <td className="td-3">
            <span />
          </td>
          <td className="td-4" />
          <td className="td-5">
            <span />
          </td>
        </tr>
        <tr>
          <td className="td-1">
            <span />
          </td>
          <td className="td-2">
            <span />
          </td>
          <td className="td-3">
            <span />
          </td>
          <td className="td-4" />
          <td className="td-5">
            <span />
          </td>
        </tr>
        <tr>
          <td className="td-1">
            <span />
          </td>
          <td className="td-2">
            <span />
          </td>
          <td className="td-3">
            <span />
          </td>
          <td className="td-4" />
          <td className="td-5">
            <span />
          </td>
        </tr>
        <tr>
          <td className="td-1">
            <span />
          </td>
          <td className="td-2">
            <span />
          </td>
          <td className="td-3">
            <span />
          </td>
          <td className="td-4" />
          <td className="td-5">
            <span />
          </td>
        </tr>
        <tr>
          <td className="td-1">
            <span />
          </td>
          <td className="td-2">
            <span />
          </td>
          <td className="td-3">
            <span />
          </td>
          <td className="td-4" />
          <td className="td-5">
            <span />
          </td>
        </tr>
        <tr>
          <td className="td-1">
            <span />
          </td>
          <td className="td-2">
            <span />
          </td>
          <td className="td-3">
            <span />
          </td>
          <td className="td-4" />
          <td className="td-5">
            <span />
          </td>
        </tr>
        <tr>
          <td className="td-1">
            <span />
          </td>
          <td className="td-2">
            <span />
          </td>
          <td className="td-3">
            <span />
          </td>
          <td className="td-4" />
          <td className="td-5">
            <span />
          </td>
        </tr>
        <tr>
          <td className="td-1">
            <span />
          </td>
          <td className="td-2">
            <span />
          </td>
          <td className="td-3">
            <span />
          </td>
          <td className="td-4" />
          <td className="td-5">
            <span />
          </td>
        </tr>
        <tr>
          <td className="td-1">
            <span />
          </td>
          <td className="td-2">
            <span />
          </td>
          <td className="td-3">
            <span />
          </td>
          <td className="td-4" />
          <td className="td-5">
            <span />
          </td>
        </tr>
        <tr>
          <td className="td-1">
            <span />
          </td>
          <td className="td-2">
            <span />
          </td>
          <td className="td-3">
            <span />
          </td>
          <td className="td-4" />
          <td className="td-5">
            <span />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default TableLoader;
