import React, { Component } from 'react';
import DataGrid from 'components/forms/DataGrid';
import Klikkie from '_klikkie';
import { ProfileFormatter } from 'components/helpers/formatters';

const klikkie = new Klikkie();

class Admins extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        key: 'userId',
        name: 'User',
        width: 250,
        formatter: (
          <ProfileFormatter
            detailOpen={userId => this.props.detailOpen(userId)}
            toastOpen={(message, level) => this.props.toastOpen(message, level)}
          />
        ),
      },
      {
        key: 'profile.email',
        name: 'email',
        sortable: true,
        filterable: true,
        resizable: true,
        width: 220,
      },
      {
        key: 'adminRole',
        name: 'Admin Role',
        sortable: true,
        filterable: true,
        resizable: true,
        width: 180,
      },
    ];
    this.state = {
      rows: undefined,
    };
  }

  componentDidMount = async () => {
    try {
      const rows = await klikkie.users().getAdmins();

      this.setState({
        rows,
      });
    } catch (error) {
      console.error(error);
    }
  };

  getCellActions = column => {
    if (column.key === 'actions') {
      return [
        {
          icon: 'icon ion-md-cog',
          actions: [],
        },
      ];
    }
    return false;
  };

  render() {
    return (
      <DataGrid
        name={this.props.name}
        columns={this.columns}
        rows={this.state.rows}
        actions={this.getCellActions}
        print={false}
      />
    );
  }
}
export default Admins;
