/* eslint-disable max-classes-per-file */
import React, { Component } from 'react';
import DataGrid from 'components/forms/DataGrid';
import { ConvertTimestamp2DateTime } from 'components/helpers/formatters';
import creditsActions from 'actions/creditsActions';

class DetailCreditsTab extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        key: 'id',
        name: 'id',
        width: 60,
        sortable: true,
        filterable: true,
        resizable: true,
      },
      {
        key: 'createdAt',
        name: 'Time',
        width: 180,
        sortable: true,
        filterable: true,
        resizable: true,
        formatter: ConvertTimestamp2DateTime,
      },
      {
        key: 'amount',
        name: 'Amount',
        width: 80,
        sortable: true,
        filterable: true,
        resizable: true,
      },
      {
        key: 'activityId',
        name: 'ActivityId',
        width: 100,
        sortable: true,
        filterable: true,
        resizable: true,
      },
    ];
    this.state = {
      rows: undefined,
    };
  }

  async componentDidMount() {
    try {
      const rows = await creditsActions.getCreditsTransactionsByUserId(this.props.uid);
      this.props.count(rows.length);
      this.setState({
        rows,
      });
    } catch (e) {
      console.error(e);
    }
  }

  render() {
    return (
      <span>
        <DataGrid columns={this.columns} rows={this.state.rows} print={false} />
      </span>
    );
  }
}

export default DetailCreditsTab;
