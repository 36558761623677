import React from 'react';
import { ExportToCsv } from 'export-to-csv';
import DataUtils from 'components/helpers/utils/data';
import { decodeHTML } from 'entities';
// const entities = require('entities');

class DataGridExportCsvButton extends React.Component {
  exportData = () => {
    if (this.props.data.length < 1) return alert('Please select at least one row');
    const date = new Date().toISOString().slice(0, 10);
    const filteredData = this.props.data
      // eslint-disable-next-line no-prototype-builtins
      .filter(r => !r.hasOwnProperty('__metaData'))
      .map(DataUtils.flattenObject);

    const headers = filteredData.flatMap(Object.keys).filter(r => r.length > 1);

    const dataWithAllHeaders = filteredData.map(row => {
      const newRow = {};
      headers.forEach(h => {
        newRow[h] = row[h] ? decodeHTML(row[h]) || '' : '';
      });
      return newRow;
    });
    const options = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalseparator: '.',
      filename: `Export_${this.props.title || ''}_${date}`,
      showLabels: true,
      showTitle: false,
      useBom: true,
      headers,
    };

    const exportToCsv = new ExportToCsv(options);
    return exportToCsv.generateCsv(dataWithAllHeaders);
  };

  render() {
    return (
      <button type="button" disabled={this.props.disabled} onClick={this.exportData} className="button">
        {this.props.button || 'Export csv'}
      </button>
    );
  }
}
export default DataGridExportCsvButton;
