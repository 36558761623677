import React from 'react';

const ConvertTimestamp2Date = props => {
  const date =
    props.value && new Date(props.value) instanceof Date
      ? new Date(props.value).toISOString().split('T')[0]
      : props.value;

  return <span>{date}</span>;
};

export default ConvertTimestamp2Date;
