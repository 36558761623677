import React, { Component } from 'react';
import { cloudStoragePicturesDetailsURL, storagePictures } from 'config/base';
import Spinner from 'components/helpers/Spinner';

class FulfillmentContentPanelCoupon extends Component {
  constructor(props) {
    super(props);
    this.state = { couponLink: null, loading: true };
  }

  componentDidMount = async () => {
    try {
      const { link } = this.props;
      const couponLink = link ? await storagePictures.ref(link).getDownloadURL() : null;

      this.setState({ couponLink, loading: false });
    } catch (error) {
      console.error(error);
      this.props.toastOpen('Failed to load all data', 'error');
    }
  };

  render = () => {
    const { couponLink, loading } = this.state;
    const { link, couponCode } = this.props;

    return (
      <span>
        {loading && <Spinner />}
        {!loading && (
          <>
            <div className="taskbar">
              <span className="header">COUPON: {couponCode}</span>
              <a
                className="button"
                type="button"
                href={`${cloudStoragePicturesDetailsURL}/${link};tab=live_object`}
                target="_blank"
                rel="noreferrer"
              >
                Open Files
              </a>
            </div>

            {couponLink && (
              <img alt="coupon" style={{ maxHeight: '80vh', maxWidth: '100%', padding: '30px' }} src={couponLink} />
            )}
            {!couponLink && !loading && (
              <h2 style={{ padding: '30px' }}>
                No rendered coupon found. You can{' '}
                <button type="button" onClick={this.sendToSupplier}>
                  produce
                </button>{' '}
                the coupon to send it to the supplier.
              </h2>
            )}
          </>
        )}
      </span>
    );
  };
}

export default FulfillmentContentPanelCoupon;
