import React from 'react';
import moment from 'moment';
import subscriptionActions from 'actions/subscriptionActions';
import orderActions from 'actions/orderActions';
import { willPauseInTheFuture, willChurnInTheFuture, willActivateInTheFuture } from 'actions/eventSchedulerOperations';
import GetLinkToOrder from 'components/helpers/GetLinkToOrder';
import { ProductPackage } from 'components/helpers/formatters';
import 'styles/_detail-page.scss';
import {
  SubscriptionHardChurnButton,
  SubscriptionEditButton,
  SubscriptionScheduleChangeButton,
  SubscriptionPauseUnpauseButton,
  SubscriptionRecreateButton,
  GenericCopyButton,
} from 'components/buttons';
import Spinner from 'components/helpers/Spinner';

/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable jsx-a11y/aria-role */

class DetailSubscriptionTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      subscription: undefined,
      logs: [],
    };
  }

  componentDidMount = () => {
    this.getSubscription();
    this.getSubscriptionLogs();
  };

  getSubscription = async () => {
    try {
      const userId = this.props.uid;
      const subscription = await subscriptionActions.getSubscription(this.props.uid);

      if (subscription === undefined) {
        throw new Error('no subscription found');
      }

      subscription.createdAt = orderActions.compileDate(subscription.createdAt);
      subscription.updatedAt = orderActions.compileDate(subscription.updatedAt);
      subscription.pausedAt = orderActions.compileDate(subscription.pausedAt);
      subscription.churnedAt = orderActions.compileDate(subscription.churnedAt);
      subscription.renewedAt = orderActions.compileDate(subscription.renewedAt);
      subscription.updatedAt = orderActions.compileDate(subscription.updatedAt);
      subscription.prepaidUntil = orderActions.compileDate(subscription.prepaidUntil);
      subscription.isPausedInTheFuture = (await willPauseInTheFuture(userId)) || 'no date';
      subscription.isChurnedInTheFuture = (await willChurnInTheFuture(userId)) || 'no date';
      subscription.isReactivatedInTheFuture = (await willActivateInTheFuture(userId)) || 'no date';

      subscription.statusPresentational = subscription.status + (subscription.status === 'active' ? '  ❤️ ' : '  💔 ');
      if (
        subscription.status !== 'active' &&
        subscription.isReactivatedInTheFuture &&
        subscription.isReactivatedInTheFuture !== 'no date'
      ) {
        subscription.statusPresentational += ` until ${subscription.isReactivatedInTheFuture}`;
      }

      if (
        subscription.status === 'active' &&
        subscription.isPausedInTheFuture &&
        subscription.isPausedInTheFuture !== 'no date'
      ) {
        subscription.statusPresentational += ` until ${subscription.isPausedInTheFuture}`;
      }

      if (
        subscription.status === 'active' &&
        subscription.isChurnedInTheFuture &&
        subscription.isChurnedInTheFuture !== 'no date'
      ) {
        subscription.statusPresentational += ` until ${subscription.isChurnedInTheFuture}`;
      }

      this.props.count('✓');
      this.setState({ subscription, loading: false });
    } catch (error) {
      console.info(error);

      this.setState({ subscription: undefined, loading: false });
    }
  };

  getSubscriptionLogs = async () => {
    try {
      const logs = await subscriptionActions.getSubscriptionLogs(this.props.uid);

      if (logs === undefined) {
        throw new Error('no logs found');
      }

      this.setState({ logs });
    } catch (error) {
      console.info(error);

      this.setState({ logs: [] });
    }
  };

  renderEventLog = event => {
    if (event === 'active') {
      return <span role="img">active ❤️</span>;
    }
    if (event === 'churned') {
      return <span role="img">churned 💔</span>;
    }

    if (event === 'paused') {
      return <span role="img">paused 💤</span>;
    }

    return event;
  };

  GetLinkToOrder = ({ value }) => <GetLinkToOrder oid={value} />;

  handleChange = () => {
    this.getSubscription();
    this.getSubscriptionLogs();
  };

  render() {
    const { loading, subscription, logs } = this.state;
    const status = subscription ? subscription.status : '';
    if (loading) {
      return <Spinner />;
    }

    return (
      <div>
        <ul className="controls">
          <li>
            <SubscriptionEditButton userId={this.props.uid} onChange={this.handleChange} />
          </li>
          <li>
            <SubscriptionPauseUnpauseButton uid={this.props.uid} status={status} onChange={this.handleChange} />
          </li>
          <li>
            <SubscriptionHardChurnButton uid={this.props.uid} />
          </li>
          <li>
            <SubscriptionRecreateButton uid={this.props.uid} />
          </li>
          <li>
            <SubscriptionScheduleChangeButton userId={this.props.uid} onChange={this.handleChange} />
          </li>
        </ul>

        {subscription && (
          <div>
            <span className="subHeader">SUBSCRIPTION TYPE</span>
            <ul className="subSection">
              <li>
                <span className="label">Status</span> {subscription.statusPresentational}
              </li>

              <li>
                <span className="label">TermType</span> {subscription.termType}
              </li>

              <li>
                <span className="label">Recurrence Day</span> {subscription.monthlyRecurrenceDay}
              </li>
              <li>
                <span className="label">Package</span> <ProductPackage value={subscription.packageId} />
              </li>
              <li>
                <span className="label">Prepaid Until</span> {subscription.prepaidUntil}
              </li>
              <li>
                <span className="label">Active order</span> {subscription.activeOrder}
                <GenericCopyButton title="Copy active order" text="" value={subscription.activeOrder} />
              </li>
            </ul>
            <span className="subHeader">DATES</span>
            <ul className="subSection">
              <li>
                <span className="label">Created At</span> {subscription.createdAt}
              </li>

              <li>
                <span className="label">Paused At</span> {subscription.pausedAt}
              </li>
              <li>
                <span className="label">Paused Future 💤</span> {subscription.isPausedInTheFuture}
              </li>
              <li>
                <span className="label">Churned At</span> {subscription.churnedAt}
              </li>

              <li>
                <span className="label">Churned Future 💔</span> {subscription.isChurnedInTheFuture}
              </li>
            </ul>
            <div className="logsContainer">
              <span className="subHeader">CHANGE LOG</span>
              {logs.length > 0 && (
                <div className="logs subSection">
                  <div className="header">Date</div>
                  <div className="header">Event</div>
                  <div className="header">Value</div>
                  {logs.map(({ time, event, value }) => (
                    <React.Fragment key={time}>
                      <div>{moment(time).format('YYYY-MM-DD HH:mm')}</div>
                      <div>{this.renderEventLog(event)}</div>
                      <div>{value}</div>
                    </React.Fragment>
                  ))}
                </div>
              )}
              {logs.length === 0 && (
                <div>
                  NO LOGS <span role="img">😔</span>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default DetailSubscriptionTab;
