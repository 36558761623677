import orderActions from 'actions/orderActions';

export const removePictureOrderPictures = async (userId, orderId) => {
  try {
    const updateOrder = {
      pictures: {},
    };

    await orderActions.updatePictureOrder(userId, orderId, updateOrder);
  } catch (error) {
    console.error('removePictureOrderPictures', error);

    throw error;
  }
};

export const resetPictureOrder = async (userId, orderId) => {
  try {
    await removePictureOrderPictures(userId, orderId);
    await orderActions.resetPictureOrder(userId, orderId);
  } catch (error) {
    console.error('removePictureOrderPictures', error);

    throw error;
  }
};

export const migrateEditorOrder = async (userId, orderId) => {
  try {
    await resetPictureOrder(userId, orderId);
    await orderActions.importOrderToPictureOrder(userId, orderId);
  } catch (error) {
    console.error('migrateEditorOrder', error);

    throw error;
  }
};

export const pictureOrderChangeStatus = async (userId, orderId, status) => {
  try {
    const allowed = ['pending', 'fulfilled', 'rejected'];
    if (!allowed.includes(status)) throw new Error('Status is not allowed');
    const updateOrder = { status };

    return orderActions.updatePictureOrder(userId, orderId, updateOrder);
  } catch (error) {
    console.error('openPictureOrder', error);

    throw error;
  }
};

export const reprintPictureOrder = async (userId, orderId) => {
  try {
    await orderActions.deleteOrderFromQueue(orderId);
    await pictureOrderChangeStatus(userId, orderId, 'pending');
    await pictureOrderChangeStatus(userId, orderId, 'fulfilled');
  } catch (error) {
    console.error('reprintPictureOrder', error);

    throw error;
  }
};
