import React, { Component } from 'react';
import { auth } from 'config/base';
import SubscriptionList from 'components/helpers/SubscriptionList';
import Klikkie from '_klikkie';
import ReactMarkdown from 'react-markdown';

const klikkie = new Klikkie();
class Home extends Component {
  constructor() {
    super();
    this.state = {
      admin: false,
      displayName: '',
      releaseNotes: '',
    };
  }

  componentDidMount = () => {
    this.getReleaseNote();
    this.checkUser();
  };

  checkUser = () => {
    return auth.onAuthStateChanged(async user => {
      try {
        if (!user) throw new Error('No user credentials found');
        const { uid: userId, displayName } = user;
        const admin = await klikkie.users(userId).isAdmin();
        if (!admin) throw new Error('Not an admin');

        this.setState({
          admin,
          displayName,
        });
      } catch (error) {
        console.error(error);
        window.location.reload();
      }
    });
  };

  getReleaseNote = async () => {
    try {
      // eslint-disable-next-line global-require
      const readmePath = await require('../../RELEASE_NOTES.md');
      const response = await fetch(readmePath.default);
      const releaseNotes = await response.text();
      this.setState({
        releaseNotes,
      });
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    const { releaseNotes } = this.state;
    return (
      <div className="home">
        {this.state.displayName && <h1>Hi {this.state.displayName}! </h1>}
        Welcome
        {(this.state.admin === 'admin' || this.state.admin === 'customerService') && <SubscriptionList />}
        <div className="notes">
          <ReactMarkdown source={releaseNotes} />
        </div>
      </div>
    );
  }
}
export default Home;
