import React, { useEffect, useState } from 'react';
import { consoleUrl, config } from 'config/base';
import ScanQrToOrder from 'components/helpers/ScanQrToOrder';
import FulfillmentContentPanelOriginalPictures from './FulfillmentContentPanelOriginalPictures';

const ContentPanelPictureOrder = ({ history, match, toastOpen }) => {
  const [uesrId, setUserId] = useState(null);
  const [pictureOrderId, setPictureOrderId] = useState(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const { userId, orderId } = match.params;
    setUserId(userId);
    setPictureOrderId(orderId);
    setLoaded(true);
  }, []);

  return (
    <span style={{ padding: '20px' }}>
      <a
        className="button"
        href={`${consoleUrl}database/${config.projectId}/data/~2FPictureOrders~2F${uesrId}~2F${pictureOrderId}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        Open Database
      </a>
      <a
        className="button"
        href={`${consoleUrl}storage/${config.storageBucket}/files/~2Fuser~2F${uesrId}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        Open Storage
      </a>
      {loaded && (
        <FulfillmentContentPanelOriginalPictures orderId={pictureOrderId} userId={uesrId} toastOpen={toastOpen} />
      )}

      <ScanQrToOrder history={history} />
    </span>
  );
};

export default ContentPanelPictureOrder;
