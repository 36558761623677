import React from 'react';

class CouponModal extends React.Component {
  constructor() {
    super();
    this.escFunction = this.escFunction.bind(this);
    this.state = {
      startDate: undefined,
      endDate: undefined,
      submitDate: undefined,
      uid: '',
      oid: '',
      loaded: false,
      visibleContent: 1, // 2
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false);
  }

  componentWillUnmount = () => {
    document.removeEventListener('keydown', this.escFunction, false);
    const blankState = {};
    Object.keys(this.state).forEach(stateKey => {
      blankState[stateKey] = undefined;
    });
    this.setState(blankState);
  };

  cancelDetail = () => {
    this.props.close();
  };

  clickHandler = () => {
    this.props.handler();
  };

  saveHandler = () => {
    this.props.save();
  };

  escFunction(event) {
    if (event.keyCode !== 27) return false;
    return this.cancelDetail();
  }

  render() {
    return (
      <div className="couponModal  true ">
        <div className="couponModal-container">
          <button type="button" className="close" onClick={this.cancelDetail}>
            &times;
          </button>
          {this.props.form}
          {/* <AddCoupon couponData={this.props.row} save={this.saveHandler.bind(this)} /> */}
        </div>
      </div>
    );
  }
}
export default CouponModal;
