import moment from 'moment';
import { apiBaseUrl, auth } from 'config/base';

const API_ENDPOINT = `${apiBaseUrl}/eventSchedules`;

const resultMiddleware = async response => {
  if (response.status !== 200) {
    return null;
  }

  const result = await response.json();

  return result.data;
};

const getHeaders = async () => {
  const token = await auth.currentUser.getIdToken();

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  return headers;
};

export default {
  createEvent: async (event, userId, rawDate) => {
    if (!userId || !event || !rawDate) {
      throw new Error('No Event or UserId or Date');
    }

    const triggerAt = moment(rawDate).format('YYYY-MM-DD');

    const requestBody = {
      method: 'POST',
      headers: await getHeaders(),
      body: JSON.stringify({
        event,
        userId,
        triggerAt,
        customAttributes: {
          userId,
        },
      }),
    };

    return fetch(`${API_ENDPOINT}`, requestBody).then(resultMiddleware);
  },
  getEvents: async userId => {
    try {
      if (!userId) {
        throw new Error('No UserId');
      }

      const requestBody = {
        method: 'GET',
        headers: await getHeaders(),
      };

      console.info(`${API_ENDPOINT}/user/${userId}`);

      const events = await fetch(`${API_ENDPOINT}/user/${userId}`, requestBody).then(resultMiddleware);

      if (!events) {
        return [];
      }

      return events;
    } catch {
      return [];
    }
  },
  deleteEvent: async (event, userId) => {
    if (!event || !userId) {
      throw new Error('No Event or UserId');
    }

    const requestBody = {
      method: 'DELETE',
      headers: await getHeaders(),
    };

    return fetch(`${API_ENDPOINT}/user/${userId}/${event}`, requestBody)
      .then(resultMiddleware)
      .catch(() => null);
  },
};
