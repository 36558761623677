// eslint-disable-next-line max-classes-per-file
import React, { Component } from 'react';
import { Row } from 'react-data-grid';
import DataGrid from 'components/forms/DataGrid';
import DataUtils from 'components/helpers/utils/data';
import Klikkie from '_klikkie';
import addressActions from 'actions/addressActions';
import { ProductQueuePrintCouponLabelButton } from 'components/buttons';

const klikkie = new Klikkie();
// Custom Formatter component
class RowRenderer extends React.Component {
  getRowStyle = () => {
    const backgroundColor = this.props.row.active ? '#c3f3e3' : '';
    return {
      backgroundColor,
    };
  };

  render() {
    return (
      <div style={this.getRowStyle()}>
        <Row
          ref={node => {
            this.row = node;
          }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...this.props}
        />
      </div>
    );
  }
}

class DetailAddressesTab extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        key: 'addressId',
        name: 'addressId',
        width: 170,
        sortable: true,
        filterable: true,
        resizable: true,
      },
      {
        key: 'fullname',
        name: 'User',
        width: 100,
        sortable: true,
        filterable: true,
        resizable: true,
        editable: true,
      },
      {
        key: 'address',
        name: 'Address',
        width: 150,
        sortable: true,
        filterable: true,
        resizable: true,
        editable: true,
      },
      {
        key: 'address2',
        name: 'Address 2',
        width: 100,
        sortable: true,
        filterable: true,
        resizable: true,
        editable: true,
      },
      {
        key: 'zipcode',
        name: 'Zipcode',
        width: 100,
        sortable: true,
        filterable: true,
        resizable: true,
        editable: true,
      },
      {
        key: 'city',
        name: 'City',
        width: 150,
        sortable: true,
        filterable: true,
        resizable: true,
        editable: true,
      },
      {
        key: 'region',
        name: 'Region',
        width: 150,
        sortable: true,
        filterable: true,
        resizable: true,
        editable: true,
      },
      {
        key: 'countryCode',
        name: 'Country',
        width: 150,
        sortable: true,
        filterable: true,
        resizable: true,
        editable: true,
      },
      {
        key: 'actionsColumn',
        name: '',
        width: 150,
        sortable: false,
        filterable: false,
        resizable: false,
      },
    ];
    this.state = {
      rows: undefined,
    };
  }

  getCellActions = (column, row) => {
    const actionsColumn = [
      {
        icon: 'icon ion-md-more',
        actions: [
          {
            text: 'Delete Address',
            callback: () => this.deleteAddress(row),
          },
          {
            text: 'Make Primary',
            callback: () => this.setPrimaryAddress(row),
          },
        ],
      },
    ];

    const cellActions = {
      actionsColumn,
    };
    return cellActions[column.key];
  };

  deleteAddress = async row => {
    try {
      if (row.active) {
        return this.props.toastOpen(
          "You can't delete the active address. To delete this address first set another address as the active address.",
        );
      }
      const confirm = window.confirm(
        `Are you sure that you want to delete this address?

        ${row.address}
        ${row.address2 || ''}
        ${row.zipcode} ${row.city}
        ${row.countryCode}`,
      );
      if (!confirm) return false;

      await klikkie
        .users(this.props.uid)
        .addresses(row.addressId)
        .delete();
      this.componentDidMount();
      return this.props.toastOpen(`Succesfully deleted address ${row.addressId}`);
    } catch (error) {
      return this.props.toastOpen(`Something went wrong deleting address ${row.addressId}. ${error}`);
    }
  };

  setPrimaryAddress = async row => {
    try {
      const { uid: userId } = this.props;
      const { addressId } = row;
      await klikkie
        .users(userId)
        .addresses(addressId)
        .setPrimary();
      this.componentDidMount();
      this.props.toastOpen(`Succesfully set address ${row.addressId} as primary address`);
    } catch (error) {
      this.props.toastOpen(`Something went wrong setting address ${row.addressId} as active address. ${error}`);
    }
  };

  componentDidMount = async () => {
    try {
      this.setState({
        rows: undefined,
      });
      const { uid: userId } = this.props;
      const addresses = await klikkie
        .users(userId)
        .addresses()
        .get();
      const activeAddressId = await klikkie
        .users(userId)
        .addresses()
        .getPrimary();
      const rows = addresses.map(obj => ({ ...obj, active: obj.addressId === activeAddressId }));
      rows.sort(DataUtils.dynamicSort('-active'));
      this.props.count(rows.length);

      this.setState({
        rows,
      });
    } catch (e) {
      console.error(e);
    }
  };

  // eslint-disable-next-line no-unused-vars
  onGridRowsUpdated = async ({ fromRow, toRow, updated }) => {
    try {
      const newRow = Object.assign(this.state.rows[fromRow], updated);
      const { active } = newRow;
      delete newRow.active;
      if (
        newRow.fullname !== '' &&
        newRow.address !== '' &&
        newRow.zipcode !== '' &&
        newRow.city !== '' &&
        newRow.countryCode !== ''
      ) {
        const [{ addressId }] =
          newRow.addressId === ''
            ? await addressActions.createAddress(this.props.uid, newRow)
            : await addressActions.updateAddress(this.props.uid, newRow.addressId, newRow);
        newRow.addressId = addressId;
        this.props.toastOpen(`Address "${addressId}" saved`);
      }
      const { rows } = this.state;
      delete rows[fromRow];
      this.setState({ rows }, () => {
        rows[fromRow] = Object.assign(newRow, { active });
        this.setState({ rows });
      });
    } catch (error) {
      console.error(error);
      this.props.toastOpen(error);
    }
  };

  createAddress = async () => {
    try {
      const emptyRow = {
        addressId: '',
        fullname: '',
        address: '',
        address2: '',
        zipcode: '',
        city: '',
        region: '',
        countryCode: '',
      };

      this.setState(prevState => {
        const { rows } = prevState;
        const newRows = [emptyRow, ...rows];
        return { rows: newRows };
      });

      this.props.toastOpen('New Address created. Double-click to added');
    } catch (error) {
      console.error(error);
      this.props.toastOpen(error);
    }
  };

  render() {
    return (
      <span>
        <DataGrid
          columns={this.columns}
          rows={this.state.rows}
          print={false}
          rowRenderer={RowRenderer}
          actions={this.getCellActions}
          onGridRowsUpdated={this.onGridRowsUpdated}
          enableCellSelect
          getSelection={selection => this.setState({ selection })}
          extraTools={
            <span>
              <button className="button" type="button" onClick={this.createAddress}>
                Add Address
              </button>
              <ProductQueuePrintCouponLabelButton labelType="TNT" data={this.state.selection} />
            </span>
          }
        />
      </span>
    );
  }
}

export default DetailAddressesTab;
