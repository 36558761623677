/* eslint-disable jsx-a11y/no-autofocus */
import React, { Component } from 'react';
import QrReader from 'react-qr-reader';

class ScanQrToOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      delay: 300,
      result: 'No result',
    };
    this.query = React.createRef();
  }

  handleScan = query => {
    if (!query) return;
    const url = query.startsWith('https')
      ? new URL(query)
      : new URL(`${window.location.href.substring(0, window.location.href.lastIndexOf('/'))}/${query}`);

    if (url.pathname) this.props.history.push(url.pathname);
  };

  handleKeyPress = e => {
    // filter out the ] that is used for submitting
    const query = this.query.current.value.replace(/]/g, '');
    // don't listen to space as we use it as the button for next order
    if (query.includes(']')) return;
    if (e.key === 'Enter') {
      this.handleScan(query);
      this.query.current.value = '';
    }
  };

  handleError = err => {
    console.error(err);
  };

  render() {
    return (
      <div className="QrSscanner" key={this.props.history}>
        <input className="QrInput" type="text" autoFocus ref={this.query} onKeyDown={this.handleKeyPress} />
        <QrReader showViewFinder={false} delay={this.state.delay} onError={this.handleError} onScan={this.handleScan} />
        <p>{this.state.result}</p>
      </div>
    );
  }
}
export default ScanQrToOrder;
