import React, { Component } from 'react';
import sa from 'actions/statisticsActions';
import DataGrid from 'components/forms/DataGrid';
import { ProductPackage } from 'components/helpers/formatters';

const {
  Filters: { MultiSelectFilter },
} = require('react-data-grid-addons');

class PauseReasons extends Component {
  constructor() {
    super();
    this.columns = [
      {
        key: 'profile',
        name: 'profile',
        width: 80,
        sortable: true,
        filterable: true,
        resizable: true,
      },
      {
        key: 'countryCode',
        name: 'country Code',
        width: 80,
        sortable: true,
        filterable: true,
        resizable: true,
      },
      {
        key: 'packageId',
        name: 'package',
        width: 180,
        sortable: true,
        filterable: true,
        resizable: true,
        formatter: ProductPackage,
      },
      {
        key: 'createdAt',
        name: 'created At',
        width: 80,
        sortable: true,
        filterable: true,
        resizable: true,
      },
      {
        key: 'pausedAt',
        name: 'paused At',
        width: 80,
        sortable: true,
        filterable: true,
        resizable: true,
      },
      {
        key: 'subscriptionCreatedAt',
        name: 'subscription Created At',
        width: 80,
        sortable: true,
        filterable: true,
        resizable: true,
      },
      {
        key: 'reason',
        name: 'reason',
        width: 350,
        filterable: true,
        filterRenderer: MultiSelectFilter,
      },
      {
        key: 'months',
        name: 'paused for',
        width: 100,
        sortable: true,
        fillterable: true,
      },
      {
        key: 'comment',
        name: 'comments',
        width: 900,
      },
    ];
    this.state = {
      rows: [],
    };
  }

  componentDidMount = async () => {
    try {
      const res = await sa.pauseStatistics();

      const rows = res.reduce((acc, item) => {
        const pausePeriod =
          item.months === 'indefinitely' || !item.months
            ? item.months
            : `${item.months} month${parseInt(item.months, 10) !== 1 ? 's' : ''}`;

        return acc.concat({
          ...item,
          months: pausePeriod,
        });
      }, []);

      return this.setState({ rows });
    } catch (error) {
      console.error(error);
      return this.props.toastOpen(error, 'error');
    }
  };

  addRow = async () => {
    try {
      await sa.addStatistic({}, 'Paused');
      this.props.toastOpen('Row added');
    } catch (error) {
      console.error(error);
      this.props.toastOpen(error, 'error');
    }
  };

  getCellActions = (column, row) => {
    if (column.key === 'profile') {
      return [
        {
          icon: 'icon ion-md-person',
          text: 'open',
          callback: () => {
            this.props.detailOpen(row.userId);
          },
        },
      ];
    }
    return false;
  };

  render() {
    return (
      <div>
        <DataGrid
          rows={this.state.rows}
          columns={this.columns}
          print={false}
          actions={this.getCellActions}
          name={this.props.name}
          extraTools={
            <button type="button" className="button" onClick={this.addRow}>
              Add Reason
            </button>
          }
        />
      </div>
    );
  }
}

export default PauseReasons;
