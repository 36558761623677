import React from 'react';
import orderActions from 'actions/orderActions';

class printDate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: '',
    };
  }

  componentDidMount = async () => {
    try {
      const { orderId } = this.props.dependentValues;
      const date = await orderActions.getfulfilledAt(orderId);

      this.setState({ date });
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    const { date } = this.state;
    return <span>{date}</span>;
  }
}

export default printDate;
