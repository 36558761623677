/* eslint-disable react/no-string-refs */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { MdSearch, MdAdd, MdAlarmAdd, MdPlaylistAdd } from 'react-icons/md';
import { DiscountType } from '@klikkie/klikkie-schemas/lib/interfaces/Coupon';
import DataGrid from 'components/forms/DataGrid';
import { database } from 'config/base';
import orderActions from 'actions/orderActions';
import CouponModal from 'components/detail/CouponModal';
import CouponModalEdit from 'components/detail/CouponModalEdit';
import CouponBatch from 'components/forms/CouponBatch';
import AddCoupon from 'components/coupons/AddCoupon';
import AddDiscount from 'components/forms/AddDiscount';
import RemoveCoupons from 'components/coupons/RemoveCoupons';
import DataUtils from 'components/helpers/utils/data';
import CouponCodeFormatter from 'components/helpers/formatters/CouponCodeFormatter';

const {
  Filters: { NumericFilter, AutoCompleteFilter },
} = require('react-data-grid-addons');

class Coupons extends Component {
  constructor() {
    super();
    this.columns = [
      {
        key: 'couponCode',
        name: 'Code',
        sortable: true,
        filterable: true,
        resizable: true,
        width: 230,
        formatter: CouponCodeFormatter,
      },
      {
        key: 'name',
        name: 'Name',
        sortable: true,
        filterable: true,
        resizable: true,
        width: 150,
      },
      {
        key: 'orderId',
        name: 'Order Id',
        sortable: true,
        filterable: true,
        resizable: true,
        width: 150,
      },
      {
        key: 'discount',
        name: 'Discount',
        sortable: true,
        filterable: true,
        resizable: true,
        width: 75,
        filterRenderer: NumericFilter,
      },
      {
        key: 'limitToPackageFilter',
        name: 'Package Limit',
        sortable: true,
        filterable: true,
        resizable: true,
        width: 120,
        filterRenderer: AutoCompleteFilter,
      },
      {
        key: 'redeemLimit',
        name: 'Redeem Limit',
        sortable: true,
        filterable: true,
        resizable: true,
        width: 120,
      },
      {
        key: 'createdAt',
        name: 'Created at',
        sortable: true,
        filterable: true,
        resizable: true,
        width: 100,
      },
      {
        key: 'expiresAt',
        name: 'Expires at',
        sortable: true,
        filterable: true,
        resizable: true,
        width: 100,
      },
      {
        key: 'updatedAt',
        name: 'Updated at',
        sortable: true,
        filterable: true,
        resizable: true,
        width: 100,
      },
      {
        key: 'redeems',
        name: 'Redeems',
        sortable: true,
        filterable: true,
        resizable: true,
        width: 150,
      },
      {
        key: 'claims',
        name: 'Claims',
        sortable: true,
        filterable: true,
        resizable: true,
        width: 150,
      },
    ];
    this.state = {
      rows: [],
      selection: [],
      searching: false,
    };
  }

  searchClickHandler = e => {
    e.preventDefault();
    return this.searchCoupon(this.refs.coupon.value.toUpperCase());
  };

  searchKeyDownHandler = event => {
    if (event.keyCode === 27) {
      this.refs.coupon.value = '';
      return this.setState({ rows: [] });
    }
    if (event.key !== 'Enter') return false;
    event.preventDefault();
    return this.searchCoupon(this.refs.coupon.value.toUpperCase());
  };

  searchCoupon = async query => {
    try {
      if (!query || query === '') {
        return;
      }
      if (query.length < 6) {
        alert('Enter more than 6 characters');
        return;
      }
      this.setState({ rows: undefined, searching: true });

      const fetchCouponData = async couponSnapshot => {
        const coupon = couponSnapshot.val();
        const { name, orderId, discount, redeemLimit } = coupon;
        const [redeems, claims] = await Promise.all([
          orderActions.getDiscountCodeRedeems(couponSnapshot.key).catch(() => '?'),
          orderActions.getDiscountCodeClaims(couponSnapshot.key).catch(() => '?'),
        ]);

        return {
          couponCode: couponSnapshot.key,
          name,
          orderId,
          limitToPackageFilter: coupon.limitToPackages ? coupon.limitToPackages.join(', ') : '',
          discount:
            coupon.discountType === DiscountType.Percent
              ? this.convertToPercent(discount)
              : this.convertToMoney(discount),
          discountType: (coupon.discountType || 'fixed').toUpperCase(),
          redeemLimit,
          createdAt: orderActions.compileDate(coupon.createdAt),
          updatedAt: orderActions.compileDate(coupon.updatedAt),
          expiresAt: coupon.expiresAt ? orderActions.compileDate(coupon.expiresAt) : '',
          redeems,
          claims,
        };
      };

      const singleCouponSnapshot = await database.ref(`couponService/${query}`).once('value');
      if (singleCouponSnapshot.exists()) {
        const rows = [await fetchCouponData(singleCouponSnapshot)];
        this.setState({ rows, searching: false });
        return;
      }

      const couponsSnapshot = await database
        .ref('couponService')
        .orderByKey()
        .startAt(`${query}`)
        .endAt(`${query}\uf8ff`)
        .limitToLast(100)
        .once('value');
      const couponsProcess = [];
      couponsSnapshot.forEach(couponSnapshot => {
        couponsProcess.push(fetchCouponData(couponSnapshot));
      });

      const rowsToAppend = await Promise.all(couponsProcess);
      rowsToAppend.sort(DataUtils.dynamicSort('-updatedAt'));
      this.setState({ rows: rowsToAppend, searching: false });
    } catch (error) {
      console.error(error);
    }
  };

  convertToMoney = value => {
    let amount = '';
    if (value !== undefined && value !== '') {
      amount = Number(value / 100).toFixed(2);
    }
    return amount;
  };

  convertToPercent = value => {
    let amount = '';
    if (value !== undefined && value !== '') {
      amount = `${Number(value).toFixed(2)}%`;
    }
    return amount;
  };

  getCellActions = (column, row) => {
    if (column.key === 'couponCode') {
      return [
        {
          icon: 'icon ion-md-create',
          text: 'open',
          callback: () => {
            this.setState({
              rowData: row,
              showEditModal: true,
              showModal: false,
            });
          },
        },
      ];
    }
    if (column.key === 'redeems') {
      return [
        {
          icon: 'icon ion-md-open',
          text: 'redeems',
          callback: () => {
            this.props.history.push(`/redeems/${row.couponCode}`);
          },
        },
      ];
    }
    return false;
  };

  showPage = () => {
    this.setState({ showModal: true, showEditModal: false, showDiscountModal: false });
  };

  closeHandler = () => {
    this.setState({ showModal: false, showEditModal: false, showBatchModal: false, showDiscountModal: false });
  };

  saveHandler = code => {
    this.setState({
      rows: [],
      showModal: false,
      showEditModal: false,
      showBatchModal: false,
      showDiscountModal: false,
    });
    this.refs.coupon.value = code;
    this.props.toastOpen(`Saved code ${code}`);
    return this.searchCoupon(this.refs.coupon.value.toUpperCase());
  };

  newCoupon = () => {
    this.setState({
      rowData: undefined,
      showModal: true,
      showBatchModal: false,
      showDiscountModal: false,
    });
  };

  newDiscount = () => {
    this.setState({
      rowData: undefined,
      showModal: true,
      showBatchModal: false,
      showDiscountModal: true,
    });
  };

  newBatch = () => {
    const { showBatchModal } = this.state;
    this.setState({
      showBatchModal: !showBatchModal,
      showModal: false,
      showDiscountModal: false,
    });
  };

  render() {
    return (
      <div>
        {this.state.showModal && (
          <CouponModal
            form={<AddCoupon couponData={this.props.row} save={this.saveHandler} />}
            close={this.closeHandler}
          />
        )}
        {this.state.showBatchModal && (
          <CouponModal form={<CouponBatch save={this.saveHandler} />} close={this.closeHandler} />
        )}
        {this.state.showEditModal && (
          <CouponModalEdit row={this.state.rowData} close={this.closeHandler} save={this.saveHandler} />
        )}
        {this.state.showDiscountModal && (
          <CouponModal form={<AddDiscount save={this.saveHandler} />} close={this.closeHandler} />
        )}

        <DataGrid
          loaded={!this.state.searching}
          rows={this.state.rows}
          coupons
          columns={this.columns}
          actions={this.getCellActions}
          name={this.props.name}
          print={false}
          getSelection={selection => this.setState({ selection })}
          extraTools={
            <span>
              <input
                ref="coupon"
                placeholder="Search a coupon"
                onKeyDown={this.searchKeyDownHandler}
                style={{ textTransform: 'uppercase', width: '150px', height: '30px' }}
              />
              <button
                type="button"
                style={{ marginLeft: '-33px', border: '0', background: 'none' }}
                className="small-btn"
                onClick={this.searchClickHandler}
              >
                <MdSearch />
              </button>

              <RemoveCoupons data={this.state.selection} />
              <button type="button" className="small-btn" onClick={this.newCoupon}>
                <MdAdd />
              </button>
              <button type="button" className="small-btn" onClick={this.newDiscount}>
                <MdAlarmAdd />
              </button>
              <button type="button" className="small-btn" onClick={this.newBatch}>
                <MdPlaylistAdd />
              </button>
            </span>
          }
        />
      </div>
    );
  }
}

export default withRouter(Coupons);
