import React from 'react';
import Message from 'components/helpers/Message';
import userActions from 'actions/userActions';
import { reprintPictureOrder } from 'actions/orderOperations';

class PictureOrderReprintButton extends React.Component {
  componentDidMount = () => {
    userActions.hasRights().then(data => {
      if (data) {
        console.info(this.props.data.length);

        this.setState({ show: true });
      } else {
        this.setState({ show: false });
        throw console.info('no admin');
      }
    });
  };

  reprintPictureOrders = () => {
    const { oid: orderId, userId, data: orders } = this.props;

    if (this.props.oid !== undefined) {
      return reprintPictureOrder(userId, orderId).then(() => this.showModal(orderId));
    }
    if (orders && orders.length !== 0) {
      orders.map(({ oid }) => reprintPictureOrder(userId, oid).then(() => this.showModal(oid)));
    }
    return true;
  };

  showModal = oid => {
    this.setState({ text: `Order ${oid}is reprinted.` });

    this.messageModal.showModal();
  };

  render() {
    return (
      <div className="moveOrderButton">
        <Message
          ref={instance => {
            this.messageModal = instance;
          }}
          text={this.state.text}
        />
        {this.state.show && (
          <button type="button" className="button" onClick={this.reprintPictureOrders.bind(this)}>
            <span role="img" aria-label="emoji">
              {this.state.loading && <img src="https://www.klikkie.nl/assets/img/loader.gif" alt="" />}
            </span>
            Reprint
          </button>
        )}
      </div>
    );
  }
}
export default PictureOrderReprintButton;
