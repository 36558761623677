import React from 'react';
import productPackageActions from 'actions/productPackageActions';

class ProductPackage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      packageId: this.props.value || '',
      variant: '',
    };
  }

  componentDidMount = () => this.loadData();

  loadData = async () => {
    try {
      const packageId = parseInt(this.props.value, 10);
      const { variant } = await productPackageActions.getPackage(packageId);
      this.setState({ packageId, variant });
    } catch (error) {
      console.error(error);
    }
  };

  componentDidUpdate = prevProps => {
    if (prevProps.dependentValues === this.props.dependentValues && prevProps.value === this.props.value) return;
    this.loadData();
  };

  render() {
    return (
      <span>
        <span
          style={{
            display: 'inline-block',
            borderRadius: '24px',
            fontSize: '10px',
            fontWeight: 600,
            height: 24,
            width: 24,
            textAlign: 'center',
            lineHeight: '25px',
            backgroundColor: '#999',
            color: 'white',
          }}
        >
          {this.state.packageId}
        </span>{' '}
        {this.state.variant}
      </span>
    );
  }
}

export default ProductPackage;
