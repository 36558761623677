/* eslint-disable react/no-string-refs */
import React from 'react';
import Modal from 'components/helpers/Modal';
import userActions from 'actions/userActions';
import { auth, apiBaseUrl } from 'config/base';

class AuthEditEmailAddress extends React.Component {
  constructor() {
    super();
    this.state = {
      email: '',
      loading: false,
    };
  }

  componentDidMount = () => {
    userActions.hasRights();
  };

  preCheckEmail = () => {
    const email = this.refs.email.value;
    if (!this.validateEmail(email)) return false;
    if (this.props.email === email) return false;
    this.setState({ email });
    return this.modal.showModal();
  };

  validateEmail = email => {
    const re = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;
    return re.test(String(email).toLowerCase());
  };

  updateEmailAddress = () => {
    this.setState({ loading: true });
    const body = {
      email: this.state.email,
    };

    auth.currentUser.getIdToken().then(token => {
      fetch(`${apiBaseUrl}/users/${this.props.uid}/change-email`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      })
        .then(response => {
          this.setState({ loading: false });
          const res = response.text();
          if (response.status === 200) {
            const message = `Email ${this.props.email} is changed to ${this.state.email}\n\n Please refresh page to see.`;
            alert(message);
          } else {
            alert(res);
          }
        })
        .catch(error => {
          alert(error);
        });
    });
  };

  render() {
    return (
      <span>
        <div className="updateEmail">
          <input
            type="email"
            ref="email"
            name="email"
            placeholder="Type new email"
            className="email"
            defaultValue={this.props.email}
            data-lpignore="true"
            autoComplete="off"
          />

          {this.state.loading && (
            <img className="preloader" src="https://www.klikkie.nl/assets/img/loader.gif" alt="" />
          )}
          <button type="button" className="button" onClick={this.preCheckEmail}>
            Update Email
          </button>
        </div>
        <Modal
          ref={instance => {
            this.modal = instance;
          }}
          handler={this.updateEmailAddress}
          title="Update email"
          message={`Update email from: ${this.props.email} to email:${this.state.email}`}
        />
      </span>
    );
  }
}
export default AuthEditEmailAddress;
