/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-return-assign */
import React from 'react';
import { Row } from 'react-data-grid';

class RowRenderer extends React.Component {
  getRowStyle = () => ({
    backgroundColor: this.getRowBackground(),
  });

  getRowBackground = () => {
    let color = '';

    if (this.props.row.status === 'pending') {
      color = '#8daed0b8';
    }

    if (this.props.row.status === 'rejected') {
      color = '#f3b2b2';
    }

    if (this.props.row.status === 'fulfilled') {
      color = '#c3f3e4';
    }

    return color;
  };

  render() {
    return (
      <div style={this.getRowStyle()}>
        <Row ref={node => (this.row = node)} {...this.props} />
      </div>
    );
  }
}

export default RowRenderer;
