import React from 'react';
import { GoReport } from 'react-icons/go';

class Modal extends React.Component {
  constructor() {
    super();
    this.state = {
      show: false,
    };
  }

  componentDidMount = () => {
    document.addEventListener('keydown', this.escFunction, false);

    if (this.props.showOnStart) {
      this.setState({ show: true });
    }
  };

  componentWillUnmount = () => {
    document.removeEventListener('keydown', this.escFunction, false);
    const blankState = {};
    Object.keys(this.state).forEach(stateKey => {
      blankState[stateKey] = undefined;
    });
    this.setState(blankState);
  };

  showModal = () => {
    this.setState({ show: true });
  };

  cancelModal = () => {
    if (this.props.cancelFunction) this.props.cancelFunction();
    this.setState({ show: false });
  };

  clickHandler = () => {
    this.setState({ show: false });
    this.props.handler();
  };

  escFunction = event => {
    if (event.keyCode !== 27) return false;
    return this.cancelModal();
  };

  render() {
    return (
      <div
        role="presentation"
        className={`modal-wrapper ${this.state.show}`}
        onKeyDown={this.escFunction}
        onClick={this.cancelModal}
      >
        <div className="modal-container">
          <div className="modal-header">
            <GoReport />
          </div>
          <div className="modal-content">
            <h2>{this.props.title}</h2>
            <p>{this.props.message}</p>
          </div>
          <div>
            {' '}
            <button type="button" className="cancel" onClick={this.cancelModal}>
              cancel
            </button>
            <button type="button" className="ok" onClick={this.clickHandler}>
              ok
            </button>
          </div>
        </div>
      </div>
    );
  }
}
export default Modal;
