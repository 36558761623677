import React from 'react';
import { Row } from 'react-data-grid';

class RowRendererPayments extends React.Component {
  getRowStyle = () => ({
    backgroundColor: this.getRowBackground(),
  });

  getRowBackground = () => {
    return this.props.row.success === 'true' && this.props.row.status === 'AUTHORISATION' ? '#c3f3e3' : '';
  };

  render() {
    // here we are just changing the style
    // but we could replace this with anything we liked, cards, images, etc
    // usually though it will just be a matter of wrapping a div, and then calling back through to the grid
    return (
      <div style={this.getRowStyle()}>
        <Row
          ref={node => {
            this.row = node;
          }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...this.props}
        />
      </div>
    );
  }
}

export default RowRendererPayments;
