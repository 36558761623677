import React from 'react';
import { IconContext } from 'react-icons';
import { NavLink } from 'react-router-dom';
import { userRoles } from '../../config/userRoles';

const Menu = props => {
  return (
    <ul className={props.active ? 'menu active' : 'menu inactive'}>
      {userRoles[props.role] &&
        userRoles[props.role].map(route => (
          <IconContext.Provider
            key={route.path}
            value={{ size: '1.5em', style: { marginBottom: '-4px', marginRight: '14px' } }}
          >
            {route.visible !== false && (
              <li key={route.path}>
                <NavLink to={route.to}>
                  <span className="menuIcon">
                    <route.icon />
                  </span>{' '}
                  {route.title}
                </NavLink>
              </li>
            )}
          </IconContext.Provider>
        ))}
    </ul>
  );
};

export default Menu;
