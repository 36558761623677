import React from 'react';

const ExpandableMemo = props => {
  const data = props.value;
  const preview = data.length > 70 ? `${data.substring(0, 70)}...` : data;

  return <span style={{ color: '#999', lineHeight: '48px' }}>{preview}</span>;
};

export default ExpandableMemo;
