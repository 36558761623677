import React from 'react';
import moment from 'moment';

const ConvertTimestamp2DateTime = props => {
  const date =
    props.value && new Date(props.value) instanceof Date
      ? moment(props.value).format('YYYY-MM-DD HH:mm:ss')
      : props.value;

  return <span>{date}</span>;
};

export default ConvertTimestamp2DateTime;
