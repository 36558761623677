import React from 'react';
import { countryCodeEmoji } from 'country-code-emoji';
import '../../../styles/_comment.scss';

class Flag extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value || '',
    };
  }

  componentWillUnmount = () => {
    const blankState = {};
    Object.keys(this.state).forEach(stateKey => {
      blankState[stateKey] = undefined;
    });
    this.setState(blankState);
  };

  getFlagEmoji = () => {
    try {
      return countryCodeEmoji(this.props.value);
    } catch (e) {
      return this.props.value;
    }
  };

  render() {
    return <span>{this.getFlagEmoji()}</span>;
  }
}
export default Flag;
