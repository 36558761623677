import React, { Component } from 'react';
import userActions from 'actions/userActions';
import Modal from 'components/helpers/Modal';
import subscriptionActions from 'actions/subscriptionActions';

class ChargeOrderComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  componentDidMount = () => {
    userActions
      .hasRights()
      .then(value => {
        this.setState({ show: value });
      })
      .catch();
  };

  clickHandler = async () => {
    if (Array.isArray(this.props.data)) {
      const promises = this.props.data.map(({ userId }) => {
        const pauseReq = subscriptionActions.pauseSubscription(userId);

        return pauseReq.data;
      });

      const results = await Promise.allSettled(promises);

      alert(`Status changed to paused for ${JSON.stringify(results)}`);
    }
  };

  render() {
    return (
      <span>
        <Modal
          ref={instance => {
            this.modal = instance;
          }}
          handler={this.clickHandler}
          title="Pause Subscriptions"
          message="Pause the subscriptions?"
        />
        {this.state.show && (
          <button type="submit" className="button" onClick={this.modal.showModal}>
            Pause Subscription
          </button>
        )}
      </span>
    );
  }
}

export default ChargeOrderComponent;
