import React from 'react';

class ConfirmModal extends React.Component {
  constructor() {
    super();
    this.escFunction = this.escFunction.bind(this);
    this.state = {
      show: false,
      textarea: undefined,
    };
  }

  componentDidMount = () => {
    document.addEventListener('keydown', this.escFunction, false);

    if (this.props.showOnStart) {
      this.setState({ show: true });
    }
  };

  componentWillUnmount = () => {
    document.removeEventListener('keydown', this.escFunction, false);
    const blankState = {};
    Object.keys(this.state).forEach(stateKey => {
      blankState[stateKey] = undefined;
    });
    this.setState(blankState);
  };

  showModal = () => {
    this.setState({ show: true });
  };

  cancelModal = () => {
    this.setState({ show: false });
    this.props.handler(false);
  };

  clickHandler = () => {
    this.props.handler(true, this.state.textarea);
    this.setState({ show: false });
  };

  handleChange = event => {
    const { value } = event.target;
    this.setState({ textarea: value });
  };

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps = newProps => {
    const { textarea } = newProps;
    if (!textarea) return;
    if (Object.keys(textarea).length === 0) return;
    this.setState({ textarea });
  };

  escFunction(event) {
    if (event.keyCode !== 27) return false;

    return this.cancelModal();
  }

  render() {
    return (
      <div className={`modal-wrapper ${this.state.show}`}>
        <div className="modal-container">
          <h2>{this.props.title}</h2>
          <p>{this.props.message}</p>
          {this.props.textarea !== undefined && <textarea value={this.state.textarea} onChange={this.handleChange} />}
          <div>
            {' '}
            <button type="button" className="cancel" onClick={this.cancelModal}>
              cancel
            </button>
            <button type="button" className="ok" onClick={this.clickHandler}>
              ok
            </button>
          </div>
        </div>
      </div>
    );
  }
}
export default ConfirmModal;
