import React from 'react';
import FulfillmentContentPanelAlbum from './fulfillment/FulfillmentContentPanelAlbum';

function Photobook({ toastOpen, match }) {
  const { storyId } = match.params;

  return <FulfillmentContentPanelAlbum storyId={storyId} toastOpen={toastOpen} />;
}

export default Photobook;
