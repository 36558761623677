import React, { Component } from 'react';
import DataGrid from 'components/forms/DataGrid';
import DatePicker from 'react-datepicker';
import DataUtils from 'components/helpers/utils/data';
import { MdLocationOn } from 'react-icons/md';
import {
  ConvertTimestamp2DateTime,
  Status,
  Flag,
  ProfileFormatter,
  CentimalToDecimal,
  Currency2Symbol,
  MerchantLogoFormatter,
} from 'components/helpers/formatters';
import { GenericButtonSplit, ChargeOrderButton, SubscriptionPauseButton } from 'components/buttons';
import paymentActions from 'actions/paymentActions';

const {
  Filters: {
    NumericFilter,
    // AutoCompleteFilter,
    MultiSelectFilter,
  },
} = require('react-data-grid-addons');

class Payments extends Component {
  constructor() {
    super();
    this.dataGrid = React.createRef();
    this.state = {
      rows: undefined,
      startDate: new Date(),
    };

    this.columns = [
      {
        key: 'countryCode',
        name: <MdLocationOn />,
        width: 40,
        sortable: true,
        filterable: true,
        resizable: true,
        editable: true,
        formatter: Flag,
      },
      {
        key: 'userId',
        name: 'User',
        width: 220,
        sortable: true,
        filterable: true,
        resizable: true,
        editable: true,
        filterRenderer: MultiSelectFilter,
        formatter: (
          <ProfileFormatter
            detailOpen={userId => this.props.detailOpen(userId)}
            toastOpen={(message, level) => this.props.toastOpen(message, level)}
          />
        ),
      },
      {
        key: 'orderId',
        name: 'Order',
        width: 180,
        sortable: true,
        filterable: true,
        resizable: true,
        // editable: true,
        // formatter: FulfillmentOrderIdFormatter,
        // editable: true, // don't allow admins to edit order for now
      },
      {
        key: 'createdAt',
        name: 'Date',
        width: 140,
        sortable: true,
        filterable: true,
        resizable: true,
        editable: true,
        formatter: ConvertTimestamp2DateTime,
      },
      {
        key: 'providerId',
        name: 'PSP',
        width: 60,
        sortable: true,
        filterable: true,
        resizable: true,
        editable: true,
        formatter: MerchantLogoFormatter,
      },
      {
        key: 'amount',
        name: 'amount',
        width: 70,
        sortable: true,
        filterable: true,
        resizable: true,
        editable: true,
        filterRenderer: NumericFilter,
        formatter: CentimalToDecimal,
      },
      {
        key: 'currency',
        name: 'Curr',
        width: 40,
        sortable: true,
        filterable: true,
        resizable: true,
        editable: true,
        formatter: Currency2Symbol,
      },
      {
        key: 'pspPaymentId',
        name: 'PSP ID',
        width: 160,
        sortable: true,
        filterable: true,
        resizable: true,
        editable: true,
      },
      {
        key: 'paymentMethod',
        name: 'Method',
        width: 70,
        sortable: true,
        filterable: true,
        resizable: true,
        editable: true,
        filterRenderer: MultiSelectFilter,
        formatter: MerchantLogoFormatter,
      },
      {
        key: 'status',
        name: 'Status',
        width: 60,
        sortable: true,
        filterable: true,
        resizable: true,
        editable: true,
        formatter: Status,
      },
      {
        key: 'reason',
        name: 'Reason',
        filterRenderer: MultiSelectFilter,
        width: 340,
        sortable: true,
        filterable: true,
        resizable: true,
        editable: false,
      },
    ];
  }

  componentDidMount = () => {
    return this.loadData();
  };

  loadData = async () => {
    try {
      this.setState({ rows: undefined });
      const { startDate } = this.state;
      const shortdate = new Date(startDate).toISOString().split('T')[0];

      const rows = await paymentActions.getPaymentsChargebacksByDate(shortdate, 'chargeback');

      rows.forEach((row, i) => {
        rows[i] = DataUtils.flattenObject(row);
        // eslint-disable-next-line
        rows[i].reason = row.pspMetadata && row.pspMetadata.reason ? row.pspMetadata.reason : 'no reason';
      });
      rows.sort(DataUtils.dynamicSort('-createdAt'));
      this.setState({ rows });
    } catch (error) {
      console.error(error);
      this.props.toastOpen(error, 'error');
    }
  };

  handleDateChange = date => {
    this.setState(
      {
        startDate: new Date(date),
      },
      () => this.loadData(),
    );
  };

  changeStatusOfSelection = async status => {
    try {
      return this.dataGrid.current.actionOnSelection(order => {
        return this.changeStatus(order.id, status);
      });
    } catch (error) {
      console.error(error);
      return this.props.toastOpen(error, 'error');
    }
  };

  changeStatus = async (orderId, status) => {
    try {
      // TODO: implement after dynamic API updates
      await paymentActions.updatePaymentStatus(orderId, status);

      this.props.toastOpen(`Order ${orderId} is set to ${status}`);
    } catch (error) {
      console.error(error);
      this.props.toastOpen(error, 'error');
    }
  };

  chargeOrders = async orderId => {
    try {
      // TODO: implement after dynamic API updates
      await paymentActions.chargeOrderUsingRecurringInfo(orderId);

      this.props.toastOpen(`Order ${orderId} is set charged!`);
    } catch (error) {
      console.error(error);
      this.props.toastOpen(error, 'error');
    }
  };

  rowDeleteByOrderId = orderId => {
    const { rows } = this.state;

    const updatedRows = rows.filter(item => item.orderId !== orderId);

    this.setState({
      rows: updatedRows,
    });
  };

  getCellActions = (column, row) => {
    const changeStatus = [
      {
        icon: 'icon ion-md-more',
        actions: [
          {
            text: 'Set as pending',
            callback: () => this.changeStatus(row.id, 'pending'),
          },
          {
            text: 'Set as rejected',
            callback: () => this.changeStatus(row.id, 'rejected'),
          },
          {
            text: 'Set as fulfilled',
            callback: () => this.changeStatus(row.id, 'fulfilled'),
          },
        ],
      },
    ];

    const cellActions = {
      changeStatus,
    };

    return cellActions[column.key];
  };

  // eslint-disable-next-line no-unused-vars
  onGridRowsUpdated = async ({ fromRow, toRow, updated }) => {
    try {
      const newRow = Object.assign(this.state.rows[fromRow], updated);
      const { active } = newRow;
      delete newRow.active;

      await paymentActions.updatePayment(newRow.orderId, newRow);

      const { rows } = this.state;

      delete rows[fromRow];

      this.setState({ rows }, () => {
        rows[fromRow] = Object.assign(newRow, { active });
        this.setState({ rows });
      });
    } catch (error) {
      console.error(error);
      this.props.toastOpen(error);
    }
  };

  render() {
    return (
      <div>
        <DataGrid
          ref={this.dataGrid}
          rows={this.state.rows}
          columns={this.columns}
          print={false}
          name={this.props.name}
          getSelection={selection => this.setState({ selection })}
          actions={this.getCellActions}
          onGridRowsUpdated={this.onGridRowsUpdated}
          enableCellSelect
          toastOpen={this.props.toastOpen}
          extraTools={
            <span>
              <button type="button" className="button" onClick={this.loadData}>
                Reload Data
              </button>
              <DatePicker
                utcOffset={0}
                // dateFormat="DD-MMM HH:mm"
                todayButton="Today"
                selected={this.state.startDate}
                onChange={this.handleDateChange}
                value={null}
              />
              <GenericButtonSplit />
              {/* <GenericStatusSelect changeStatus={this.changeStatusOfSelection} exclude={this.props.status} /> */}
              <ChargeOrderButton data={this.state.selection} />
              <GenericButtonSplit />
              <SubscriptionPauseButton data={this.state.selection} />
              {this.state.supplier && <span> Supplier: {this.state.supplier}</span>}
            </span>
          }
        />
      </div>
    );
  }
}

export default Payments;
