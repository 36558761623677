import DataUtils from 'components/helpers/utils/data';
import { database } from 'config/base';

export default {
  async getPackage(packageId) {
    try {
      const snapshot = await database
        .ref('productPackages_I18n')
        .child(parseInt(packageId, 10))
        .once('value');

      return snapshot.val();
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async updatePackage(packageId, path, data) {
    try {
      const updateData = Object.assign(data, {});
      Object.entries(updateData).forEach(([key, value]) => {
        if (!path.toLowerCase().includes('price') && !path.includes('shippingCost')) return;
        updateData[key] = parseInt(value, 10);
      });

      const ref = path
        ? database
            .ref('productPackages_I18n')
            .child(parseInt(packageId, 10))
            .child(path)
        : database.ref('productPackages_I18n').child(parseInt(packageId, 10));
      await ref.update(updateData);
      return true;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async getPackages() {
    try {
      const snapshot = await database.ref('productPackages_I18n').once('value');
      const data = snapshot.val();
      const rows = [];
      Object.entries(data).forEach(([key, value]) => {
        rows.push(DataUtils.flattenObject({ productPackageId: key, ...value }));
      });
      return rows;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};
