import React, { Component } from 'react';
import userActions from 'actions/userActions';
import Modal from 'components/helpers/Modal';
import paymentActions from 'actions/paymentActions';
import recoverActions from 'actions/recoverActions';

class SubscriptionRecreateButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: null,
      orderId: null,
      show: false,
      message: '',
    };
  }

  componentDidMount = () => {
    console.info(this.props);
    userActions
      .hasRights()
      .then(value => {
        this.setState({
          show: value,
          userId: this.props.uid,
          message: `First we need to find subscriptions payments for user: ${this.props.uid}...`,
        });
      })
      .catch();
  };

  getUserPayments = () => {
    const time = new Date().getTime();
    // const periodSubscriptionPayment = paymentActions
    paymentActions
      .getUserPaymentsByPeriod(this.props.uid, time)
      .then(result => result.filter(item => item.packageId < 100))
      .then(filtered => {
        this.setState(
          {
            orderId: filtered[0].orderId,
            message: `Found payment at ${new Date(filtered[0].createdAt).toISOString().split('T')[0]} for package ${
              filtered[0].packageId
            }. Recreate subscription?`,
          },
          () => {
            if (this.state.orderId) {
              this.modalCreateResult.showModal();
            } else {
              this.setState({
                message: 'This user has no subscription purchased. Cannot create a subscription before user payment.',
              });

              alert(this.state.message);
            }
          },
        );
      })
      .catch(() => {
        this.setState({
          message: 'Error creating the subscription. Could be there is no payment or subscription is already created.',
        });
        this.modalError.showModal();
      });
  };

  generateSubscription = () => {
    const { userId, orderId } = this.state;
    recoverActions
      .subscriptionCreate(userId, orderId)
      .then(result => {
        if (result) {
          this.setState({ message: 'Subscription created' });
          this.modalSubscriptionCreated.showModal();
        } else {
          this.setState({
            message: `Subscription creation error. Check with a developer user ${userId}, orderId ${orderId}`,
          });
          this.modalError.showModal();
        }
      })
      .catch(() => {
        this.setState({
          message: 'Error creating the subscription. Could be there is no payment or subscription is already created.',
        });
        this.modalError.showModal();
      });
  };

  render() {
    return (
      <div className="generateSubscriptionButton">
        <Modal
          ref={instance => {
            this.modal = instance;
          }}
          handler={this.getUserPayments}
          title="Create subscription"
          message={this.state.message}
        />
        <Modal
          ref={instance => {
            this.modalCreateResult = instance;
          }}
          handler={this.generateSubscription}
          title="Result"
          message={this.state.message}
        />
        <Modal
          ref={instance => {
            this.modalError = instance;
          }}
          handler={() => null}
          title="Error"
          message={this.state.message}
        />
        <Modal
          ref={instance => {
            this.modalSubscriptionCreated = instance;
          }}
          handler={() => null}
          title="Subscription Created"
          message={this.state.message}
        />
        {this.state.show && (
          <button type="button" className="button" onClick={this.modal.showModal}>
            (Re)create Subscription
          </button>
        )}
      </div>
    );
  }
}

export default SubscriptionRecreateButton;
