import { FulfillmentSchema } from '@klikkie/klikkie-schemas';

export default class FulfillmentOrders {
  constructor(klikkie) {
    this.klikkie = klikkie;
    this.validate = data => {
      const valid = FulfillmentSchema.validate(data);
      if (valid.error) {
        throw new Error(valid.message);
      }
    };
    const requiredParams = ['fulfillmentOrderId'];
    this.hasParams = () => {
      requiredParams.map(p => {
        if (!this.klikkie.fulfillmentOrder[p]) {
          throw new Error(`Parameter: ${p} is required`);
        }
        return true;
      });
    };
  }

  /**
   * Create a new fulfillment order
   *
   * @param {Object} data The data of the new user
   * @returns {Promise<Object>} A promise to the user object
   * @memberof FulfillmentOrders
   */
  create(data) {
    this.validate(data);
    return this.klikkie.call('PUT', `${this.klikkie.apiBaseUrl}/fulfillment/`, data);
  }

  /**
   * Get an existing fulfillmentOrder
   *
   * @returns {Promise<Object>} A promise to the fulfillmentOrder object
   * @memberof FulfillmentOrders
   */
  async get() {
    this.hasParams();
    return this.klikkie.call(
      'GET',
      `${this.klikkie.apiBaseUrl}/fulfillment/${this.klikkie.fulfillmentOrder.fulfillmentOrderId}`,
    );
  }

  /**
   * Update the properties of a fulfillmentOrder
   *
   * @param {*} data The data to change
   * @returns {Promise<Object>} A promise to the fulfillmentOrder object
   * @memberof FulfillmentOrders
   */
  update(data) {
    this.hasParams();
    this.validate(data);

    return this.klikkie.call(
      'PUT',
      `${this.klikkie.apiBaseUrl}/fulfillment/${this.klikkie.fulfillmentOrder.fulfillmentOrderId}`,
      data,
    );
  }

  /**
   * Delete the fulfillmentOrder
   *
   * @returns {Promise}
   * @memberof FulfillmentOrders
   */
  delete() {
    this.hasParams();
    return this.klikkie.call(
      'DELETE',
      `${this.klikkie.apiBaseUrl}/fulfillment/${this.klikkie.fulfillmentOrder.fulfillmentOrderId}`,
    );
  }

  /**
   * Render the fulfillmentOrder
   *
   * @returns {Promise}
   * @memberof FulfillmentOrders
   */
  render() {
    this.hasParams();
    return this.klikkie.call(
      'POST',
      `${this.klikkie.apiBaseUrl}/fulfillment/${this.klikkie.fulfillmentOrder.fulfillmentOrderId}/supplier/render`,
    );
  }
}
