/* eslint-disable max-classes-per-file */
import React, { Component } from 'react';
import { Row } from 'react-data-grid';
import DataGrid from 'components/forms/DataGrid';
import paymentActions from 'actions/paymentActions';
import DataUtils from 'components/helpers/utils/data';
import {
  ConvertTimestamp2Date,
  Flag,
  Status,
  CentimalToDecimal,
  Currency2Symbol,
  MerchantLogoFormatter,
} from 'components/helpers/formatters';
import { adyenUrl, checkoutUrl } from 'config/base';
import { ChargeOrderButton, GenericButtonSplit } from 'components/buttons';
import orderActions from 'actions/orderActions';
import CouponCodeFormatter from 'components/helpers/formatters/CouponCodeFormatter';
import FulfillmentOrderIdFormatter from 'components/helpers/formatters/FulfillmentOrderIdFormatter';

const {
  Filters: {
    NumericFilter,
    // AutoCompleteFilter
    MultiSelectFilter,
    SingleSelectFilter,
  },
} = require('react-data-grid-addons');

class RowRenderer extends React.Component {
  getRowStyle = () => ({
    backgroundColor: this.getRowBackground(),
  });

  getRowBackground = () => {
    let color = '';

    if (this.props.row.status === 'pending') {
      color = '#8daed0b8';
    }

    if (this.props.row.status === 'rejected') {
      color = '#f3b2b2';
    }

    if (this.props.row.status === 'fulfilled') {
      color = '#c3f3e4';
    }

    return color;
  };

  render() {
    return (
      <div style={this.getRowStyle()}>
        <Row
          ref={node => {
            this.row = node;
          }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...this.props}
        />
      </div>
    );
  }
}

class DetailPaymentsTab extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        key: 'id',
        name: 'id',
        width: 80,
        sortable: true,
        filterable: true,
        resizable: true,
        editable: true,
        filterRenderer: MultiSelectFilter,
      },
      {
        key: 'status',
        name: 'Status',
        width: 60,
        sortable: true,
        filterable: true,
        resizable: true,
        editable: true,
        formatter: Status,
      },
      {
        key: 'createdAt',
        name: 'date',
        width: 150,
        sortable: true,
        filterable: true,
        resizable: true,
        editable: true,
        formatter: ConvertTimestamp2Date,
      },
      {
        key: 'pspPaymentId',
        name: 'PSP ID',
        width: 160,
        sortable: true,
        filterable: true,
        resizable: true,
        editable: true,
        filterRenderer: MultiSelectFilter,
      },
      {
        key: 'orderId',
        name: 'orderId',
        width: 250,
        sortable: true,
        filterable: true,
        resizable: true,
        formatter: FulfillmentOrderIdFormatter,
      },
      {
        key: 'providerId',
        name: 'PSP',
        width: 60,
        sortable: true,
        filterable: true,
        resizable: true,
        editable: true,
        formatter: MerchantLogoFormatter,
      },
      {
        key: 'paymentMethod',
        name: 'Method',
        width: 60,
        sortable: true,
        filterable: true,
        resizable: true,
        editable: true,
        filterRenderer: SingleSelectFilter,
        formatter: MerchantLogoFormatter,
      },
      {
        key: 'countryCode',
        name: 'Country',
        width: 60,
        sortable: true,
        filterable: true,
        resizable: true,
        editable: true,
        filterRenderer: SingleSelectFilter,
        formatter: <Flag size="24px" />,
      },
      // {
      //   key: 'formattedAmount',
      //   name: 'Amount',
      //   width: 60,
      //   sortable: true,
      //   filterable: true,
      //   resizable: true,
      // },
      {
        key: 'amount',
        name: 'amount',
        width: 70,
        sortable: true,
        filterable: true,
        resizable: true,
        editable: true,
        filterRenderer: NumericFilter,
        formatter: CentimalToDecimal,
      },
      {
        key: 'currency',
        name: 'Curr',
        width: 40,
        sortable: true,
        filterable: true,
        resizable: true,
        editable: true,
        formatter: Currency2Symbol,
      },
      {
        key: 'actions',
        name: '',
        width: 50,
        sortable: false,
        filterable: false,
        resizable: false,
        editable: true,
      },
      {
        key: 'discountCode',
        name: 'Discount Code',
        width: 200,
        sortable: true,
        filterable: true,
        resizable: true,
        editable: true,
        formatter: CouponCodeFormatter,
      },
    ];
    this.state = {
      rows: undefined,
    };
  }

  async componentDidMount() {
    try {
      const payments = await paymentActions.getUserPayments(this.props.uid);
      const currencySymbols = {
        USD: '$', // US Dollar
        EUR: '€', // Euro
        CRC: '₡', // Costa Rican Colón
        GBP: '£', // British Pound Sterling
        ILS: '₪', // Israeli New Sheqel
        INR: '₹', // Indian Rupee
        JPY: '¥', // Japanese Yen
        KRW: '₩', // South Korean Won
        NGN: '₦', // Nigerian Naira
        PHP: '₱', // Philippine Peso
        PLN: 'zł', // Polish Zloty
        PYG: '₲', // Paraguayan Guarani
        THB: '฿', // Thai Baht
        UAH: '₴', // Ukrainian Hryvnia
        VND: '₫', // Vietnamese Dong
      };

      const rows = payments.map(p => {
        const providerLogo = p.providerId ? MerchantLogoFormatter(p.providerId) : null;
        const formattedAmount = `${currencySymbols[p.currency] ? currencySymbols[p.currency] : p.currency} ${p.amount /
          100}`;
        return Object.assign(p, { providerLogo, formattedAmount });
      });
      rows.sort(DataUtils.dynamicSort('createdAt', -1));

      this.setState({
        rows,
      });
    } catch (e) {
      console.error(e);
    }
  }

  getCellActions = (column, row) => {
    const pspActions = [
      {
        icon: 'icon ion-md-more',
        actions: [
          {
            text: 'Open in PSP Admin',
            callback: () => {
              window.open(
                `${adyenUrl}/ca/ca/accounts/showTx.shtml?txType=Payment&pspReference=${row.pspPaymentId}`,
                '_blank',
              );
            },
          },
          {
            text: 'Update Status',
            callback: () => {
              paymentActions
                .updateStatus(row.pspPaymentId)
                .then(() => this.props.toastOpen(`Payment ${row.pspPaymentId} status updated`))
                .catch(error => {
                  console.error(error);
                  this.props.toastOpen(`Something went wrong with update payment status: ${error.toString()}`);
                });
            },
          },
          {
            text: 'Refund payment',
            callback: () => {
              paymentActions
                .refundPayment(row.orderId)
                .then(() => this.props.toastOpen(`Order ${row.orderId} refunded`))
                .catch(error => {
                  console.error(error);
                  this.props.toastOpen(`Something went wrong with the refund: ${error.toString()}`);
                });
            },
          },
          {
            text: 'Get payment link',
            callback: async () => {
              try {
                const { orderId } = row;
                // eslint-disable-next-line
                const id = isNaN(orderId) ? (await orderActions.getOrderAPIOrder(orderId)).id : orderId;
                this.copyText(`${checkoutUrl}/?id=${id}`);
              } catch (error) {
                console.error(error);
              }
            },
          },
        ],
      },
    ];

    const cellActions = {
      actions: pspActions,
    };
    return cellActions[column.key];
  };

  copyText = text => {
    navigator.clipboard.writeText(text);
    this.props.toastOpen(`'${text}' copied to clipboard`);
  };

  // eslint-disable-next-line no-unused-vars
  onGridRowsUpdated = async ({ fromRow, toRow, updated }) => {
    try {
      const newRow = Object.assign(this.state.rows[fromRow], updated);
      const { active } = newRow;
      delete newRow.active;

      await paymentActions.updatePayment(newRow.orderId, newRow);

      const { rows } = this.state;

      delete rows[fromRow];

      this.setState({ rows }, () => {
        rows[fromRow] = Object.assign(newRow, { active });
        this.setState({ rows });
      });
    } catch (error) {
      console.error(error);
      this.props.toastOpen(error);
    }
  };

  render() {
    return (
      <span>
        <DataGrid
          columns={this.columns}
          rows={this.state.rows}
          print={false}
          actions={this.getCellActions}
          rowRenderer={RowRenderer}
          onGridRowsUpdated={this.onGridRowsUpdated}
          enableCellSelect
          getSelection={selection => this.setState({ selection })}
          extraTools={
            <span>
              <GenericButtonSplit />
              {/* <GenericStatusSelect exclude={this.props.status} changeStatus={this.changeStatusOfSelection} />
              <GenericButtonSplit /> */}
              <ChargeOrderButton data={this.state.selection} />
            </span>
          }
        />
      </span>
    );
  }
}

export default DetailPaymentsTab;
