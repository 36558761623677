import React, { Component } from 'react';
import DataGrid from 'components/forms/DataGrid';
import { MdLocationOn, MdLoop } from 'react-icons/md';
import orderActions from 'actions/orderActions';
import DataUtils from 'components/helpers/utils/data';
import CouponCodeFormatter from 'components/helpers/formatters/CouponCodeFormatter';
import {
  ConvertTimestamp2DateTime,
  Flag,
  Status,
  AddressIdSelector,
  TagFormatter,
  FulfillmentOrderIdFormatter,
} from 'components/helpers/formatters';
import {
  ProductQueuePrintCouponLabelButton,
  GenericStatusSelect,
  DeleteOrderButton,
  ChargeOrderButton,
  GenericButtonSplit,
} from 'components/buttons';
import { checkoutUrl } from 'config/base';
import Klikkie from '_klikkie';
import fulfillmentActions from 'actions/fulfillmentActions';

const klikkie = new Klikkie();

class DetailOrdersTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: undefined,
      addresses: undefined,
    };
    this.columns = [
      { key: 'id', name: 'id', width: 60, sortable: true, filterable: true, resizable: true },
      {
        key: 'createdAt',
        name: 'Created',
        width: 140,
        sortable: true,
        filterable: true,
        resizable: true,
        formatter: ConvertTimestamp2DateTime,
      },
      {
        key: 'orderId',
        name: 'orderId',
        width: 250,
        sortable: true,
        filterable: true,
        resizable: true,
        formatter: FulfillmentOrderIdFormatter,
      },
      { key: 'productSKUs', name: 'SKUs', width: 60, sortable: true, filterable: true, resizable: true },
      {
        key: 'discountCode',
        name: 'Discount Code',
        width: 180,
        sortable: true,
        filterable: true,
        resizable: true,
        formatter: CouponCodeFormatter,
      },
      {
        key: 'countryCode',
        name: <MdLocationOn />,
        width: 40,
        sortable: true,
        filterable: true,
        resizable: true,
        formatter: Flag,
      },
      {
        key: 'userAgent.source',
        name: 'Source',
        width: 100,
        sortable: true,
        filterable: true,
        resizable: true,
        formatter: TagFormatter,
      },
      { key: 'userAgent.version', name: 'Version', width: 70, sortable: true, filterable: true, resizable: true },
      {
        key: 'addressId',
        name: 'Address',
        width: 140,
        sortable: true,
        filterable: true,
        resizable: true,
        getRowMetaData: row => {
          return { addresses: this.state.addresses, changeHandler: this.changeAddress, ...row };
        },
        formatter: AddressIdSelector,
      },
      {
        key: 'status',
        name: 'Ordered',
        width: 60,
        sortable: true,
        filterable: true,
        resizable: true,
        formatter: Status,
      },
      {
        key: 'statusPayment',
        name: 'Paid',
        width: 60,
        sortable: true,
        filterable: true,
        resizable: true,
        formatter: Status,
      },
      {
        key: 'statusPictureOrder',
        name: 'Pictures',
        width: 60,
        sortable: true,
        filterable: true,
        resizable: true,
        formatter: Status,
      },
      {
        key: 'statusFulfillment',
        name: 'Fulfilled',
        width: 60,
        sortable: true,
        filterable: true,
        resizable: true,
        formatter: Status,
      },
      {
        key: 'actions',
        name: '',
        width: 90,
      },
    ];
  }

  componentDidMount = () => this.loadData();

  changeStatusOfSelection = async status => {
    try {
      return this.dataGrid.current.actionOnSelection(order => this.changeStatus(order.id, status));
    } catch (error) {
      console.error(error);
      return this.props.toastOpen(error, 'error');
    }
  };

  changeStatus = async (orderId, status) => {
    try {
      await orderActions.putOrderAPIOrderStatus(orderId, status);
      this.loadData();
      this.props.toastOpen(`Order ${orderId} is set to ${status}`);
    } catch (error) {
      console.error(error);
      this.props.toastOpen(error, 'error');
    }
  };

  addToFulfillment = async orderId => {
    try {
      await fulfillmentActions.addOrderToQueue(orderId);
      this.loadData();
      this.props.toastOpen(`Order ${orderId} is added to the fulfillment queue`);
    } catch (error) {
      console.error(error);
      this.props.toastOpen(error, 'error');
    }
  };

  changeAddress = async (orderId, addressId) => {
    try {
      const data = {
        addressId,
      };
      await orderActions.putOrderAPIAddresses(orderId, data);
      return this.props.toastOpen('Address updated');
    } catch (error) {
      console.error(error);
      return this.props.toastOpen(error, 'error');
    }
  };

  getAddresses = async () => {
    const { uid: userId } = this.props;
    const addresses = await klikkie
      .users(userId)
      .addresses()
      .get();
    this.setState({ addresses });
  };

  rowDeleteByOrderId = orderId => {
    const { rows } = this.state;

    const updatedRows = rows.filter(item => item.orderId !== orderId);

    this.setState({
      rows: updatedRows,
    });
  };

  getCellActions = (column, row) => {
    if (column.key === 'actions') {
      return [
        {
          icon: 'icon ion-md-more',
          actions: [
            {
              text: 'Set as pending',
              callback: () => this.changeStatus(row.id, 'pending'),
            },
            {
              text: 'Set as rejected',
              callback: () => this.changeStatus(row.id, 'rejected'),
            },
            {
              text: 'Set as fulfilled',
              callback: () => this.changeStatus(row.id, 'fulfilled'),
            },
            {
              text: 'Add to Fulfillment',
              callback: () => this.addToFulfillment(row.id),
            },
            {
              text: 'Copy orderId',
              callback: () => this.copyText(row.orderId || row.id),
            },
            {
              text: 'Copy userId',
              callback: () => this.copyText(row.userId),
            },
            {
              text: 'Get payment link',
              callback: () => this.copyText(`${checkoutUrl}/?id=${row.id}`),
            },
          ],
        },
      ];
    }
    return false;
  };

  copyText = text => {
    navigator.clipboard.writeText(text);
    this.props.toastOpen(`'${text}' copied to clipboard`);
  };

  loadData = async () => {
    this.setState({
      rows: undefined,
    });
    const rows = await orderActions.getUserOrdersAPIOrders(this.props.uid);
    this.getAddresses();
    this.props.count(rows.length);
    rows.forEach(async (row, i) => {
      rows[i] = DataUtils.flattenObject(row);
      rows[i].productSKUs = row.products.map(p => p.sku).join(', ');
      // get fulfillment status
      try {
        const fulfillment = await klikkie.fulfillment(rows[i].id).get();
        rows[i].statusFulfillment = fulfillment.status || undefined;
      } catch (e) {
        console.info('No order in fulfillment');
      }
      // get pictureOrder status
      if (rows[i]['products[0].type'] === 'pictures') {
        try {
          const pictures = await orderActions.getPictureOrder(rows[i].userId, rows[i].orderId);
          rows[i].statusPictureOrder = pictures[0].status || undefined;
        } catch (e) {
          console.info('No order in pictureOrder');
        }
      }
    });
    rows.sort(DataUtils.dynamicSort('-createdAt'));
    const length = rows ? rows.length : 0;
    this.props.count(length);
    this.setState({
      rows,
    });
  };

  render() {
    return (
      <DataGrid
        columns={this.columns}
        rows={this.state.rows}
        getSelection={selection => this.setState({ selection })}
        actions={this.getCellActions}
        print={false}
        extraTools={
          <span>
            <button
              type="button"
              className={this.state.rows ? 'small-btn' : 'small-btn active'}
              data-tip="Reload Data"
              onClick={this.loadData}
            >
              <div className={this.state.rows ? '' : 'spin'}>
                <MdLoop />
              </div>
            </button>
            <GenericButtonSplit />
            <ProductQueuePrintCouponLabelButton
              labelType="GIFT"
              data={this.state.selection}
              toastOpen={this.props.toastOpen}
            />
            <GenericStatusSelect changeStatus={this.changeStatusOfSelection} />
            <GenericButtonSplit />
            <ChargeOrderButton data={this.state.selection} />
            <GenericButtonSplit />
            <DeleteOrderButton data={this.state.selection} toastOpen={this.props.toastOpen} />
          </span>
        }
      />
    );
  }
}

export default DetailOrdersTab;
