import { apiBaseUrl, paymentsAPIBaseURL } from 'config/base';
import api from 'components/helpers/utils/api';

export default {
  getUserPayments(userId) {
    return api.fetchAsAdmin('GET', `${paymentsAPIBaseURL}/payments/user/${userId}`);
  },

  getPaymentByOrder(orderId) {
    return api.fetchAsAdmin('GET', `${paymentsAPIBaseURL}/payments/order/${orderId}`);
  },

  removePaymentByOrder(orderId) {
    return api.fetchAsAdmin('DELETE', `${paymentsAPIBaseURL}/payments/order/${orderId}`);
  },

  getPaymentByOrderWithNotifications(orderId) {
    return api.fetchAsAdmin('GET', `${paymentsAPIBaseURL}/payments/order/${orderId}/notifications`);
  },

  getPayment(paymentId) {
    return api.fetchAsAdmin('GET', `${paymentsAPIBaseURL}/payments/${paymentId}`);
  },

  getPaymentsByDate(date) {
    return api.fetchAsAdmin('GET', `${paymentsAPIBaseURL}/payments/date/${date}`);
  },

  getPaymentsChargebacksByDate(date, status = 'chargeback') {
    return api.fetchAsAdmin('GET', `${paymentsAPIBaseURL}/notifications/psp/${date}/${status}`);
  },

  updatePayment(orderId, payment) {
    return api.fetchAsAdmin('PUT', `${paymentsAPIBaseURL}/payments/order/${orderId}`, payment);
  },

  updateStatus(pspID) {
    return api.fetchAsAdmin('GET', `${paymentsAPIBaseURL}/payments/updateStatus/${pspID}`);
  },

  chargeOrderUsingRecurringInfo(orderId, overwriteRecurence = true) {
    return api.fetchAsAdmin('POST', `${apiBaseUrl}/checkout/charge/${orderId}`, {
      idempotencyUpdate: overwriteRecurence,
    });
  },
  getUserPaymentsByPeriod(userId, time) {
    return api.fetchAsAdmin('GET', `${apiBaseUrl}/payments/${userId}/period/${time}`);
  },

  refundPayment(orderId) {
    return api.fetchAsAdmin('POST', `${apiBaseUrl}/checkout/refund/${orderId}`, { idempotencyUpdate: true });
  },

  triggerRecurrenceCharge(userId) {
    return api.fetchAsAdmin('POST', `${apiBaseUrl}/pubsub/recover/subscription-charge`, { subscriptions: [userId] });
  },
};
