import React from 'react';

const FulfillmentOrderIdFormatter = props => {
  const orderId = props.value;
  return (
    <a href={`/fulfillment/${orderId}`} rel="noopener noreferrer" target="_blank" style={{ fontSize: '10px' }}>
      {orderId}
    </a>
  );
};

export default FulfillmentOrderIdFormatter;
