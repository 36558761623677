import {
  MdArchive,
  MdAttachMoney,
  MdBookmark,
  MdCardMembership,
  MdExitToApp,
  MdImportContacts,
  MdLocalShipping,
  MdShoppingCart,
  MdFeedback,
  MdMessage,
  MdMonetizationOn,
  MdReportProblem,
  MdVerifiedUser,
  MdTranslate,
  MdCardGiftcard,
} from 'react-icons/md';
import Admins from 'components/pages/Admins';
import Translations from 'components/pages/Translations';
import StoryPagePDF from 'components/pages/StoryPagePDF';
import Charges from 'components/pages/Charges';
import Churns from 'components/pages/Churns';
import Coupons from 'components/pages/Coupons';
import DetailOverview from 'components/detail/DetailOverview';
import Home from 'components/pages/Home';
import Logout from 'components/pages/Logout';
import FulfillmentOrder from 'components/pages/fulfillment/FulfillmentOrder';
import Orders from 'components/pages/Orders';
import OrderToPrintOldRedirect from 'components/pages/OrderToPrintOldRedirect';
import Paused from 'components/pages/Paused';
import Payments from 'components/pages/Payments';
import PaymentsChargeback from 'components/pages/PaymentsChargeback';
import Products from 'components/pages/Products';
import FulfillmentArchive from 'components/pages/fulfillment/FulfillmentArchive';
import FulfillmentRejected from 'components/pages/fulfillment/FulfillmentRejected';
import FulfillmentQueue from 'components/pages/fulfillment/FulfillmentQueue';
import Redeems from 'components/pages/Redeems';
import Reload from 'components/pages/Reload';
import Photobook from 'components/pages/Photobook';
import DailySubscriptionCycles from 'components/pages/DailySubscriptionCycles';
import ContentPanelPictureOrder from 'components/pages/fulfillment/ContentPanelPictureOrder';

const routes = {
  home: {
    to: '/',
    path: '/',
    title: 'Home',
    icon: MdBookmark,
    component: Home,
    visible: false,
  },
  admins: {
    to: '/admins',
    path: '/admins',
    title: 'Admins',
    icon: MdVerifiedUser,
    component: Admins,
  },
  charges: {
    to: '/charges',
    path: '/charges',
    title: 'Subscription Charges',
    icon: MdMonetizationOn,
    component: Charges,
  },
  chargesV2: {
    to: '/dailyCycles',
    path: '/dailyCycles',
    title: 'Daily Cycles',
    icon: MdMonetizationOn,
    component: DailySubscriptionCycles,
  },
  coupon: {
    to: '/coupon',
    path: '/coupon',
    title: 'Coupons',
    icon: MdCardMembership,
    component: Coupons,
  },
  logout: {
    to: '/logout',
    path: '/logout',
    title: 'Logout',
    icon: MdExitToApp,
    component: Logout,
  },
  orders: {
    to: '/orders',
    path: '/orders',
    title: 'Orders',
    icon: MdShoppingCart,
    component: Orders,
  },
  orderOld: {
    to: '/order',
    path: '/order/:orderId',
    title: 'Order',
    icon: MdShoppingCart,
    component: OrderToPrintOldRedirect,
    visible: false,
  },
  payments: {
    to: '/payments',
    path: '/payments',
    title: 'Payments',
    icon: MdAttachMoney,
    component: Payments,
  },
  paymentsChargeback: {
    to: '/paymentsChargeback',
    path: '/paymentsChargeback',
    title: 'Payments Chargebacks',
    icon: MdAttachMoney,
    component: PaymentsChargeback,
  },
  FulfillmentOrder: {
    to: '/fulfillment',
    path: '/fulfillment/:orderId',
    title: 'Fulfillment Order',
    icon: MdBookmark,
    component: FulfillmentOrder,
    visible: false,
  },
  PictureOrder: {
    to: '/pictureOrder',
    path: '/pictureOrder/:userId/:orderId',
    title: 'PictureOrder',
    icon: MdBookmark,
    component: ContentPanelPictureOrder,
    visible: false,
  },
  paused: {
    to: '/paused',
    path: '/paused',
    title: 'Pause Reasons',
    icon: MdMessage,
    component: Paused,
    visible: true,
  },
  products: {
    to: '/products',
    path: '/products',
    title: 'Products',
    icon: MdImportContacts,
    component: Products,
  },
  photobook: {
    to: '/photobook',
    path: '/photobook/:storyId',
    title: 'Photobook',
    icon: MdBookmark,
    component: Photobook,
    visible: false,
  },
  fulfillmentQueue: {
    to: '/fulfillmentQueue',
    path: '/fulfillmentQueue',
    title: 'Fulfillment Queue',
    icon: MdLocalShipping,
    component: FulfillmentQueue,
  },
  fulfillmentArchive: {
    to: `/fulfillmentArchive`,
    path: '/fulfillmentArchive',
    title: 'Fulfillment Archive',
    icon: MdArchive,
    component: FulfillmentArchive,
  },
  fulfillmentRejected: {
    to: '/fulfillmentRejected',
    path: '/fulfillmentRejected',
    title: 'Fulfillment Rejected',
    icon: MdReportProblem,
    component: FulfillmentRejected,
  },
  reasons: {
    to: '/reasons',
    path: '/reasons',
    title: 'Churn Reasons',
    icon: MdFeedback,
    component: Churns,
    visible: true,
  },
  redeems: {
    to: '/redeems',
    path: '/redeems/',
    title: 'Redeems & Claims',
    icon: MdCardGiftcard,
    component: Redeems,
  },
  redeemsSearch: {
    to: '/redeems',
    path: '/redeems/:discountCode',
    title: 'Redeems & Claims',
    icon: MdCardGiftcard,
    component: Redeems,
    visible: false,
  },
  reload: {
    to: '/reload',
    path: '/reload',
    title: 'Reloads by going back',
    component: Reload,
    visible: false,
  },
  storyPagePdf: {
    to: '/storyPages',
    path: '/storyPages/:storyId/:pageId',
    title: 'Story Page PDF',
    icon: MdArchive,
    component: StoryPagePDF,
    visible: false,
  },
  translations: {
    to: '/translations',
    path: '/translations',
    title: 'Translations',
    icon: MdTranslate,
    component: Translations,
  },
  user: {
    to: '/user/150',
    path: '/user/:number',
    title: 'User',
    icon: MdBookmark,
    component: DetailOverview,
    visible: false,
  },
  userTab: {
    to: '/user',
    path: '/user/:number/:tab',
    title: 'user',
    icon: MdBookmark,
    component: DetailOverview,
    visible: false,
  },
};

const permissions = {
  printer: [
    routes.reload,
    routes.fulfillmentQueue,
    routes.fulfillmentRejected,
    routes.fulfillmentArchive,
    routes.FulfillmentOrder,
    routes.PictureOrder,
    routes.orderOld,
    routes.home,
  ],
  customerService: [
    routes.user,
    routes.userTab,
    routes.products,
    routes.coupon,
    routes.payments,
    routes.paymentsChargeback,
    routes.charges,
    routes.orders,
    routes.redeems,
    routes.redeemsSearch,
    routes.translations,
  ],
  admin: [routes.reasons, routes.paused, routes.admins, routes.storyPagePdf, routes.photobook, routes.chargesV2],
};

export const userRoles = {
  printer: [...permissions.printer, routes.logout],
  customerService: [...permissions.printer, ...permissions.customerService, routes.logout],
  admin: [...permissions.printer, ...permissions.customerService, ...permissions.admin, routes.logout],
};

export const suppliers = [
  {
    name: 'Foto Westerduin',
    userIds: ['6kE8CcyZx6alk7gJs9y2Ce00Chh2', 'NBGj3Amrt9NF21idegfP7HX4LDJ2'],
    skus: [3, 103, 104, 105, 113, 114, 115, 120, 121, 122, 123, 130, 200, 201, 250, 251, 252, 301, 302, 501, 902],
  },
  {
    name: 'Klikkie BV',
    userIds: [],
    skus: [],
  },
];
