import React from 'react';
import userActions from 'actions/userActions';
import Modal from 'components/helpers/Modal';
import oa from 'actions/orderActions';

import Message from './Message';

class ResetOrder extends React.Component {
  constructor() {
    super();
    this.state = {
      loaded: false,
      message: '',
    };
  }

  componentDidMount = () => {
    userActions
      .hasRights()
      .then(value => {
        this.setState({ loaded: value });
      })
      .catch();
  };

  removeModalPre = () => {
    if (this.props.oid === undefined) {
      if (this.props.data.length === 0) {
        return alert('Nothing selected.');
      }
    }

    return this.modal.showModal();
  };

  distributeOrders = async () => {
    try {
      console.info(this.props.data);

      if (this.props.oid !== undefined) {
        await oa.resetOrder(this.props.oid, this.props.uid);
        return this.showModal(this.props.oid);
      }
      if (this.props.data.length !== 0) {
        console.info(this.props.data);
        this.props.data.map(i => oa.resetOrder(i.orderId, i.uid).then(() => this.showModal(i.orderId)));
      }
      return true;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  showModal(oid) {
    this.setState({ message: `${oid} is reset` });
    this.message.showModal();
  }

  render() {
    return (
      <span>
        {this.state.loaded && (
          <span>
            <Message
              ref={instance => {
                this.message = instance;
              }}
              text={this.state.message}
            />
            <Modal
              ref={instance => {
                this.modal = instance;
              }}
              handler={this.distributeOrders}
              title="(Re)create Order(s)? "
              message={[
                'Note: Uploaded pictures will be lost.',
                <br key="space" />,
                'Are you sure you want to reset order(s)? ',
              ]}
            />
            <button type="button" className="button" onClick={this.removeModalPre.bind(this)}>
              Re-render
            </button>
          </span>
        )}
      </span>
    );
  }
}

export default ResetOrder;
