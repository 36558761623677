const configs = {
  development: {
    apiKey: 'AIzaSyDyxbdEAFT1YyJnRpTn4OZI3mt1RCpCriI',
    authDomain: 'klikkie-dev.firebaseapp.com',
    databaseURL: 'https://klikkie-dev.firebaseio.com',
    projectId: 'klikkie-dev',
    storageBucket: 'klikkie-pictures-dev',
    messagingSenderId: '238483107990',
    appId: '1:238483107990:web:9446cf5b96b3f2bf',
    customerioSiteId: 'ddea81af695a5cd2a430',
    customerioSegment: '17',
  },
  production: {
    apiKey: 'AIzaSyATDiBMGbsuc3vnsiJg02xO0sIhTCoTzcE',
    authDomain: 'klikkie-prod.firebaseapp.com',
    databaseURL: 'https://klikkie-prod.firebaseio.com',
    projectId: 'klikkie-prod',
    storageBucket: 'klikkie-pictures-prod',
    messagingSenderId: '1093343370835',
    appId: '1:1093343370835:web:d4f2fee779a7a1ba',
    customerioSiteId: 'b73d9d608bb15dfe3ebc',
    customerioSegment: '94',
  },
};

export default configs;
