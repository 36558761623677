/* eslint-disable valid-typeof */
import React from 'react';
import { MdCheck, MdWarning } from 'react-icons/md';

class Message extends React.Component {
  constructor() {
    super();
    this.state = {
      show: false,
      message: undefined,
      level: 'info',
    };
  }

  componentDidMount() {
    this.setState({ message: this.props.text });
  }

  showModal = (msg = this.props.text, lvl = 'info') => {
    const level = typeof msg === Error ? 'error' : lvl;
    const message = (typeof msg === Error || msg !== 'object') && msg ? msg.toString().replace('Error: ', '') : msg;
    this.setState({ show: true, message, level });
    if (level === 'error') return false;
    return setTimeout(() => {
      this.setState({ message: '', level: 'hidden' });

      clearTimeout();
      return false;
    }, 3000);
  };

  render() {
    const { show, message, level } = this.state;
    return (
      <span>
        {show && (
          <div className={`message-ticket ${level}`}>
            <span className="icon">{level === 'info' ? <MdCheck /> : <MdWarning />}</span>
            <span className="value">{message}</span>
            {level === 'error' && (
              <span className="close">
                <button type="button" onClick={() => this.setState({ message: '', level: 'hidden' })}>
                  &times;
                </button>
              </span>
            )}
          </div>
        )}
      </span>
    );
  }
}
export default Message;
