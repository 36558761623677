import { apiBaseUrl } from 'config/base';
import api from 'components/helpers/utils/api';

export default {
  list(userId) {
    return api.fetchAsAdmin('GET', `${apiBaseUrl}/drafts/list/${userId}`);
  },
  import(userId) {
    return api.fetchAsAdmin('POST', `${apiBaseUrl}/drafts/import/${userId}`);
  },
};
