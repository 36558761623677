import React from 'react';

const LogMessageFormatter = props => {
  const { value } = props;
  return (
    <span
      style={{
        whiteSpace: 'pre-line',
        fontSize: '6px',
        lineHeight: '9px',
        overflowWrap: 'break-word',
        display: 'block',
      }}
    >
      {value}
    </span>
  );
};

export default LogMessageFormatter;
