import { apiBaseUrl, database } from 'config/base';
import api from 'components/helpers/utils/api';

export default {
  createCoupon(newCoupon) {
    return api.fetchAsAdmin('POST', `${apiBaseUrl}/coupons`, newCoupon);
  },

  readCoupon(couponCode) {
    return api.fetchAsAdmin('GET', `${apiBaseUrl}/coupons/${couponCode}`);
  },

  updateCoupon(couponCode, newCoupon) {
    return api.fetchAsAdmin('PUT', `${apiBaseUrl}/coupons/${couponCode}`, newCoupon);
  },

  deleteCoupon(couponCode) {
    return api.fetchAsAdmin('DELETE', `${apiBaseUrl}/coupons/${couponCode}`);
  },
  async readCouponByOrderId(orderId) {
    const snap = await database
      .ref('couponService')
      .orderByChild('orderId')
      .equalTo(orderId)
      .once('value');
    return snap.val();
  },
};
