import React from 'react';

class ProfileEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      profile: props.profile ? props.profile : {},
    };
  }

  handleFieldChange = (field, value) => {
    const { profile } = this.state;
    const { onChange } = this.props;

    profile[field] = value;

    this.setState({ profile });

    if (onChange) {
      onChange(profile);
    }
  };

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps = newProps => {
    const newProfile = newProps.profile;

    if (newProfile && Object.keys(newProfile).length > 0) {
      this.setState({ profile: newProfile });
    }
  };

  render() {
    const { profile } = this.state;
    const { name, surname, phonenumber, countryCode, languageCode } = profile;

    return (
      <div className="fieldEditor">
        <label htmlFor="limit">
          Name
          <input
            type="text"
            name="name"
            id="name"
            value={name}
            onChange={event => this.handleFieldChange('name', event.target.value)}
          />
        </label>
        <label htmlFor="limit">
          Surname
          <input
            type="text"
            name="surname"
            id="surname"
            value={surname}
            onChange={event => this.handleFieldChange('surname', event.target.value)}
          />
        </label>
        <label htmlFor="limit">
          Phone number
          <input
            type="tel"
            name="phonenumber"
            id="phonenumber"
            value={phonenumber}
            onChange={event => this.handleFieldChange('phonenumber', event.target.value)}
          />
        </label>
        <label htmlFor="limit">
          Country [NL, FR, BE, GB...]
          <input
            type="text"
            name="countryCode"
            id="countryCode"
            value={countryCode}
            onChange={event => this.handleFieldChange('countryCode', event.target.value)}
          />
        </label>
        <label htmlFor="limit">
          Language [nl, fr, en]
          <input
            type="text"
            name="languageCode"
            id="languageCode"
            value={languageCode}
            onChange={event => this.handleFieldChange('languageCode', event.target.value)}
          />
        </label>
      </div>
    );
  }
}

export default ProfileEditor;
