import React, { useState, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import { ProfileFormatter } from 'components/helpers/formatters';
import DataGrid from 'components/forms/DataGrid';
import ConfirmModal from 'components/helpers/ConfirmModal';
import 'react-datepicker/dist/react-datepicker.css';
import { GenericButtonSplit } from 'components/buttons';
import subscriptionActions from 'actions/subscriptionActions';
import { MultiSelectFilter } from 'react-data-grid-addons';
import TagFormatter from 'components/helpers/formatters/TagFormatter';

const DailySubscriptionCycles = props => {
  const [rows, setRows] = useState(undefined);
  const [func] = useState('');
  const [modalTitle] = useState('');
  const [modalMessage] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const modalRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      setRows(undefined);
      const date = startDate.toISOString().split('T')[0];
      try {
        const fetchedRows = await subscriptionActions.getSubscriptionsV2ByDay(date);
        setRows(fetchedRows?.[0]?.rows || []);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [startDate]);

  const handleDateChange = date => {
    setStartDate(date);
  };

  const copyText = text => {
    navigator.clipboard.writeText(text).then(() => {
      props.toastOpen(`'${text}' copied to clipboard`);
    });
  };

  const modalHandler = async value => {
    if (!value) return;
    try {
      const result = await func();
      props.toastOpen(result);
    } catch (error) {
      console.error(error);
      props.toastOpen(error);
    }
  };

  const getCellActions = (column, row) => {
    if (column.key === 'actions') {
      return [
        {
          icon: 'icon ion-md-more',
          actions: [
            {
              text: 'Copy orderId',
              callback: () => copyText(row.orderId),
            },
            {
              text: 'Copy userId',
              callback: () => copyText(row.plan.subscription.userId),
            },
          ],
        },
      ];
    }
    return [];
  };

  const columns = [
    {
      key: 'cycleDate',
      name: 'Charge date',
      width: 140,
      resizable: true,
    },
    {
      key: 'userId',
      name: 'User',
      width: 250,
      sortable: true,
      filterable: true,
      resizable: true,
      filterRenderer: MultiSelectFilter,
      formatter: row => (
        <ProfileFormatter
          value={row.row.plan.subscription.userId}
          detailOpen={userId => props.detailOpen(userId)}
          toastOpen={(message, level) => props.toastOpen(message, level)}
        />
      ),
    },
    {
      key: 'orderId',
      name: 'Order',
      sortable: true,
      filterable: true,
      resizable: true,
      width: 100,
    },
    {
      key: 'paymentId',
      name: 'Payment Id',
      sortable: true,
      filterable: true,
      resizable: true,
      width: 100,
    },
    {
      key: 'status',
      name: 'Status',
      sortable: true,
      filterable: true,
      resizable: true,
      width: 130,
      formatter: TagFormatter,
    },
    {
      key: 'actions',
      name: '',
      width: 90,
      getCellActions: (column, row) => getCellActions(column, row),
    },
  ];

  return (
    <span>
      <ConfirmModal ref={modalRef} handler={modalHandler} title={modalTitle} message={modalMessage} />
      <DataGrid
        name={props.name}
        columns={columns}
        rows={rows}
        actions={getCellActions}
        toastOpen={props.toastOpen}
        print={false}
        extraTools={
          <span>
            <DatePicker todayButton="Today" selected={startDate} onChange={handleDateChange} />
            <GenericButtonSplit />
          </span>
        }
      />
    </span>
  );
};

export default DailySubscriptionCycles;
