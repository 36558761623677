import React from 'react';
import { MdCheck, MdReport, MdMoreHoriz } from 'react-icons/md';

const Status = props => {
  const { value } = props;
  return (
    <div title={value} style={{ textAlign: 'center', width: '100%' }}>
      {/* <ReactTooltip place="bottom" type="dark" effect="solid" /> */}
      {value === 'pending' && <MdCheck style={{ color: '#ddd' }} />}
      {value === 'fulfilled' && <MdCheck style={{ color: '#000' }} />}
      {value === 'rejected' && <MdReport style={{ color: '#e67a72' }} />}
      {!value && <MdMoreHoriz title="Status not found" style={{ color: '#ddd' }} />}
    </div>
  );
};

export default Status;
