import React from 'react';
import { ExportToCsv } from 'export-to-csv';
import { decodeHTML } from 'entities';
import addressActions from 'actions/addressActions';

class ExportFulfillmentQueueBrievenBus extends React.Component {
  exportData = async () => {
    const { data } = this.props;
    const rows = [];
    if (data.length < 1) return alert('Please select at least one row');

    const date = new Date().toISOString().slice(0, 10);
    // eslint-disable-next-line no-restricted-syntax
    for (const row of data) {
      if (!row?.address) return null;
      const {
        address: { address, zipcode, city },
      } = row;
      const searchString = `${address} ${zipcode} ${city}`;

      // eslint-disable-next-line no-await-in-loop
      const [{ address: richAddress }] = await addressActions.getDutchAddressFromPostcodeNL(searchString).catch(() => {
        return [
          {
            address: {
              building: address,
              buildingNumber: '',
              buildingNumberAddition: '',
              country: 'The Netherlands',
              locality: city,
              postcode: zipcode,
              street: '',
            },
          },
        ];
      });
      // push a row for each unit
      for (let i = 0; i < row.units; i += 1) {
        rows.push({
          id: decodeHTML(row.id || ''),
          orderId: decodeHTML(row.orderId || ''),
          userId: decodeHTML(row.userId || ''),
          packageId: decodeHTML(row.packageId || ''),
          units: `${i + 1}/${decodeHTML(row.units || '')}`,
          months: decodeHTML(row.months || ''),
          message: decodeHTML(row.message || ''),
          couponCode: row.couponCode || '',
          status: decodeHTML(row.status || ''),
          type: decodeHTML(row.type || ''),
          fullname: decodeHTML(row.fullname || '').slice(0, 35),
          street: decodeHTML(richAddress.street),
          houseNumber: richAddress.building || '',
          houseNumberAddition: richAddress.buildingNumberAddition || '',
          zipcode: decodeHTML(richAddress.postcode || '').toUpperCase(),
          city: decodeHTML(richAddress.locality || ''),
          country: decodeHTML(row.address.country || ''),
          countryCode: decodeHTML(row.countryCode || '').toUpperCase(),
          kix: decodeHTML(row.kix || ''),
          date,
        });
      }
    }
    const options = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalseparator: '.',
      filename: `Export_${this.props.title || ''}_${date}`,
      showLabels: true,
      showTitle: false,
      useBom: true,
      headers: Object.keys(rows[0]),
    };

    const exportToCsv = new ExportToCsv(options);
    return exportToCsv.generateCsv(rows);
  };

  render() {
    return (
      <button type="button" disabled={this.props.disabled} onClick={this.exportData} className="button">
        Export Brievenbus pakjes
      </button>
    );
  }
}
export default ExportFulfillmentQueueBrievenBus;
