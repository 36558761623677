import React, { Component } from 'react';
import ReactDataGrid from 'react-data-grid';
import { MdFilterList } from 'react-icons/md';
import TableLoader from 'components/helpers/TableLoader';
import TableEmpty from 'components/helpers/TableEmpty';
import { DataGridExportCsvButton, GenericButtonSplit } from 'components/buttons';

const {
  Toolbar,
  Data: { Selectors },
} = require('react-data-grid-addons');

class DataGrid extends Component {
  constructor() {
    super();

    this.columns = [];
    this.state = {
      rows: null,
      temp: [],
      selectedIndexes: [],
      selectedOrders: [],
      groupBy: [],
      filters: {},
      filterActive: false,
      printQueue: [],
      total: '',
      archiveButton: true,
      columnSize: 0,
      print: true,
      csvRows: {},
    };
  }

  componentDidMount = () => {
    if (this.props.print !== undefined) this.setState({ print: this.props.print });
    if (this.props.groupBy !== undefined) this.setState({ groupBy: this.props.groupBy });
    this.columns = this.props.columns;
    const calc = Math.round(window.innerWidth / (this.columns.length + 1));

    this.setState({
      rows: this.props.rows,
      csvRows: this.props.rows,
      originalRows: this.props.rows,
      columnSize: calc,
    });
  };

  componentDidUpdate = (_prevProps, prevState) => {
    if (prevState.selectedOrders === this.state.selectedOrders) return;
    if (!this.props.getSelection) return;
    this.props.getSelection(this.state.selectedOrders);
  };

  componentWillUnmount = () => {
    const blankState = {};
    Object.keys(this.state).forEach(stateKey => {
      blankState[stateKey] = undefined;
    });
    this.setState(blankState);
  };

  static getDerivedStateFromProps(props, state) {
    if (JSON.stringify(props.rows) === JSON.stringify(state.rows)) return null;

    return { rows: props.rows };
  }

  onRowsSelected = rows => {
    const { selectedIndexes, selectedOrders } = this.state;
    this.setState({
      selectedIndexes: selectedIndexes.concat(rows.map(r => r.rowIdx)),
      selectedOrders: selectedOrders.concat(rows.map(r => r.row)),
    });
  };

  onRowsDeselected = rows => {
    const { selectedIndexes, selectedOrders } = this.state;
    const rowIndexes = rows.map(r => r.rowIdx);
    const orderIndexes = rows.map(r => r.row.orderId);
    this.setState({
      selectedIndexes: selectedIndexes.filter(i => !rowIndexes.includes(i)),
      selectedOrders: selectedOrders.filter(i => !orderIndexes.includes(i.orderId)),
    });
  };

  actionOnSelection = async action => {
    try {
      if (this.state.selectedOrders.length === 0) throw new Error('No rows selected');
      const promises = this.state.selectedOrders.map(order => action(order));
      await Promise.all(promises);
      return this.setState({ selectedIndexes: [], selectedOrders: [] });
    } catch (error) {
      console.error(error);
      return this.props.toastOpen(error, 'error');
    }
  };

  getValidFilterValues = columnId => {
    this.setState({ selectedIndexes: [], selectedOrders: [] });
    if (!this.state.rows) return false;
    const values = this.state.rows.map(r => {
      return r[columnId] ? r[columnId] : '';
    });
    return values.filter((item, i, a) => i === a.indexOf(item));
  };

  onClearFilters = () => {
    this.setState({
      filters: {},
      selectedIndexes: [],
      selectedOrders: [],
      csvRows: this.props.rows,
    });
  };

  // Changed rowGetter to split by dot to also get nested properties
  rowGetter = rowIdx => {
    const rows = Selectors.getRows(this.state);
    const project = { ...rows[rowIdx] };

    project.get = key => {
      const splitedkey = key.split('.');

      if (key.includes('.')) {
        return project[splitedkey[0]] ? project[splitedkey[0]][splitedkey[1]] : '';
      }
      return project[key];
    };

    return project;
  };

  rowsCount = () => Selectors.getRows(this.state).length;

  // filter
  handleFilterChange = filter => {
    const { filters } = this.state;
    this.setState({ filters: {}, selectedIndexes: [], selectedOrders: [] });
    const newFilters = { ...filters };

    if (filter.filterTerm) {
      newFilters[filter.column.key] = filter;
      this.setState({ archiveButton: false });
    } else {
      this.setState({ archiveButton: true });
      delete newFilters[filter.column.key];
    }
    this.setState({ filters: newFilters });
  };

  handleGridSort = (sortColumn, sortDirection) => {
    const { originalRows, rows } = this.state;
    const comparer = (a, b) => {
      if (sortDirection === 'ASC') {
        return a[sortColumn] > b[sortColumn] ? 1 : -1;
      }
      if (sortDirection === 'DESC') {
        return a[sortColumn] < b[sortColumn] ? 1 : -1;
      }
      return false;
    };
    const newRows = sortDirection === 'NONE' ? originalRows.slice(0) : rows.sort(comparer);
    this.setState({ rows: newRows });
  };

  EmptyRowsView = () => {
    return (
      <span>
        {!this.props.rows && <TableLoader />}
        {this.props.rows && <TableEmpty />}
      </span>
    );
  };

  toggleFilter = () => {
    const { filterActive } = this.state;
    this.setState({ filterActive: !filterActive });
    this.instance.onToggleFilter();
  };

  render() {
    return (
      <span>
        {this.state.columnSize > 0 && (
          <span>
            <ReactDataGrid
              ref={datagrid => {
                this.instance = datagrid;
              }}
              minHeight={window.innerHeight - 225}
              totalWidth={window.innerWidth}
              minColumnWidth={this.state.columnSize}
              rowHeight={this.props.rowHeight ? this.props.rowHeight : 50}
              headerRowHeight={30}
              columns={this.columns}
              rowGetter={this.rowGetter}
              rowsCount={this.rowsCount()}
              onGridSort={this.handleGridSort}
              toolbar={
                <Toolbar enableFilter={false} numberOfRows={false}>
                  {this.props.name && (
                    <span className="pageTitle">
                      {this.props.name}
                      {this.state.selectedOrders.length > 0 && (
                        <span className="count selected">{this.state.selectedOrders.length}</span>
                      )}
                      <span className="count">
                        {Selectors.getRows(this.state).length
                          ? Selectors.getRows(this.state).length.toLocaleString()
                          : '◌'}
                      </span>
                    </span>
                  )}
                  <button
                    type="button"
                    className={this.state.filterActive ? 'small-btn active' : 'small-btn'}
                    disabled={!this.state.rows}
                    onClick={this.toggleFilter}
                  >
                    <MdFilterList />
                  </button>
                  {!this.props.hideCsvButton && (
                    <DataGridExportCsvButton data={this.state.selectedOrders} title={this.props.name} />
                  )}
                  <GenericButtonSplit />
                  {this.props.extraTools}
                </Toolbar>
              }
              onAddFilter={this.handleFilterChange}
              getValidFilterValues={this.getValidFilterValues}
              onClearFilters={this.onClearFilters}
              onGridRowsUpdated={e => {
                this.props.onGridRowsUpdated(e);
              }}
              enableCellSelect={this.props.enableCellSelect}
              getSubRowDetails={this.getSubRowDetails}
              getCellActions={this.props.actions}
              rowRenderer={this.props.rowRenderer}
              enableRowSelect={null} // fix for warning message can be removed when upgrading to react-data-grid 7.0
              emptyRowsView={this.EmptyRowsView}
              rowSelection={{
                showCheckbox: this.props.showCheckbox === undefined ? true : this.props.showCheckbox,
                enableShiftSelect: true,
                onRowsSelected: this.onRowsSelected,
                onRowsDeselected: this.onRowsDeselected,
                selectBy: {
                  indexes: this.state.selectedIndexes,
                },
              }}
            />
          </span>
        )}
      </span>
    );
  }
}
export default DataGrid;
