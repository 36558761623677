import React from 'react';
import ProfileEditor from 'components/helpers/ProfileEditor';
import ModalContainer from 'components/helpers/ModalContainer';
import userActions from 'actions/userActions';

/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable jsx-a11y/aria-role */

class ProfileEditButton extends React.Component {
  constructor() {
    super();

    this.state = {
      profile: {},
    };

    this.profile = {};
  }

  loadUser = async () => {
    try {
      const { userId } = this.props;

      const { profile } = await userActions.getUser(userId);

      this.setState({ profile });
      this.profile = profile;
    } catch (error) {
      console.error(error);
    }
  };

  showEditProfileModal = async () => {
    this.loadUser();
    this.modal.showModal();
  };

  editProfileHandler = async () => {
    const { userId, onSave } = this.props;

    await userActions.updateProfile(userId, this.profile);

    if (onSave) {
      onSave(this.profile);
    }
  };

  render() {
    const { profile } = this.state;

    return (
      <span className="fieldEditorContainer">
        <button type="button" className="button" onClick={this.showEditProfileModal}>
          Edit User Profile
        </button>
        <ModalContainer
          ref={instance => {
            this.modal = instance;
          }}
          title="Edit User Profile"
          handler={this.editProfileHandler}
        >
          <ProfileEditor
            profile={profile}
            onChange={p => {
              this.profile = p;
            }}
          />
        </ModalContainer>
      </span>
    );
  }
}

export default ProfileEditButton;
