import React from 'react';
import { MdCompareArrows } from 'react-icons/md';
import Message from 'components/helpers/Message';
import userActions from 'actions/userActions';
import { pictureOrderChangeStatus } from 'actions/orderOperations';
// eslint-disable-next-line import/no-cycle
import { GenericButtonSplit } from '.';

class PictureOrderChangeStatusButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
      status: 'pending',
    };
  }

  componentDidMount = () => {
    userActions.hasRights().then(data => this.setState({ show: !!data }));
  };

  changeStatus = async (userId, orderId, status) => {
    await pictureOrderChangeStatus(userId, orderId, status);
    return this.showModal(`Status of order ${orderId} updated to '${status}'`);
  };

  distributeOrders = async () => {
    try {
      const { status } = this.state;
      const { data: orders } = this.props;
      if (!orders || orders.length === 0) return this.showModal('No order selected');

      await Promise.all(orders.map(({ userId, orderId }) => this.changeStatus(userId, orderId, status)));

      return this.props.updateHandler();
    } catch (error) {
      console.error(error);
      return this.showModal(error);
    }
  };

  showModal = oid => {
    this.setState({ text: `Order ${oid} is moved.` });

    this.messageModal.showModal();
  };

  changeHandler = event => {
    console.info(event.target.value);

    this.setState({ status: event.target.value });
  };

  render() {
    return (
      <div className="moveOrderButton">
        <Message
          ref={instance => {
            this.messageModal = instance;
          }}
          text={this.state.text}
        />
        {this.state.show && (
          <span>
            <label htmlFor="select">
              Order status
              <select id="select" onChange={this.changeHandler} value={this.state.status}>
                <option value="pending">Open for member</option>
                <option value="fulfilled">Submitted by member</option>
                <option value="rejected">Rejected (No payment)</option>
              </select>
            </label>
            <button type="submit" className="small-btn" onClick={this.distributeOrders.bind(this)}>
              <span role="img" aria-label="emoji">
                {this.state.loading && <img src="https://www.klikkie.nl/assets/img/loader.gif" alt="" />}
              </span>
              <MdCompareArrows />
            </button>
            <GenericButtonSplit />
          </span>
        )}
      </div>
    );
  }
}
export default PictureOrderChangeStatusButton;
