import React from 'react';
import { database } from 'config/base';
import { GenericCopyButton, AuthEditPasswordButton, ProfileEditButton, AuthEditEmailAddress } from 'components/buttons';
import orderActions from 'actions/orderActions';
import DeleteUser from 'components/helpers/DeleteUser';
import MakeAdmin from 'components/helpers/MakeAdmin';
import userActions from 'actions/userActions';
import { Flag } from 'components/helpers/formatters';

class DetailUserTab extends React.Component {
  dbUserRef = database.ref('user');

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      email: 'no-data',
      mgm: 'no-data',
      countryCode: 'no-data',
      languageCode: 'no-data',
      userToken: 'no-data',
      recordUser: false,
      name: 'no-data',
      surname: 'no-data',
      phonenumber: 'no-data',
      startDate: 'Unknown',
    };
  }

  componentDidMount() {
    this.initData();
  }

  initData = async () => {
    const { uid } = this.props;
    await this.getUser(uid);
    await this.getMGM(uid);

    const userToken = await orderActions.getToken();
    this.setState({ userToken });
  };

  getUser = async uid => {
    try {
      const user = await userActions.getUser(uid);

      if (!user) throw new Error("User doesn't exist");

      console.info(user);
      const profile = user.profile || {};
      this.updateStateFromProfile(profile);

      this.setState(prevState => ({
        startDate: user.optin?.optinTermsDate?.split('T')[0] || prevState.startDate,
        show: true,
        recordUser: user.recordUser || false,
      }));

      this.props.count('✓');
    } catch (error) {
      console.error(error);
    }
  };

  updateStateFromProfile = profile => {
    const { name, surname, phonenumber, email, countryCode, languageCode } = profile;

    this.setState(state => ({
      name: name || state.name,
      surname: surname || state.surname,
      phonenumber: phonenumber || state.phonenumber,
      email: email || state.email,
      countryCode: countryCode || state.countryCode,
      languageCode: languageCode || state.languageCode,
    }));
  };

  handleProfileUpdate = profile => {
    this.updateStateFromProfile(profile);
    this.setState({ show: true });
  };

  toggleRecordUser = async () => {
    try {
      await userActions.toggleRecordUser(this.props.uid, !this.state.recordUser);
      this.setState(prevState => ({ recordUser: !prevState.recordUser }));
    } catch (error) {
      console.error(error);
    }
  };

  getMGM = async userId => {
    try {
      const snapshot = await database
        .ref('mgm')
        .child(userId)
        .child('code')
        .once('value');
      this.setState(prevState => ({ mgm: snapshot.val() || prevState.mgm }));
    } catch (e) {
      console.error(e);
    }
  };

  getFullName = () => {
    const { name, surname } = this.state;
    return `${name} ${surname}`;
  };

  render() {
    const {
      show,
      userToken,
      name,
      surname,
      startDate,
      phonenumber,
      mgm,
      email,
      countryCode,
      languageCode,
      recordUser,
    } = this.state;

    return (
      <div>
        {show && (
          <div>
            <ul className="controls">
              <li>
                <ProfileEditButton userId={this.props.uid} onSave={profile => this.handleProfileUpdate(profile)} />
              </li>
              <li>
                <DeleteUser name={`${name} ${surname}`} uid={this.props.uid} />
              </li>
              <li>
                <MakeAdmin uid={this.props.uid} />
              </li>
              {this.props.uid === this.props.user.uid && (
                <li>
                  <GenericCopyButton className="button" text="Get Token" value={userToken} />
                </li>
              )}
            </ul>
            <span className="subHeader">MEMBER</span>
            <ul className="subSection">
              <li style={{ textTransform: 'capitalize' }}>
                <span className="label">Name</span>
                {this.getFullName()}
                <GenericCopyButton title="Copy Name" text="" value={this.getFullName()} />
              </li>
              <li>
                <span className="label">Signed up</span>
                {startDate}
              </li>
              <li>
                <span className="label">Phone</span>
                {phonenumber}
              </li>
              <li>
                <span className="label">MGM</span>
                {mgm}
                <GenericCopyButton title="Copy Code" text="" value={mgm} />
              </li>
            </ul>
            <span className="subHeader">ACCOUNT</span>
            <ul className="subSection">
              <li>
                <span className="label">User ID</span> {this.props.uid}
                <GenericCopyButton title="Copy User Id" text="" value={this.props.uid} />
              </li>
              <li>
                <span className="label">Email</span>
                <AuthEditEmailAddress uid={this.props.uid} email={email} />
                <GenericCopyButton title="Copy email" text="" value={email} />
              </li>
              <li>
                <span className="label">Password</span>
                <AuthEditPasswordButton uid={this.props.uid} toastOpen={this.props.toastOpen} />
              </li>
            </ul>
            <span className="subHeader">LOCALIZATION</span>
            <ul className="subSection">
              <li>
                <span className="label">Country </span>
                {countryCode}
                <Flag value={countryCode} />
              </li>
              <li>
                <span className="label">Language </span>
                {languageCode}
              </li>
            </ul>
            <ul className="subSection">
              <li>
                <span className="label">Record with UX cam </span>
                <input type="checkbox" checked={recordUser} onChange={this.toggleRecordUser} />
                {recordUser}
              </li>
            </ul>
            <div />
          </div>
        )}
      </div>
    );
  }
}

export default DetailUserTab;
