import React, { Component } from 'react';
import DataGrid from 'components/forms/DataGrid';
import DataUtils from 'components/helpers/utils/data';
import { MdLocationOn } from 'react-icons/md';
import {
  FulfillmentOrderIdFormatter,
  ConvertTimestamp2DateTime,
  Status,
  Flag,
  TagFormatter,
  ProfileFormatter,
} from 'components/helpers/formatters';
import orderActions from 'actions/orderActions';
import fulfillmentActions from 'actions/fulfillmentActions';
import GenericButtonSplit from 'components/buttons/GenericButtonSplit';
import CouponCodeFormatter from 'components/helpers/formatters/CouponCodeFormatter';
import { withRouter } from '../../../node_modules/react-router-dom/cjs/react-router-dom.min';

const {
  Filters: {
    NumericFilter,
    // AutoCompleteFilter,
    MultiSelectFilter,
    // SingleSelectFilter
  },
} = require('react-data-grid-addons');

class Redeems extends Component {
  constructor() {
    super();
    this.dataGrid = React.createRef();
    this.state = {
      rows: undefined,
      discountCode: null,
    };

    this.columns = [
      { key: 'id', name: 'id', width: 60, sortable: true, filterable: true, resizable: true },
      {
        key: 'createdAt',
        name: 'Created',
        width: 140,
        sortable: true,
        filterable: true,
        resizable: true,
        formatter: ConvertTimestamp2DateTime,
      },
      {
        key: 'orderId',
        name: 'orderId',
        width: 180,
        sortable: true,
        filterable: true,
        resizable: true,
        formatter: FulfillmentOrderIdFormatter,
      },
      {
        key: 'userId',
        name: 'userId',
        width: 250,
        sortable: true,
        filterable: true,
        resizable: true,
        filterRenderer: MultiSelectFilter,
        formatter: (
          <ProfileFormatter
            detailOpen={userId => this.props.detailOpen(userId)}
            toastOpen={(message, level) => this.props.toastOpen(message, level)}
          />
        ),
      },
      {
        key: 'status',
        name: 'Status',
        width: 100,
        sortable: true,
        filterable: true,
        filterRenderer: MultiSelectFilter,
        formatter: TagFormatter,
      },
      {
        key: 'productSKUs',
        name: 'SKUs',
        width: 60,
        sortable: true,
        filterable: true,
        resizable: true,
        filterRenderer: NumericFilter,
      },
      {
        key: 'discountCode',
        name: 'Discount Code',
        width: 140,
        sortable: true,
        filterable: true,
        resizable: true,
        formatter: CouponCodeFormatter,
      },
      {
        key: 'countryCode',
        name: <MdLocationOn />,
        width: 40,
        sortable: true,
        filterable: true,
        resizable: true,
        formatter: Flag,
      },
      {
        key: 'totalToCharge',
        name: 'totalToCharge',
        sortable: true,
        filterable: true,
        resizable: true,
        filterRenderer: NumericFilter,
      },
      {
        key: 'userAgent.source',
        name: 'Source',
        width: 100,
        sortable: true,
        filterable: true,
        resizable: true,
        filterRenderer: MultiSelectFilter,
        formatter: TagFormatter,
      },
      { key: 'userAgent.version', name: 'Version', sortable: true, filterable: true, resizable: true },
      { key: 'userAgent.utm.utm_medium', name: 'Medium', sortable: true, filterable: true, resizable: true },
      { key: 'userAgent.utm.utm_source', name: 'Source', sortable: true, filterable: true, resizable: true },
      { key: 'userAgent.utm.utm_content', name: 'Content', sortable: true, filterable: true, resizable: true },
      { key: 'userAgent.utm.utm_campaign', name: 'Campaign', sortable: true, filterable: true, resizable: true },
      {
        key: 'addressId',
        name: 'Address',
        width: 140,
        sortable: true,
        filterable: true,
        resizable: true,
      },
      {
        key: 'status',
        name: 'Ordered',
        width: 60,
        sortable: true,
        filterable: true,
        resizable: true,
        formatter: Status,
      },
      {
        key: 'metadata.sender',
        name: 'Sender',
        width: 140,
      },
      {
        key: 'statusPayment',
        name: 'Paid',
        width: 60,
        sortable: true,
        filterable: true,
        resizable: true,
        formatter: Status,
      },
      {
        key: 'changeStatus',
        width: 60,
      },
    ];
  }

  componentDidMount = () => {
    return this.loadData();
  };

  loadData = async () => {
    try {
      this.setState({ rows: undefined });
      const { discountCode } = this.state;
      if (!discountCode && this.props.match.params.discountCode)
        this.setState({ discountCode: this.props.match.params.discountCode });
      const rows = await orderActions.getOrdersAPIOrdersByDiscountCode(
        discountCode || this.props.match.params.discountCode,
      );

      rows.forEach((row, i) => {
        rows[i] = DataUtils.flattenObject(row);
        rows[i].productSKUs = row.products.map(p => p.sku).join(', ');
      });
      rows.sort(DataUtils.dynamicSort('-createdAt'));
      this.setState({ rows });
    } catch (error) {
      console.error(error);
      this.setState({ rows: [] });
      this.props.toastOpen(error, 'error');
    }
  };

  handleDiscountCodeInputChange = event => {
    this.setState({ discountCode: event.target.value });
  };

  handleSubmit = event => {
    event.preventDefault(); // Prevent form from actually submitting
    this.loadData();
  };

  changeStatusOfSelection = async status => {
    try {
      return this.dataGrid.current.actionOnSelection(order => this.changeStatus(order.id, status));
    } catch (error) {
      console.error(error);
      return this.props.toastOpen(error, 'error');
    }
  };

  changeStatus = async (orderId, status) => {
    try {
      await orderActions.putOrderAPIOrderStatus(orderId, status);

      this.props.toastOpen(`Order ${orderId} is set to ${status}`);
    } catch (error) {
      console.error(error);
      this.props.toastOpen(error, 'error');
    }
  };

  addToFulfillment = async orderId => {
    try {
      await fulfillmentActions.addOrderToQueue(orderId);
      this.loadData();
      this.props.toastOpen(`Order ${orderId} is added to the fulfillment queue`);
    } catch (error) {
      console.error(error);
      this.props.toastOpen(error, 'error');
    }
  };

  rowDeleteByOrderId = orderId => {
    const { rows } = this.state;

    const updatedRows = rows.filter(item => item.orderId !== orderId);

    this.setState({
      rows: updatedRows,
    });
  };

  getCellActions = (column, row) => {
    const changeStatus = [
      {
        icon: 'icon ion-md-more',
        actions: [
          {
            text: 'Set as pending',
            callback: () => this.changeStatus(row.id, 'pending'),
          },
          {
            text: 'Set as rejected',
            callback: () => this.changeStatus(row.id, 'rejected'),
          },
          {
            text: 'Set as fulfilled',
            callback: () => this.changeStatus(row.id, 'fulfilled'),
          },
          {
            text: 'Add to Fulfillment',
            callback: () => this.addToFulfillment(row.id),
          },
        ],
      },
    ];

    const cellActions = {
      changeStatus,
    };

    return cellActions[column.key];
  };

  render() {
    return (
      <div>
        <DataGrid
          ref={this.dataGrid}
          rows={this.state.rows}
          columns={this.columns}
          print={false}
          name={this.props.name}
          actions={this.getCellActions}
          toastOpen={this.props.toastOpen}
          extraTools={
            <span>
              <button type="button" className="button" onClick={this.loadData}>
                Reload Data
              </button>
              <GenericButtonSplit />
              <form onSubmit={this.handleSubmit} style={{ display: 'contents' }}>
                <input
                  value={this.state.discountCode || ''}
                  onChange={this.handleDiscountCodeInputChange}
                  placeholder="Search discount code..."
                />
                <button type="submit" className="button">
                  Search code
                </button>
              </form>
            </span>
          }
        />
      </div>
    );
  }
}

export default withRouter(Redeems);
