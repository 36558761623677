/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { MdArchive, MdReport, MdTimelapse } from 'react-icons/md';
import countries from 'i18n-iso-countries';
import NamedId from 'components/helpers/formatters/NamedId';
import dymoActions from 'actions/dymoActions';
import fulfillmentActions from 'actions/fulfillmentActions';
import {
  AddressIdSelector,
  ProfileFormatter,
  ProductPackage,
  ConvertTimestamp2DateTime,
  Flag,
  GenericComment,
} from 'components/helpers/formatters';
import { ProductQueuePrintCouponLabelButton } from 'components/buttons';
import FulfillmentContentPanelProduct from 'components/pages/fulfillment/FulfillmentContentPanelProduct';
import FulfillmentContentPanelPictureOrder from 'components/pages/fulfillment/FulfillmentContentPanelPictureOrder';
import FulfillmentContentPanelCoupon from 'components/pages/fulfillment/FulfillmentContentPanelCoupon';
import FulfillmentContentPanelAlbum from 'components/pages/fulfillment/FulfillmentContentPanelAlbum';
import DetailSubscriptionTab from 'components/detail/DetailSubscriptionTab';
import TagFormatter from 'components/helpers/formatters/TagFormatter';
import Klikkie from '_klikkie';

import './_FulfillmentOrder.scss';
import productPackageActions from 'actions/productPackageActions';
import FulfillmentContentPanelWall from './FulfillmentContentPanelWall';
import FulfillmentContentPanelCard from './FulfillmentContentPanelCard';

const klikkie = new Klikkie();
class FulfillmentOrder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      addresses: [],
      productionStatus: {
        externalId: null,
        externalStatus: null,
        trackingUrl: null,
        trackingCode: null,
      },
      supplier: null,
    };
  }

  componentDidMount() {
    this.getData();
    document.addEventListener('keydown', this.handleDoneKey, false);
  }

  componentWillUnmount = () => {
    document.removeEventListener('keydown', this.handleDoneKey, false);
  };

  componentDidUpdate = nextProps => {
    if (nextProps.location.pathname === this.props.location.pathname) return;
    this.getData();
  };

  getProductionStatus = async () => {
    try {
      const { orderId } = this.props.match.params;
      const status = await fulfillmentActions.getStatusFromSupplier(orderId);

      const productionStatus = status[0].orderInformation;

      this.setState({ productionStatus });
    } catch (error) {
      console.error(error);
    }
  };

  getData = async () => {
    try {
      const { orderId } = this.props.match.params;
      const queueOrder = await klikkie.fulfillment(orderId).get();
      // hack to get the 501 on qr scan
      if (!queueOrder && orderId.includes('501')) return this.changeOrder(`${orderId}-501`);
      const addresses = await klikkie
        .users(queueOrder.userId)
        .addresses()
        .get();
      const { supplier } = await productPackageActions.getPackage(queueOrder.packageId);
      await this.getProductionStatus();
      return this.setState({
        addresses,
        queueOrder,
        supplier,
      });
    } catch (error) {
      return console.error(error);
      // this.props.toastOpen(error, 'error');
    }
  };

  doneButtonHandler = async () => {
    try {
      const { queueOrder } = this.state;
      const update = { status: 'fulfilled' };
      await fulfillmentActions.updateQueue(queueOrder.id, update);
      try {
        this.printLabel(queueOrder);
      } catch (error) {
        console.error(error);
        this.props.toastOpen(error);
      }

      this.setState({ queueOrder: Object.assign(queueOrder, update) });
    } catch (error) {
      console.error(error);
      this.props.toastOpen(error);
    }
  };

  reopenButtonHandler = async () => {
    try {
      const { queueOrder } = this.state;
      const update = { status: 'pending', runSideEffects: false };
      await fulfillmentActions.updateQueue(queueOrder.id, update);
      this.setState({ queueOrder: Object.assign(queueOrder, update) });
      this.props.toastOpen('Order Reopened');
    } catch (error) {
      console.error(error);
      this.props.toastOpen(error);
    }
  };

  rejectButtonHandler = async () => {
    try {
      const { queueOrder } = this.state;
      const update = { status: 'rejected' };
      await fulfillmentActions.updateQueue(queueOrder.id, update);

      this.setState({ queueOrder: Object.assign(queueOrder, update) });
      this.props.toastOpen('Order Rejected');
    } catch (error) {
      console.error(error);
      this.props.toastOpen(error);
    }
  };

  changeOrder = newOrderId => {
    const { history, location } = this.props;
    let { pathname } = location;
    pathname = pathname.split('/').filter(value => value);
    const orderPage = pathname[0];

    console.info(`${orderPage}/${newOrderId}`);

    history.push(`/${orderPage}/${newOrderId}/`);
  };

  updateAddress = async (id, aId) => {
    try {
      const { addresses, queueOrder } = this.state;
      const address = Object.values(addresses).filter(({ addressId }) => addressId === aId)[0];

      await fulfillmentActions.updateQueue(id, { address });
      const orderWithNewAddress = { ...queueOrder };
      orderWithNewAddress.address = address;
      this.setState({ queueOrder: orderWithNewAddress });
      return this.props.toastOpen(`Address changed to: \n${address.address} - ${address.city}`);
    } catch (error) {
      console.error(error);
      return this.props.toastOpen(error);
    }
  };

  renderOrder = async () => {
    const { orderId } = this.props.match.params;
    try {
      const { supplier } = this.state;
      if (!supplier) return this.props.toastOpen("Supplier unknown, can't render photo book", 'error');
      this.props.toastOpen('Started rendering ...');
      await klikkie.fulfillment(orderId).render();
      this.props.toastOpen('Rendering finished.');
      return window.location.reload(false);
    } catch (error) {
      console.error(error);
      return this.props.toastOpen(error.message || 'Failed to render', 'error');
    }
  };

  handleDoneKey = ({ key }) => {
    const { status } = this.state.queueOrder;

    if (key !== ']' || status !== 'pending') return;

    this.doneButtonHandler();
  };

  generateLabel = row => {
    const address = row.address.address ? row.address : row;
    const country = countries.getName(address.countryCode, 'en').toUpperCase() || '';
    const label = {
      sku: row.packageId ? row.packageId : '',
      oid: row.orderId ? row.orderId : '',
      uid: row.userId ? row.userId : '',
      street: address.address2 && address.address2 !== '' ? `${address.address}-${address.address2}` : address.address,
      city: address.city.toUpperCase() || address.municipality || address.municipalityName || '',
      zipcode: address.countryCode === 'NL' ? this.formatNLZipcode(address.zipcode) : address.zipcode || '',
      fullname: address.fullname || '',
      country: address.countryCode === 'NL' ? '' : country,
      kix: row.kix,
      id: row.id || '',
    };

    return label;
  };

  formatNLZipcode = zipcode =>
    zipcode
      ? `${zipcode.substring(0, 4)} ${zipcode
          .replace(' ', '')
          .substring(4, 6)
          .toUpperCase()}`
      : '';

  printLabel = async data => {
    try {
      const { orderId } = this.props.match.params;
      if (!data.address && !data.street) throw new Error(`No address found for order ${orderId}`);
      const row = data.address.address ? data.address : data;
      row.packageId = data.packageId;
      row.kix = data.metadata?.kix;
      const label = this.generateLabel(row);
      label.couponCode = `https://admin.klikkie.nl/order/${data.userId}/${orderId}`;
      label.ids = orderId;
      dymoActions.printCodeLabel(window, label, 'TNT');
      return true;
    } catch (error) {
      console.error(error);
      this.props.toastOpen(error, 'error');
      return false;
    }
  };

  sendToSupplier = async (render = true, idempotent = false) => {
    try {
      const { orderId } = this.props.match.params;
      this.props.toastOpen('Resending to Supplier.');

      await fulfillmentActions.sendOrderToSupplier(orderId, render, idempotent);

      return this.props.toastOpen('Resent to Supplier');
    } catch (error) {
      return this.props.toastOpen(error, 'error');
    }
  };

  recreatePhotobook = async () => {
    try {
      const { orderId } = this.props.match.params;
      this.props.toastOpen('Resending to Supplier.');

      await fulfillmentActions.recreatePhotobook(orderId);

      return this.props.toastOpen('Resent to Supplier');
    } catch (error) {
      return this.props.toastOpen(error, 'error');
    }
  };

  render = () => {
    const { queueOrder, addresses, productionStatus, supplier } = this.state;
    const images = require.context('../../../img/suppliers', true);

    return (
      <span className="order-container">
        <div className="side-panel">
          {queueOrder && (
            <span>
              <div className="order-status">
                <div className={queueOrder.status === 'pending' ? 'active' : ''}>
                  <a href="#" onClick={this.reopenButtonHandler}>
                    <MdTimelapse />
                    Open
                  </a>
                </div>
                <div className={queueOrder.status === 'rejected' ? 'active' : ''}>
                  <a href="#" onClick={this.rejectButtonHandler}>
                    <MdReport />
                    Reject
                  </a>
                </div>
                <div className={queueOrder.status === 'fulfilled' ? 'active' : ''}>
                  <a href="#" onClick={this.doneButtonHandler}>
                    <MdArchive />
                    Done
                  </a>
                </div>
              </div>
              {supplier && (
                <div>
                  <span style={{ height: '50px', display: 'flex', alignItems: 'center' }}>
                    <img
                      style={{ width: '80px', paddingRight: '10px' }}
                      src={images(`./${supplier}.png`).default}
                      alt={supplier}
                    />{' '}
                    {productionStatus.externalId && (
                      <p
                        style={{
                          fontSize: '11px',
                          fontWeight: 'bold',
                          paddingRight: '10px',
                          maxWidth: '150px',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {productionStatus.externalId}{' '}
                      </p>
                    )}
                    <p style={{ fontSize: '11px' }}>
                      {productionStatus.trackingUrl && (
                        <a href={productionStatus.trackingUrl}>{productionStatus.trackingCode || 'Track'}</a>
                      )}
                    </p>
                    <TagFormatter color="#E2A0FF" value={productionStatus.externalStatus} />
                  </span>
                  <div>
                    <button type="button" onClick={() => this.sendToSupplier(true, false)}>
                      Produce
                    </button>
                    <button type="button" onClick={() => this.sendToSupplier(false, false)}>
                      Produce (Skip render)
                    </button>
                    <button type="button" onClick={() => this.renderOrder()}>
                      Render
                    </button>
                    {supplier === 'Peecho' && 'link' in queueOrder && queueOrder.link && (
                      <button type="button" onClick={() => this.recreatePhotobook()}>
                        Re-create order
                      </button>
                    )}
                  </div>

                  <hr />
                </div>
              )}

              <div>
                <h2>ORDER</h2>
                <NamedId id={queueOrder.id || queueOrder.orderId} size="large" toastOpen={this.props.toastOpen} />
                <ConvertTimestamp2DateTime value={queueOrder.createdAt} />
                <h2>
                  {queueOrder.units}✕{'  '}
                  <ProductPackage value={queueOrder.packageId} /> <TagFormatter value={queueOrder.type} />
                </h2>
              </div>
              {queueOrder.address && (
                <>
                  <hr />
                  <h2>ADDRESS</h2>
                  <AddressIdSelector
                    style={{ width: '200px' }}
                    value={queueOrder.address.addressId}
                    dependentValues={{
                      addresses,
                      id: queueOrder.id,
                      orderId: queueOrder.orderId,
                      changeHandler: this.updateAddress,
                    }}
                  />
                  <p>{queueOrder.address.address}</p>
                  <p>{queueOrder.address.address2}</p>
                  <p>
                    {queueOrder.address.zipcode} {queueOrder.address.city}
                  </p>
                  <p>{queueOrder.address.country}</p>
                  <p>
                    <Flag value={queueOrder.address.countryCode} />
                  </p>
                  <ProductQueuePrintCouponLabelButton
                    labelType={queueOrder.type === 'pictures' || queueOrder.type === 'photo-set' ? 'TNT' : 'GIFT'}
                    data={[queueOrder]}
                    toastOpen={this.props.toastOpen}
                  />
                </>
              )}
              <hr />
              <h2>ORDERED BY</h2>
              <p>{queueOrder.userId}</p>
              <h3>
                <ProfileFormatter value={queueOrder.userId} detailOpen={this.props.detailOpen} />
              </h3>
              <hr />
              <h2>COMMENT</h2>
              <div
                style={{
                  background: 'yellow',
                  height: '100px',
                  padding: '8px',
                  display: 'flex',
                }}
              >
                <GenericComment
                  value={queueOrder.comment}
                  changeHandler={(id: queueOrder.id, comment) => fulfillmentActions.updateQueue(id, { comment })}
                  toastOpen={this.props.toastOpen}
                  dependentValues={queueOrder}
                  idField="id"
                />
              </div>
              {queueOrder.status === 'pending' && (
                <button type="button" className="button primary" onClick={this.doneButtonHandler}>
                  DONE ]
                </button>
              )}
              {queueOrder.status !== 'pending' && (
                <button type="button" className="button primary" onClick={this.reopenButtonHandler}>
                  Reopen
                </button>
              )}
            </span>
          )}
        </div>

        {queueOrder && !queueOrder.products && (
          <div className="content-panel">
            {queueOrder.type !== 'album' && queueOrder.status !== 'pending' && (
              <div className="done-overlay">
                <p className={queueOrder.status}>ORDER {queueOrder.status}</p>
              </div>
            )}
            {queueOrder && queueOrder.type === 'product' && (
              <FulfillmentContentPanelProduct
                packageId={queueOrder.packageId}
                units={queueOrder.units}
                toastOpen={this.props.toastOpen}
              />
            )}
            {(queueOrder.type.startsWith('picture') || queueOrder.type === 'photo-print') && (
              <FulfillmentContentPanelPictureOrder
                fulfillmentId={queueOrder.id}
                packageId={queueOrder.packageId}
                userId={queueOrder.userId}
                orderId={queueOrder.orderId}
                history={this.props.history}
                toastOpen={this.props.toastOpen}
              />
            )}
            {queueOrder.type === 'coupon' && (
              <FulfillmentContentPanelCoupon
                couponCode={queueOrder.couponCode}
                id={queueOrder.id}
                link={queueOrder.link}
                toastOpen={this.props.toastOpen}
              />
            )}
            {queueOrder.type === 'album' && (
              <FulfillmentContentPanelAlbum
                userId={queueOrder.userId}
                id={this.props.match.params.orderId}
                supplier={supplier}
                storyId={queueOrder?.metadata?.story?.id}
                orderId={queueOrder.orderId}
                orderApiId={queueOrder.metadata.orderApiId}
                album={queueOrder.link}
                toastOpen={this.props.toastOpen}
              />
            )}

            {queueOrder.type === 'card' && (
              <FulfillmentContentPanelCard
                userId={queueOrder.userId}
                id={this.props.match.params.orderId}
                supplier={supplier}
                orderId={queueOrder.orderId}
                printId={queueOrder?.metadata?.printId}
                orderApiId={queueOrder.metadata.orderApiId}
                link={queueOrder.link}
                toastOpen={this.props.toastOpen}
              />
            )}

            {queueOrder.type === 'wall' && (
              <FulfillmentContentPanelWall
                userId={queueOrder.userId}
                id={this.props.match.params.orderId}
                supplier={supplier}
                link={queueOrder?.link}
                printId={queueOrder?.metadata?.printId}
                orderId={queueOrder.orderId}
                orderApiId={queueOrder.metadata.orderApiId}
                toastOpen={this.props.toastOpen}
              />
            )}
            {queueOrder.type === 'subscription' && (
              <DetailSubscriptionTab
                uid={queueOrder.userId}
                orderId={queueOrder.orderId}
                toastOpen={this.props.toastOpen}
                count={() => true}
              />
            )}
          </div>
        )}
      </span>
    );
  };
}
export default FulfillmentOrder;
