import { database } from 'config/base';

export default {
  getfirebaseActions: async child => {
    try {
      const ss = await database.ref(child).once('value');
      if (!ss.exists()) return 'no data present';
      return ss.val();
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  updateNode: async (ref, data) => {
    return database.ref(ref).update(data);
  },
  // checks if node exists and requests value of child node
  exists(node, childNode) {
    return node === undefined ? '-' : node[childNode] || '-';
  },
  capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
};
