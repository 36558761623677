import React from 'react';
import userActions from 'actions/userActions';
import Klikkie from '_klikkie';
import Message from './Message';
import { userRoles } from '../../config/userRoles';

const klikkie = new Klikkie();

class MakeAdmin extends React.Component {
  constructor() {
    super();
    this.state = {
      token: undefined,
      loading: false,
      message: 'Make Admin',
      adminRole: 'no',
      isAdmin: false,
    };
    this.dbRef = {};
  }

  componentDidMount = async () => {
    try {
      const isAdmin = await userActions.hasRights();
      const { uid: userId } = this.props;
      if (!isAdmin) throw new Error('no admin');
      const adminRole = await klikkie.users(userId).isAdmin();

      return this.setState({ adminRole, isAdmin });
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  componentWillUnmount = () => {
    const blankState = {};
    Object.keys(this.state).forEach(stateKey => {
      blankState[stateKey] = undefined;
    });
    this.setState(blankState);
  };

  setAdmin = () => {
    this.setState({ loading: true });
    let data = {};
    if (this.state.adminRole === 'no') {
      data = {
        isAdmin: false,
        adminRole: '',
      };
      userActions.updateUserNode(this.props.uid, data);
    } else {
      data = {
        isAdmin: true,
        adminRole: this.state.adminRole,
      };
      userActions.updateUserNode(this.props.uid, data);
    }
    this.setState({ loading: false, message: `User set to ${data.adminRole}` });
    this.MakeAdmin.showModal();
  };

  selectChoice = event => {
    this.setState({ adminRole: event.target.value });
  };

  render() {
    return (
      <span>
        <Message
          ref={instance => {
            this.MakeAdmin = instance;
          }}
          text={this.state.message}
        />
        {this.state.isAdmin && (
          <span className="make-admin">
            <select onChange={this.selectChoice} value={this.state.adminRole}>
              <option value="no">No admin </option>
              {Object.keys(userRoles).map(role => (
                <option key={role} value={role}>
                  {role}{' '}
                </option>
              ))}
            </select>
            <button type="button" className="button" onClick={this.setAdmin.bind(this)}>
              Update user role
              {this.state.loading && <img src="https://www.klikkie.nl/assets/img/loader.gif" alt="" />}
            </button>
          </span>
        )}
      </span>
    );
  }
}

export default MakeAdmin;
