import React, { Component } from 'react';
import userActions from 'actions/userActions';
import Modal from 'components/helpers/Modal';
import paymentActions from 'actions/paymentActions';

class ChargeOrderComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  componentDidMount = () => {
    userActions
      .hasRights()
      .then(value => {
        this.setState({ show: value });
      })
      .catch();
  };

  clickHandler = async () => {
    if (Array.isArray(this.props.data)) {
      if (Array.isArray(this.props.data)) {
        const promises = this.props.data.map(async ({ userId, orderId, id }) => {
          try {
            await paymentActions.chargeOrderUsingRecurringInfo(orderId || id);

            return `User:  ${userId} Order: ${orderId} Status: recharge succeeded`;
          } catch (error) {
            return `User:  ${userId} Order: ${orderId} Status: recharge rejected. Error: ${JSON.stringify(error)}`;
          }
        });

        const results = await Promise.allSettled(promises);

        alert(`Status changed to paused for ${JSON.stringify(results)}`);
      }
    }
  };

  render() {
    return (
      <span>
        <Modal
          ref={instance => {
            this.modal = instance;
          }}
          handler={this.clickHandler}
          title="Charge"
          message="Charge orders?"
        />
        {this.state.show && (
          <button type="submit" className="button" onClick={this.modal.showModal}>
            Charge With Contract
          </button>
        )}
      </span>
    );
  }
}

export default ChargeOrderComponent;
