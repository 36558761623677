import React, { Component } from 'react';
import Fulfillment from 'components/pages/fulfillment/Fulfillment';

class ProductQueue extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Fulfillment
        status="pending"
        name="Fulfillment Queue"
        toastOpen={this.props.toastOpen}
        detailOpen={this.props.detailOpen}
        role={this.props.role}
        user={this.props.user}
      />
    );
  }
}

export default ProductQueue;
