import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { MdContentCopy } from 'react-icons/md';
import Message from 'components/helpers/Message';

class GenericCopyButton extends React.Component {
  notify = () => {
    console.info(`\n\n${this.props.value}`);
    this.ticket.showModal();
  };

  render() {
    return (
      <span>
        <Message
          ref={instance => {
            this.ticket = instance;
          }}
          text={`Clipboard: ${this.props.value}`}
        />
        <CopyToClipboard text={this.props.value}>
          <button
            type="button"
            className={this.props.text ? `button ${this.props.className}` : `small-btn ${this.props.className}`}
            onClick={this.notify}
          >
            <MdContentCopy />
            <span role="img" aria-label="copy">
              {' '}
              {this.props.text && this.props.text}{' '}
            </span>
          </button>
        </CopyToClipboard>
      </span>
    );
  }
}
export default GenericCopyButton;
