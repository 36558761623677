import React, { Component } from 'react';
import userActions from 'actions/userActions';
import Modal from 'components/helpers/Modal';
import orderActions from 'actions/orderActions';

class DeleteOrderButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  componentDidMount = () => {
    userActions
      .hasRights()
      .then(value => {
        this.setState({ show: value });
      })
      .catch();
  };

  clickHandler = async () => {
    if (Array.isArray(this.props.data)) {
      const promises = this.props.data.map(async ({ userId, orderId, id }) => {
        return orderActions
          .removeOrderAPIOrder(orderId || id)
          .then(() => this.props.toastOpen(`Order remove from user ${userId} order ${orderId || id} succeeded!`))
          .catch(error => {
            console.error(error);
            this.props.toastOpen(`Order remove from user ${userId} order ${orderId || id} error!`);
          });
      });

      return Promise.allSettled(promises);
    }

    return null;
  };

  render() {
    return (
      <span>
        <Modal
          ref={instance => {
            this.modal = instance;
          }}
          handler={this.clickHandler}
          title="Delete"
          message="Delete orders?"
        />
        {this.state.show && (
          <button type="submit" className="button" onClick={this.modal.showModal}>
            Delete Orders
          </button>
        )}
      </span>
    );
  }
}

export default DeleteOrderButton;
