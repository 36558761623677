import { database, apiBaseUrl, auth } from 'config/base';
import api from 'components/helpers/utils/api';

export default {
  checkIfAdmin: async uid => {
    try {
      const snapshot = await database.ref(`user/${uid}`).once('value');
      const userRole = snapshot.val();
      console.info('checkIfAdmin');
      if (!userRole.isAdmin) return false;

      return userRole.adminRole;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  getUser(userId) {
    return api.fetchAsAdmin('GET', `${apiBaseUrl}/users/${userId}`).then(u => u[0]);
  },

  getUserActiveAddress(userId) {
    return this.getUser(userId)
      .then(user => {
        const userActiveAddress =
          user.metadata && user.metadata.activeAddress ? user.metadata.activeAddress : 'subscriptionV0';

        return userActiveAddress;
      })
      .catch(console.error);
  },

  updateUserNode(uid, data) {
    return database
      .ref(`/user/${uid}`)
      .update(data)
      .catch(console.info);
  },
  async updateProfile(userId, newProfile) {
    try {
      const profileRef = database
        .ref('user')
        .child(userId)
        .child('profile');

      await profileRef.update(newProfile);
    } catch (error) {
      console.error(error);
    }
  },
  toggleRecordUser(userId, recordUser) {
    return api.fetchAsAdmin('PUT', `${apiBaseUrl}/users/${userId}/recordUser`, { recordUser });
  },
  async getRole() {
    try {
      const roleSnap = await database
        .ref(`user/${auth.currentUser.uid}`)
        .child('adminRole')
        .once('value');
      if (!roleSnap.exists()) throw new Error('User Role not found');

      return roleSnap.val();
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  hasRights(admin = false) {
    return this.checkIfAdmin(auth.currentUser.uid)
      .then(data => {
        let val = false;

        if (admin) {
          if (data.adminRole === 'admin') {
            return true;
          }
        } else if (data.adminRole !== 'printer' && data.adminRole !== '' && data.adminRole !== 'no') {
          val = true;
        }

        return val;
      })

      .catch();
  },
};
