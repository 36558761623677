import React, { Component } from 'react';
import fulfillmentActions from 'actions/fulfillmentActions';
import { storagePictures } from 'config/base';

class FulfillmentContentPanelWall extends Component {
  constructor(props) {
    super(props);
    this.state = {
      downloadLink: null,
      siteId: null,
      prodigiDashboard: null,
    };
  }

  componentDidMount = async () => {
    try {
      const { printId, link } = this.props;
      const siteId = printId ? printId.split('_')[1] : null;
      const { REACT_APP_SECRET_CODE } = process.env;
      const isProd = REACT_APP_SECRET_CODE === 'production';
      const prodigiDashboard = isProd
        ? 'https://dashboard.prodigi.com/dashboard'
        : 'https://sandbox-beta-dashboard.pwinty.com';
      const downloadLink = await storagePictures.ref(link).getDownloadURL();
      this.setState({ downloadLink, siteId, prodigiDashboard });
    } catch (error) {
      console.error(error);
    }
  };

  sendToSupplier = async (render, idempotent) => {
    try {
      const { id } = this.props;
      this.props.toastOpen('Resending album to Supplier.');

      await fulfillmentActions.sendOrderToSupplier(id, render, idempotent);

      return this.props.toastOpen('Album sent to Supplier');
    } catch (error) {
      return this.props.toastOpen(error, 'error');
    }
  };

  resubmitOrder = async () => {
    const { orderApiId } = this.props;
    try {
      this.props.toastOpen('Resubmitting order...');
      await fulfillmentActions.addOrderToQueue(orderApiId);
      window.location.reload();
    } catch (error) {
      this.props.toastOpen(error, 'error');
    }
  };

  render() {
    const { downloadLink, prodigiDashboard, siteId } = this.state;

    return downloadLink ? (
      <div className="FulfillmentContentPanelWall">
        <div className="taskbar">
          {siteId && (
            <a
              className="button"
              href={`${prodigiDashboard}/orders/${siteId}/detail`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Dashboard
            </a>
          )}
        </div>
        {downloadLink && (
          <div className="preview">
            <img alt="preview" src={downloadLink} style={{ margin: '50px', height: '70vh' }} />
          </div>
        )}
      </div>
    ) : (
      <div>
        <h1>Wall deco not added to fulfillment order</h1>
        <button type="button" onClick={this.resubmitOrder}>
          Resubmit order
        </button>
      </div>
    );
  }
}

export default FulfillmentContentPanelWall;
