/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { MdVerifiedUser } from 'react-icons/md';
import { getNamedId } from 'named-ids';

const NamedId = props => {
  const { id, size } = props;
  const namedId = getNamedId(id).toUpperCase();
  const copyText = () => {
    navigator.clipboard.writeText(id);
    props.toastOpen(`'${id}' copied to clipboard`);
  };
  return (
    <span>
      <span onClick={copyText} className={`named-id ${size}`}>
        <span className="icon">
          <span>
            <MdVerifiedUser />
          </span>
        </span>
        {namedId}
        <div className="id">ID: {id}</div>
      </span>
    </span>
  );
};

export default NamedId;
