import { SchemaAddresses } from '@klikkie/klikkie-schemas';

export default class Addresses {
  constructor(settings) {
    this.klikkie = settings.klikkie;
    this.users = settings.users;
    this.addresses = settings.addresses;
    const requiredParams = ['addressId'];

    this.hasParams = () => {
      requiredParams.map(p => {
        if (!this.addresses[p]) {
          throw new Error(`Parameter: ${p} is required`);
        }
        return true;
      });
    };
    this.validate = data => {
      const notValidObject = SchemaAddresses.validate(data).error;
      if (notValidObject) throw new Error(notValidObject);

      return true;
    };
  }

  create(address) {
    this.validate(address);

    return this.klikkie.call('POST', `${this.klikkie.apiBaseUrl}/addresses/${this.klikkie.users.userId}/`, address);
  }

  get() {
    const uri = this.addresses.addressId
      ? `${this.klikkie.apiBaseUrl}/addresses/${this.klikkie.users.userId}/${this.addresses.addressId}`
      : `${this.klikkie.apiBaseUrl}/addresses/${this.klikkie.users.userId}`;

    return this.klikkie.call('GET', uri);
  }

  update(address) {
    this.hasParams();
    this.validate(address);

    return this.klikkie.call(
      'PUT',
      `${this.klikkie.apiBaseUrl}/addresses/${this.klikkie.users.userId}/${this.addresses.addressId}`,
      address,
    );
  }

  delete() {
    this.hasParams();

    return this.klikkie.call(
      'DELETE',
      `${this.klikkie.apiBaseUrl}/addresses/${this.klikkie.users.userId}/${this.addresses.addressId}`,
    );
  }

  setPrimary() {
    this.hasParams();
    const data = {
      activeAddress: this.addresses.addressId,
    };

    return this.klikkie.call('PUT', `${this.klikkie.apiBaseUrl}/users/${this.klikkie.users.userId}/metadata`, data);
  }

  /**
   * Get the primary address of the user
   *
   * @returns {Promise<String>} addressId of active address
   * @memberof Users
   */
  async getPrimary() {
    const user = await this.klikkie.call('GET', `${this.klikkie.apiBaseUrl}/users/${this.klikkie.users.userId}`);
    const userActiveAddress =
      user.metadata && user.metadata.activeAddress ? user.metadata.activeAddress : 'subscriptionV0';

    return userActiveAddress;
  }
}
