// import * as firebase from "firebase";
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/storage';
import configs from './config';

if (!Object.keys(configs).includes(process.env.REACT_APP_SECRET_CODE))
  throw new Error(`Config for ${process.env.REACT_APP_SECRET_CODE} not found.`);
const config = configs[process.env.REACT_APP_SECRET_CODE];
const shortEnv = config.projectId === 'klikkie-dev' ? 'dev' : 'prod';
const devEnv = config.projectId === 'klikkie-dev' ? 'dev.' : '';
const app = firebase.initializeApp(config);
const database = app.database();
const firestore = app.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const storagePictures = firebase.app().storage();
const apiFunctionsUrl = `https://europe-west1-${config.projectId}.cloudfunctions.net`;
const apiBaseUrl = `https://${devEnv}api.klikkie.com`;
const checkoutUrl = `https://${devEnv}checkout.klikkie.com`;
const apiFBBaseUrl = `https://${config.projectId}.firebaseio.com`;
const ordersAPIBaseURL = `https://${devEnv}orders.klikkie.com`;
const paymentsAPIBaseURL = `https://${devEnv}payments.klikkie.com`;
const adyenUrl = `https://ca-${config.projectId === 'klikkie-dev' ? 'test' : 'live'}.adyen.com`;
const consoleUrl = `https://console.firebase.google.com/u/0/project/${config.projectId}/`;
const locizeEnv = config.projectId === 'klikkie-dev' ? 'development' : 'latest';
const storyShareUrl = `https://${devEnv}share.klikkie.com/story`;
const renderAPIBaseURL = `https://${devEnv}render.klikkie.com`;
const cloudStoragePicturesURL = `https://console.cloud.google.com/storage/browser/klikkie-pictures-${shortEnv}`;
const cloudStoragePicturesDetailsURL = `https://console.cloud.google.com/storage/browser/_details/klikkie-pictures-${shortEnv}`;

const printApiOrderUrl = `https://portal.printapi.nl/${
  config.projectId === 'klikkie-dev' ? 'test' : 'live'
}/manage/order`;

export {
  app,
  auth,
  cloudStoragePicturesURL,
  cloudStoragePicturesDetailsURL,
  database,
  storage,
  storagePictures,
  config,
  apiFunctionsUrl, // default functions REST endpoint
  apiBaseUrl,
  checkoutUrl,
  apiFBBaseUrl,
  locizeEnv,
  ordersAPIBaseURL,
  paymentsAPIBaseURL,
  firestore,
  firebase,
  adyenUrl,
  consoleUrl,
  renderAPIBaseURL,
  storyShareUrl,
  printApiOrderUrl,
};
