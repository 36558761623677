import React from 'react';

const TagFormatter = props => {
  const { value, color } = props;
  let background = value === 'true' ? '#90e693' : 'rgb(202, 234, 239)';
  if (color) {
    background = color;
  }
  if (!value) {
    background = '#f5cf5d';
  }
  return (
    <div
      style={{
        background,
        borderRadius: '19px',
        fontSize: '8px',
        textAlign: 'center',
        width: 'fit-content',
        padding: '0px 8px',
        color: '#03545f',
        height: '19px',
        lineHeight: '19px',
        textTransform: 'uppercase',
        fontHeight: '600',
        display: 'inline-block',
      }}
    >
      {value || 'Unknown'}
    </div>
  );
};

export default TagFormatter;
