import React from 'react';
import translationsActions from 'actions/translationsActions';
import { cloudStoragePicturesURL } from 'config/base';

const Translations = ({ toastOpen }) => {
  const update = async () => {
    try {
      toastOpen('Started updating the translations (takes approximately 4 minutes)...');
      await translationsActions.updateTranslations();
    } catch (error) {
      toastOpen(error);
      console.error(error);
    }
  };

  return (
    <div style={{ padding: '40px', width: '450px' }}>
      <h2>Update translations</h2>
      <p>
        You can update the translations by going to locize editing the translations and then hitting the button here to
        download them to our servers. Development happens automatically btw.
      </p>
      <p>
        You can find the translations of locize{' '}
        <a href="https://www.locize.app/p/jxia8cu8/v/latest" target="_blank" rel="noreferrer">
          here
        </a>
        .
      </p>
      <p>
        You can find the translations on our server{' '}
        <a
          href={`${cloudStoragePicturesURL}/assets/translations/26d3b460-0f85-4fce-a984-cb9ab5745a8c`}
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>
        .
      </p>
      <button type="button" onClick={update}>
        Update Translations
      </button>
    </div>
  );
};
export default Translations;
