import React from 'react';
import userActions from 'actions/userActions';
import Klikkie from '_klikkie';
import Modal from './Modal';

const klikkie = new Klikkie();
class DeleteUser extends React.Component {
  constructor() {
    super();
    this.state = {
      hasRights: false,
    };
  }

  componentDidMount = async () => {
    try {
      const hasRights = await userActions.hasRights();

      this.setState({ hasRights });
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  render() {
    const { uid: userId } = this.props;
    return (
      <span>
        {this.state.hasRights && (
          <span>
            <Modal
              ref={instance => {
                this.modal = instance;
              }}
              handler={() => klikkie.users(userId).delete()}
              title={`Remove User: ${this.props.name}`}
              message={`Are you sure you want to delete user: ${this.props.name}, with UID: ${this.props.uid}`}
            />

            <button type="button" className="button" onClick={() => this.modal.showModal()}>
              Remove User
            </button>
          </span>
        )}
      </span>
    );
  }
}

export default DeleteUser;
