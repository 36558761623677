import React, { Component } from 'react';
import productPackageActions from 'actions/productPackageActions';

class FulfillmentContentPanelProduct extends Component {
  constructor(props) {
    super(props);

    this.state = {
      packageId: '',
      product: {},
      units: 0,
    };
  }

  async componentDidMount() {
    try {
      const { packageId, units } = this.props;
      const product = await productPackageActions.getPackage(packageId);
      if (!product) throw new Error(`No product package found with #SKU ${packageId}`);

      this.setState({ packageId, product, units });
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    const { packageId, product, units } = this.state;
    return (
      <span>
        {product && (
          <span>
            <h1>
              {units}X{packageId}:{product.type}/{product.variant}
            </h1>
            <img className="product-image" src={product.thumbSrc} alt={product.variant} />
          </span>
        )}
        <h2>Groovy Magnet Links</h2>
        <ul>
          <li>
            <a
              href="https://groovymagnets.com/en/shop/magnetic-stickers/uni/magnetic-sticker-copper-dia-60cm-198/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Groovy Magnets Round Copper
            </a>
          </li>
          <li>
            <a
              href="https://groovymagnets.com/en/shop/magnetic-stickers/writable-sticker/black-magnetic-sticker-rectangle-for-chalk-markers-611/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Groovy Magnets Rectangle Chalk
            </a>
          </li>
          <li>
            <a
              href="https://groovymagnets.com/en/shop/magnetic-poster/animal-poster/magnetic-poster-rabbit-62x95cm-310/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Groovy Magnets Rabbit
            </a>
          </li>
          <li>
            <a
              href="https://groovymagnets.com/nl/shop/magnetische-poster/dieren-poster/magneetposter-parkiet-62x95cm-308/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Groovy Magnets Parakeet
            </a>
          </li>
          <li>
            <a
              href="https://groovymagnets.com/nl/shop/magnetische-poster/dieren-poster/magneetposter-poes-62x95cm-306/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Groovy Magnets Cat
            </a>
          </li>
          <li>
            <a
              href="https://groovymagnets.com/nl/shop/magnetische-poster/dieren-poster/magneetposter-giraf-62x95cm-309/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Groovy Magnets Giraffe
            </a>
          </li>
        </ul>
      </span>
    );
  }
}

export default FulfillmentContentPanelProduct;
