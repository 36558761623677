import React from 'react';

class AddressIdSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addresses: [],
      value: this.props.value,
    };
  }

  componentDidMount = () => this.loadData();

  loadData = async () => {
    try {
      const { value } = this.props;
      const { addresses } = this.props.dependentValues;

      this.setState({ addresses, value });
    } catch (error) {
      console.error(error);
    }
  };

  componentDidUpdate = prevProps => {
    if (prevProps.dependentValues === this.props.dependentValues && prevProps.value === this.props.value) return;
    this.loadData();
  };

  changeHandler = async event => {
    try {
      const { id, orderId, changeHandler } = this.props.dependentValues;
      const ID = id || orderId;
      const { value } = event.target;
      await changeHandler(ID, value);
      return this.setState({ value });
    } catch (error) {
      console.error(error);
      return this.setState({ value: this.props.value });
    }
  };

  render() {
    const { addresses, value } = this.state;
    return (
      <span>
        {addresses && (
          <select id="select" onChange={this.changeHandler} value={value || ''}>
            <option key="" value="">
              None
            </option>
            {addresses.map(address => {
              return (
                <option key={address.addressId} value={address.addressId}>
                  {address.fullname} - {address.address} {address.zipcode} {address.city} {address.countryCode}
                </option>
              );
            })}
          </select>
        )}
      </span>
    );
  }
}

export default AddressIdSelector;
