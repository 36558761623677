import React from 'react';
import CustomQueueForm from 'components/helpers/CustomQueueForm';
import ModalContainer from 'components/helpers/ModalContainer';
import oa from 'actions/orderActions';
import Message from 'components/helpers/Message';

/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable jsx-a11y/aria-role */

class PrintQueueSavePrintFilesButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: {},
    };
  }

  showCopyRenderedOrdersCustomQueueButtonModal = async () => {
    this.modal.showModal();
  };

  queueFilesCustom = async () => {
    try {
      const orders = this.props.data;
      const { name } = this.state;
      const promises = [];
      // create array for archiving
      orders.map(order => {
        return promises.push(
          oa.queueRenderedFilesCustom(order.id, name).catch(() => {
            this.setState({ message: `Something went wrong with queueing ${order.id}` });
            this.message.showModal();
          }),
        );
      });
      await Promise.all(promises);

      this.setState({ message: `Queued ${promises.length} orders in printing [selected]/${name}/` });
      this.message.showModal();
    } catch (error) {
      this.setState({ message: `Something went wrong ☹️${error}` });
      this.message.showModal();
    }
  };

  render() {
    return (
      <span className="fieldEditorContainer">
        <button
          type="button"
          className="button"
          disabled={this.props.disabled}
          onClick={this.showCopyRenderedOrdersCustomQueueButtonModal}
        >
          Queue files
        </button>
        <ModalContainer
          ref={instance => {
            this.modal = instance;
          }}
          title="Add orders to custom queue"
          handler={this.queueFilesCustom}
        >
          <p style={{ padding: '6px' }}>
            You will now queue the files of the selected orders for printing. They will be queued in the folder:
            /printing [selected]/CUSTOM_QUEUE_NAME. Orders that don&apos;t have all files ready will be skipped.
          </p>
          <CustomQueueForm onChange={name => this.setState({ name })} />
        </ModalContainer>
        <Message
          ref={instance => {
            this.message = instance;
          }}
          text={this.state.message}
        />
      </span>
    );
  }
}

export default PrintQueueSavePrintFilesButton;
