import React, { Component } from 'react';
import userActions from 'actions/userActions';
import Modal from 'components/helpers/Modal';
import Message from 'components/helpers/Message';

class GenericActionWithConfirmButton extends Component {
  constructor() {
    super();
    this.state = {
      loaded: false,
    };
  }

  componentDidMount = async () => {
    try {
      const loaded = await userActions.hasRights();

      this.setState({ loaded });
    } catch (error) {
      this.props.toastOpen(error);
      console.error(error);
    }
  };

  removeModalPre = () => {
    if (this.props.oid === undefined) {
      if (!this.props.data || this.props.data.length === 0) {
        return alert('Nothing selected.');
      }
    }

    return this.modal.showModal();
  };

  handler = async () => {
    try {
      await this.props.data.map(i => this.props.action(i));
      this.showModal();
    } catch (error) {
      console.error(error);
      this.setState({ message: `${this.props.actionName} failed` });
    }
  };

  showModal() {
    this.setState({ message: `${this.props.actionName} successful` });
    this.message.showModal();
  }

  render() {
    return (
      <span>
        {this.state.loaded && (
          <span>
            <Message
              ref={instance => {
                this.message = instance;
              }}
              text={this.state.message}
            />
            <Modal
              ref={instance => {
                this.modal = instance;
              }}
              handler={this.handler}
              title={this.props.actionName}
              message={[<br key="space" />, `Are you sure you want to ${this.props.actionName}? `]}
            />
            <button
              type="button"
              className={this.props.small ? 'small-btn' : 'button'}
              onClick={this.removeModalPre.bind(this)}
              data-tip={this.props.actionName}
            >
              {this.props.icon}
              {this.props.small ? '' : this.props.actionName}
            </button>
          </span>
        )}
      </span>
    );
  }
}

export default GenericActionWithConfirmButton;
