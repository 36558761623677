import addressActions from 'actions/addressActions';
import DymoGift from 'components/dymo/DymoGift';
import DymoGiftWesterduin from 'components/dymo/DymoGiftWesterduin';
import TNTLabel from 'components/dymo/TNTLabel';

export default {
  printCodeLabel(window, data, labelType) {
    const dymoLabelService = window.dymo.label.framework;
    const rawAddress = addressActions.buildGiftAddressDymoLabel(data);
    const parsedAddress = this.htmlSpecialChars(rawAddress);
    let label;

    switch (labelType) {
      case 'TNT':
        label = TNTLabel(parsedAddress, data.id, data.ids, data.kix);
        break;
      case 'GIFT':
        label = DymoGift(parsedAddress, data.packageId, data.ids, data.kix);
        break;
      case 'GIFTWESTERDUIN':
        label = DymoGiftWesterduin(parsedAddress, data.packageId, data.ids, data.kix);
        break;
      default:
        throw new Error('Label undefined');
    }

    const getLabel = dymoLabelService.openLabelXml(label);
    const printers = dymoLabelService.getPrinters();
    if (printers.length === 0) {
      throw new Error('No DYMO printers are installed. Install DYMO printers.');
    }
    const printerName = printers.find(p => p.printerType === 'LabelWriterPrinter').name;
    return getLabel.print(printerName);
  },

  htmlSpecialChars(unsafe) {
    if (unsafe === undefined) return '';
    return unsafe
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;');
  },
};
