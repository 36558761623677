/* eslint-disable no-underscore-dangle */
import { apiBaseUrl, storagePictures } from 'config/base';
import api from 'components/helpers/utils/api';

const getUserPicture = async (userId, userPictureId) => {
  return api.fetchAsAdmin('GET', `${apiBaseUrl}/userPictures/${userId}/${userPictureId}`).then(r => r[0]);
};

const getPrintingFiles = async () => {
  const res = await storagePictures.ref('printing').listAll();
  const promises = res.prefixes.map(async folderRef => {
    const { prefixes } = await storagePictures.ref(folderRef.location.path_).listAll();

    return prefixes.map(p => p.location.path_.split('/').pop());
  });
  const orderIds = (await Promise.all(promises)).flat();

  return orderIds;
};

const checkIfFileInStorage = async ref => {
  try {
    const pictureRef = storagePictures.ref(ref);

    const url = await pictureRef.getDownloadURL();

    return !!url;
  } catch {
    return false;
  }
};

const getDownloadURL = async ref => {
  try {
    const pictureRef = storagePictures.ref(ref);
    const url = await pictureRef.getDownloadURL();

    return url;
  } catch {
    return false;
  }
};

const checkImage = imageSrc =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = resolve();
    img.onerror = reject();
    img.src = imageSrc;
  });

const checkIfMissingPicture = async (userId, picture) => {
  if (!picture) {
    return false;
  }

  const { userPictureId, renderedRef } = picture;

  if (!userPictureId) {
    return false;
  }

  const userPicture = await getUserPicture(userId, userPictureId);

  if (!userPicture || !userPicture.originalRef) {
    return false;
  }

  let foundRef = false;
  const refsToCheck = [userPicture.originalRef];

  if (renderedRef && renderedRef !== '') {
    refsToCheck.push(renderedRef);
  }

  await Promise.all(
    refsToCheck.map(async pictureRef => {
      const fileFound = checkIfFileInStorage(pictureRef);

      if (fileFound) {
        foundRef = true;
      }
    }),
  );

  return foundRef;
};

export default {
  getUserPicture,
  getDownloadURL,
  checkIfFileInStorage,
  checkIfMissingPicture,
  checkImage,
  getPrintingFiles,
};
