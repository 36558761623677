import React from 'react';

const Spinner = () => (
  <div className="main">
    <div className="heart">
      <span className="heartL" />
      <span className="heartR" />
      <span className="square" />
    </div>
    <div className="shadow" />
  </div>
);
export default Spinner;
