import React from 'react';

class SubscriptionEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      subscription: { packageId: '', monthlyRecurrenceDay: '', activeOrder: '', prepaidUntil: '' },

      orders: [],
    };
  }

  handleSubscriptionFieldChange = (field, value) => {
    const { subscription } = this.state;
    const { onChange } = this.props;

    subscription[field] = value;
    this.setState({ subscription });

    if (onChange) {
      onChange(subscription);
    }
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps = newProps => {
    const { subscription, orders } = newProps;

    if (Object.keys(subscription).length > 0) {
      this.setState({ subscription, orders });
    }
  };

  render() {
    const { subscription, orders } = this.state;
    const { packageId, monthlyRecurrenceDay, activeOrder, prepaidUntil } = subscription;

    return (
      <div className="fieldEditor">
        <label htmlFor="limit">
          Package ID
          <input
            type="text"
            name="packageId"
            id={packageId}
            value={packageId}
            onChange={event => this.handleSubscriptionFieldChange('packageId', event.target.value)}
          />
        </label>

        <label htmlFor="limit">
          Recurrence Day
          <input
            type="number"
            name="monthlyRecurrenceDay"
            id="monthlyRecurrenceDay"
            value={monthlyRecurrenceDay}
            onChange={event => this.handleSubscriptionFieldChange('monthlyRecurrenceDay', event.target.value)}
          />
        </label>
        <label htmlFor="limit">
          Prepaid Until
          <input
            type="month"
            name="prepaidUntil"
            id="prepaidUntil"
            value={prepaidUntil}
            onChange={event => this.handleSubscriptionFieldChange('prepaidUntil', event.target.value)}
          />
        </label>
        <label htmlFor="limit">
          Active Order
          <select
            type="text"
            name="activeOrder"
            id="activeOrder"
            value={activeOrder}
            onChange={event => this.handleSubscriptionFieldChange('activeOrder', event.target.value)}
          >
            {orders &&
              orders.map(({ orderId }) => {
                return (
                  <option value={orderId} key={orderId}>
                    {orderId}
                  </option>
                );
              })}
          </select>
        </label>
        {/* <input
          
          type="text"
          name="activeOrder"
          id="activeOrder"
          value={activeOrder}
          onChange={event => this.handleSubscriptionFieldChange('activeOrder', event.target.value)}
        /> */}
      </div>
    );
  }
}

export default SubscriptionEditor;
