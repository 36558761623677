import React, { Component } from 'react';
import { MdDelete } from 'react-icons/md';
import { withRouter } from 'react-router-dom';
import ca from 'actions/couponActions';
import Modal from 'components/helpers/Modal';

class RemoveCoupons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
  }

  componentDidMount = () => {
    this.setState({ loaded: true });
  };

  clickHandler = () => {
    this.props.data.map(i => {
      console.info(i.couponCode);
      return ca.deleteCoupon(i.couponCode);
    });
    setTimeout(() => {
      this.props.history.push('/reload');
    }, 2000);
  };

  render() {
    return (
      <span>
        <Modal
          ref={instance => {
            this.modal = instance;
          }}
          handler={() => this.clickHandler()}
          title="Remove selected couponcodes?"
        />
        {this.state.loaded && (
          <button type="button" data-tip="Remove Coupons" className="small-btn" onClick={this.modal.showModal}>
            <MdDelete />
          </button>
        )}
      </span>
    );
  }
}

export default withRouter(RemoveCoupons);
