import React from 'react';

const createOrderLink = props => {
  const userId = props.dependentValues.uid || props.dependentValues.userId;
  const orderId = props.value;
  return (
    <a href={`/pictureOrder/${userId}/${orderId}`} rel="noopener noreferrer" target="_blank">
      {orderId}
    </a>
  );
};

export default createOrderLink;
