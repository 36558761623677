import React from 'react';

const convertToSpecialChar = props => {
  return <span>{String.encodeHTML(props.value)}</span>;
};

export default convertToSpecialChar;

String.encodeHTML = () => {
  return this.replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&apos;');
};
