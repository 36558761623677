import React from 'react';

const PhotobookLink = props => {
  const { id } = props.row;
  const storyId = id || props.value;

  const handleClick = () => {
    window.open(`/photobook/${storyId}`, '_blank');
  };

  return (
    <button type="button" onClick={handleClick}>
      View Photo Book
    </button>
  );
};

export default PhotobookLink;
