import React, { Component } from 'react';
import oa from 'actions/orderActions';
import userActions from 'actions/userActions';
import Modal from 'components/helpers/Modal';

class SubscriptionFreeMonthButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  componentDidMount = () => {
    userActions
      .hasRights()
      .then(value => {
        this.setState({ show: value });
      })
      .catch();
  };

  clickHandler = () => {
    oa.freeOrder(this.props.uid);
  };

  render() {
    return (
      <span>
        <Modal
          ref={instance => {
            this.modal = instance;
          }}
          handler={this.clickHandler}
          title="Free next order  ?"
          message={`Free next order for user: \n${this.props.uid}`}
        />
        {this.state.show && (
          <button type="button" className="button" onClick={this.modal.showModal}>
            Free Next Order
          </button>
        )}
      </span>
    );
  }
}

export default SubscriptionFreeMonthButton;
