import React, { Component } from 'react';
import DataGrid from 'components/forms/DataGrid';
import DatePicker from 'react-datepicker';
import fulfillmentActions from 'actions/fulfillmentActions';
import { MdLoop, MdDelete } from 'react-icons/md';
import DataUtils from 'components/helpers/utils/data';
import countries from 'i18n-iso-countries';
import {
  FulfillmentOrderIdFormatter,
  ConvertTimestamp2DateTime,
  GenericComment,
  ProductPackage,
  TagFormatter,
  ProfileFormatter,
  AddressFormatter,
  LogMessageFormatter,
  VersionFormatter,
} from 'components/helpers/formatters';
import {
  // ProductQueuePrintCouponLabelButton,
  GenericStatusSelect,
  GenericButtonSplit,
  PrintQueueSavePrintFilesButton,
  GenericActionWithConfirmButton,
  ExportFulfillmentQueue,
} from 'components/buttons';
import { suppliers } from 'config/userRoles';
import orderActions from 'actions/orderActions';
import couponActions from 'actions/couponActions';
import ReconciliateFulfillment from 'components/helpers/ReconciliateFulfillment';
import Klikkie from '_klikkie';
import ExportFulfillmentQueueBrievenBus from 'components/buttons/ExportFulfillmentQueueBrievenBus';
import ProductQueuePrintCouponLabelButton from 'components/buttons/ProductQueuePrintCouponLabelButton';

const klikkie = new Klikkie();

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

const {
  Filters: {
    NumericFilter,
    // AutoCompleteFilter,
    MultiSelectFilter,
    // SingleSelectFilter
  },
} = require('react-data-grid-addons');

class ProductQueue extends Component {
  constructor() {
    super();
    this.dataGrid = React.createRef();
    this.state = {
      rows: undefined,
      startDate: new Date(),
    };
    this.columns = [
      {
        key: 'createdAt',
        name: 'Queued',
        filterable: true,
        resizable: true,
        sortable: true,
        width: 150,
        formatter: ConvertTimestamp2DateTime,
      },
      {
        key: 'fulfilledAt',
        name: 'Fulfilled',
        filterable: true,
        resizable: true,
        sortable: true,
        width: 150,
        formatter: ConvertTimestamp2DateTime,
      },
      {
        key: 'id',
        name: 'Id',
        width: 180,
        sortable: true,
        filterable: true,
        resizable: true,
        filterRenderer: MultiSelectFilter,
        formatter: FulfillmentOrderIdFormatter,
      },
      {
        key: 'userId',
        name: 'Ordered by',
        width: 220,
        sortable: true,
        filterable: true,
        resizable: true,
        filterRenderer: MultiSelectFilter,
        formatter: (
          <ProfileFormatter
            detailOpen={userId => this.props.detailOpen(userId)}
            toastOpen={(message, level) => this.props.toastOpen(message, level)}
          />
        ),
      },
      {
        key: 'type',
        name: 'type',
        filterable: true,
        width: 90,
        filterRenderer: MultiSelectFilter,
        formatter: TagFormatter,
      },
      {
        key: 'packageId',
        name: 'Package',
        filterable: true,
        resizable: true,
        filterRenderer: NumericFilter,
        width: 160,
        getRowMetaData: row => row,
        formatter: ProductPackage,
      },
      {
        key: 'units',
        name: 'Units',
        filterable: true,
        resizable: true,
        filterRenderer: NumericFilter,
        width: 70,
      },
      {
        key: 'changeStatus',
        width: 60,
      },
      {
        key: 'version',
        name: 'Version',
        width: 90,
        getRowMetaData: row => row,
        formatter: VersionFormatter,
      },
      {
        key: 'message',
        name: 'Message',
        width: 140,
        resizable: true,
        editable: true,
        formatter: LogMessageFormatter,
      },
      {
        key: 'fullname',
        name: 'Addressee',
        width: 120,
        formatter: ({ value }) => <p style={{ fontSize: '8px' }}>{value}</p>,
      },
      {
        key: 'address',
        name: 'Address',
        filterable: true,
        resizable: true,
        filterRenderer: MultiSelectFilter,
        width: 130,
        getRowMetaData: row => ({ ...row, detailOpen: this.props.detailOpen }),
        formatter: <AddressFormatter noAddressee />,
      },
      {
        key: 'countryCode',
        name: 'Country',
        filterable: true,
        resizable: true,
        filterRenderer: MultiSelectFilter,
        width: 50,
      },
      {
        key: 'kix',
        name: 'KIX Code',
        filterable: true,
        resizable: true,
        editable: true,
        width: 130,
        getRowMetaData: row => row,
      },
      {
        key: 'comment',
        name: 'Comment',
        filterable: true,
        resizable: true,
        width: 300,
        getRowMetaData: row => row,
        formatter: (
          <GenericComment
            changeHandler={this.updateQueue}
            idField="id"
            toastOpen={(message, level) => this.props.toastOpen(message, level)}
          />
        ),
      },
    ];
  }

  updateQueue = (id, comment) => {
    fulfillmentActions.updateQueue(id, { comment });

    const { rows } = this.state;
    rows.forEach((row, i) => {
      if (id !== row.id) return;
      rows[i].comment = comment;
    });

    this.setState({
      rows,
    });
  };

  componentDidMount = () => {
    return this.loadData();
  };

  loadData = async () => {
    try {
      this.setState({ rows: undefined });
      const { user } = this.props;
      const months = {
        '103': 12,
        '104': 6,
        '105': 3,
        '113': 12,
        '114': 6,
        '115': 3,
      };
      const orders = await fulfillmentActions.getQueue(this.props.status, this.state.startDate);
      orders.sort(DataUtils.dynamicSort('-createdAt'));
      // add date for header
      orders.map(async order => {
        const o = order;

        o.date = orderActions.compileDate(o.createdAt);
        o.months = Object.keys(months).includes(o.packageId) ? months[o.packageId] : '';
        if (o.months) this.getCodes(o.id, o.orderId);
        if (o.address) {
          o.countryCode = o.address.countryCode;
          o.fullname = o.address.fullname;
          o.address.country = o.address && o.address.countryCode ? countries.getName(o.address.countryCode, 'en') : '';
        }
        o.kix = o.metadata ? o.metadata.kix : '';
        o.comment = o.comment || '- no comment -';
        return o;
      });

      // Filter based on supplier
      const supplier = suppliers.find(s => s.userIds.includes(user.uid));
      const name = supplier ? supplier.name : '';
      const rows =
        this.props.role !== 'admin'
          ? orders.filter(row => supplier.skus.includes(parseInt(row.packageId, 10)))
          : orders;

      this.setState({ rows, supplier: name });
    } catch (error) {
      console.error(error);
      this.props.toastOpen(error, 'error');
    }
  };

  handleDateChange = startDate => {
    this.setState({ startDate }, this.loadData);
  };

  changeStatusOfSelection = async status => {
    try {
      return this.dataGrid.current.actionOnSelection(order => this.changeStatus(order, status));
    } catch (error) {
      console.error(error);
      return this.props.toastOpen(error, 'error');
    }
  };

  changeStatus = async (order, status) => {
    try {
      const { id } = order;
      const newData = { ...order };
      delete newData.id;
      delete newData.date;
      delete newData.get;
      delete newData.months;
      delete newData.kix;
      delete newData.fullname;
      delete newData.countryCode;
      if (!newData.metadata) {
        newData.metadata = {};
      }
      if (!newData.metadata.printId) {
        newData.metadata.printId = id;
      }
      newData.status = status;
      await klikkie.fulfillment(order.id).update(newData);

      // resend to supplier
      if (status === 'pending') {
        await fulfillmentActions.sendOrderToSupplier(id);
      }

      if (status !== this.props.status) this.rowDeleteById(id);
      this.props.toastOpen(`Order ${id} is set to ${status}`);
    } catch (error) {
      console.error(error);
      this.props.toastOpen(error);
      const prevData = { ...order };
      delete prevData.id;
      delete prevData.date;
      delete prevData.months;
      delete prevData.kix;
      delete prevData.fullname;
      delete prevData.countryCode;
      if (!prevData.metadata) {
        prevData.metadata = {};
      }
      if (!prevData.metadata.printId) {
        prevData.metadata.printId = order.id;
      }
      await klikkie.fulfillment(order.id).create(prevData);
    }
  };

  rowDeleteById = id => {
    const { rows } = this.state;

    const updatedRows = rows.filter(item => item.id !== id);

    this.setState({
      rows: updatedRows,
    });
  };

  getCellActions = (column, row) => {
    const changeStatus = [
      {
        icon: 'icon ion-md-more',
        actions: [
          {
            text: 'Set as pending',
            callback: () => this.changeStatus(row, 'pending'),
          },
          {
            text: 'Set as rejected',
            callback: () => this.changeStatus(row, 'rejected'),
          },
          {
            text: 'Set as fulfilled',
            callback: () => this.changeStatus(row, 'fulfilled'),
          },
        ],
      },
    ];

    const cellActions = {
      changeStatus,
    };

    return cellActions[column.key];
  };

  addCodeOfSelection = async () => {
    try {
      this.dataGrid.current.actionOnSelection(row => {
        this.getCodes(row.id, row.orderId);
      });
      return this.props.toastOpen('Loaded all gift codes!');
    } catch (error) {
      console.error(error);
      return this.props.toastOpen(error, 'error');
    }
  };

  produceOrder = async (render = true, idempotent = true) => {
    if (!this.state.selection || this.state.selection.length === 0) {
      return this.props.toastOpen('Please select a row in order to produce', 'error');
    }
    const s = this.state.selection.length > 1 ? 's' : '';
    this.props.toastOpen(`Sending ${this.state.selection.length} order${s} to supplier${s}`);
    this.dataGrid.current.actionOnSelection(async row => {
      try {
        const data = await fulfillmentActions.sendOrderToSupplier(row.id, render, idempotent);
        return this.props.toastOpen(`${data[0].supplier} accepted the order (${data[0].externalId})`);
      } catch (error) {
        return this.props.toastOpen(`Failed to produce order: ${row.id} - ${error}`);
      }
    });
    return true;
  };

  getCodes = async (id, orderId) => {
    try {
      if (!orderId) return;
      const code = await couponActions.readCouponByOrderId(orderId);
      if (!code) return;
      const couponCode = Object.keys(code)[0];
      const rows = Array.from(this.state.rows);
      const updatedRows = rows.map(i => {
        const row = i;
        if (i.id !== id) return row;
        row.couponCode = couponCode;
        return row;
      });
      this.setState({ rows: updatedRows });
    } catch (error) {
      console.error(error);
    }
  };

  resubmitOrder = async () => {
    if (!this.state.selection || this.state.selection.length === 0) {
      return this.props.toastOpen('Please select a row in order to produce', 'error');
    }
    const s = this.state.selection.length > 1 ? 's' : '';
    this.props.toastOpen(`Resubmitting ${this.state.selection.length} order${s} to fulfillment queue`);
    this.dataGrid.current.actionOnSelection(async row => {
      try {
        await fulfillmentActions.addOrderToQueue(row.metadata.orderApiId);
        return this.props.toastOpen(`Resubmitted ${this.state.selection.length} order${s} to fulfillment queue`);
      } catch (error) {
        return this.props.toastOpen(`Failed to produce order: ${row.id} - ${error}`);
      }
    });
    return true;
  };

  // eslint-disable-next-line no-unused-vars
  onGridRowsUpdated = async ({ fromRow, toRow, updated }) => {
    try {
      const newRow = Object.assign(this.state.rows[fromRow], updated);
      const { active } = newRow;
      delete newRow.active;
      const data = {
        metadata: { kix: newRow.kix },
        message: newRow.message,
      };

      await fulfillmentActions.updateQueue(newRow.id, data);
      const { rows } = this.state;
      delete rows[fromRow];
      this.setState({ rows }, () => {
        rows[fromRow] = Object.assign(newRow, { active });
        this.setState({ rows });
      });
      this.props.toastOpen(`${newRow.id} changes saved`);
    } catch (error) {
      console.error(error);
      this.props.toastOpen(error);
    }
  };

  render() {
    return (
      <div>
        <DataGrid
          ref={this.dataGrid}
          rows={this.state.rows}
          columns={this.columns}
          print={false}
          name={this.props.name}
          hideCsvButton
          onGridRowsUpdated={this.onGridRowsUpdated}
          enableCellSelect
          getSelection={selection => this.setState({ selection: selection.filter(r => Object.keys(r).length > 4) })} // to get the groupings out
          actions={this.getCellActions}
          // groupBy={['date']}
          toastOpen={this.props.toastOpen}
          extraTools={
            <span style={{ display: 'contents' }}>
              <ExportFulfillmentQueue data={this.state.selection} title="fulfillment-queue" />
              <ExportFulfillmentQueueBrievenBus data={this.state.selection} title="brievenbus" />
              {this.props.status === 'fulfilled' && (
                <DatePicker
                  todayButton="Today"
                  selected={this.state.startDate}
                  onChange={this.handleDateChange}
                  value={this.state.startDate}
                />
              )}
              <button
                type="button"
                className={this.state.rows ? 'small-btn' : 'small-btn active'}
                data-tip="Reload Data"
                onClick={this.loadData}
              >
                <div className={this.state.rows ? '' : 'spin'}>
                  <MdLoop />
                </div>
              </button>
              <GenericButtonSplit />
              {/* <ProductQueuePrintCouponLabelButton
                data={this.state.selection}
                labelType="GIFT"
                toastOpen={this.props.toastOpen}
              /> */}
              <ProductQueuePrintCouponLabelButton
                data={this.state.selection}
                labelType="GIFTWESTERDUIN"
                toastOpen={this.props.toastOpen}
                name=" Westerduin"
              />
              {/* <button className="button" type="button" onClick={this.addCodeOfSelection}>
                Load missing codes
              </button> */}
              {/* <GenericButtonSplit /> */}
              <GenericStatusSelect exclude={this.props.status} changeStatus={this.changeStatusOfSelection} />
              <GenericActionWithConfirmButton
                actionName="Remove"
                small
                icon={<MdDelete />}
                action={i => fulfillmentActions.removeFromQueue(i.id).then(() => this.rowDeleteById(i.id))}
                data={this.state.selection}
              />
              <GenericButtonSplit />
              <ReconciliateFulfillment />
              <PrintQueueSavePrintFilesButton data={this.state.selection} />
              <button type="button" className="button" onClick={this.resubmitOrder}>
                Re-add to fulfillment
              </button>
              <button type="button" className="button" onClick={this.produceOrder}>
                Produce
              </button>
              <button type="button" className="button" onClick={() => this.produceOrder(true, false)}>
                Produce Again
              </button>
              <button type="button" className="button" onClick={() => this.produceOrder(false, false)}>
                Produce (Skip render)
              </button>
              <GenericButtonSplit />
              {this.state.supplier && <span> Supplier: {this.state.supplier}</span>}
            </span>
          }
        />
      </div>
    );
  }
}

export default ProductQueue;
