import React from 'react';
import { auth } from 'config/base';
import Spinner from 'components/helpers/Spinner';
import packageJson from '../../../package.json';
import logo from '../../img/klikkie-no-logo.png';

class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
    };
    this.email = React.createRef();
    this.password = React.createRef();
    this.login = async event => {
      try {
        const email = this.email.current.value;
        const password = this.password.current.value;
        event.preventDefault();

        this.setState({ loading: true });
        const user = await auth.signInWithEmailAndPassword(email, password);
        this.props.callback(user);
        this.setState({ loading: false });
      } catch (error) {
        console.error(error.code);
        if (error && typeof error.code === 'string' && !error.code.startsWith('auth')) console.error(error);
        this.props.toastOpen(error, 'error');
        this.setState({ loading: false });
      }
    };
  }

  render() {
    return (
      <div className="wrapper login-wrapper">
        <div className="login">
          <div className="logo">
            <img src={logo} alt="Klikkie" />v{packageJson.version}
          </div>
          {this.state.loading && <Spinner />}
          <div>
            <form action="" onSubmit={this.login}>
              <label htmlFor="email">
                Email
                <input ref={this.email} id="email" name="email" type="email" placeholder="Type your email" />
              </label>
              <label htmlFor="password">
                Password
                <input
                  ref={this.password}
                  type="password"
                  id="password"
                  placeholder="Type your password"
                  autoComplete="current-password"
                />
              </label>
              {!this.state.loading && <button type="submit">Login</button>}
              {this.state.loading && <div>Loading</div>}
            </form>
          </div>
        </div>
      </div>
    );
  }
}
export default Login;
