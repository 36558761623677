import React from 'react';

class ModalContainer extends React.Component {
  constructor(props) {
    super(props);

    this.escFunction = this.escFunction.bind(this);
    this.state = {
      show: false,
    };
  }

  componentDidMount = () => {
    document.addEventListener('keydown', this.escFunction, false);

    if (this.props.showOnStart) {
      this.setState({ show: true });
    }
  };

  componentWillUnmount = () => {
    document.removeEventListener('keydown', this.escFunction, false);
    const blankState = {};
    Object.keys(this.state).forEach(stateKey => {
      blankState[stateKey] = undefined;
    });
    this.setState(blankState);
  };

  showModal = () => {
    this.setState({ show: true });
  };

  cancelModal = () => {
    this.setState({ show: false });
  };

  clickHandler = () => {
    this.setState({ show: false });
    this.props.handler();
  };

  escFunction(event) {
    if (event.keyCode !== 27) return false;
    return this.cancelModal();
  }

  render() {
    const { children } = this.props;

    return (
      <div className={`modal-wrapper ${this.state.show}`}>
        <div className="modal-container">
          <h2>{this.props.title}</h2>
          {children}
          <div>
            <button type="button" className="secondary" onClick={this.cancelModal}>
              cancel
            </button>
            <button type="button" className="primary" onClick={this.clickHandler}>
              save
            </button>
          </div>
        </div>
      </div>
    );
  }
}
export default ModalContainer;
