import React from 'react';
import { auth } from 'config/base';
import ConfirmModal from 'components/helpers/ConfirmModal';
import fulfillmentActions from 'actions/fulfillmentActions';

class ReconciliateFulfillment extends React.Component {
  constructor() {
    super();
    this.state = {
      modalMessage: '',
      value: '',
      handler: this.checkStorage,
      title: 'Search multiple orders',
    };
  }

  componentDidMount = () => {};

  checkStorage = async (confirmed, textarea) => {
    try {
      if (!confirmed) return;
      const queue = await fulfillmentActions.getQueue('pending');
      const orderIdsQueue = queue.map(o => o.id);
      const orderIdsTextArea = textarea.split('\n');
      const inQueue = orderIdsTextArea.filter(x => orderIdsQueue.includes(x));
      this.setState(
        {
          modalMessage: `There are ${inQueue.length} orders that are still in the fulfillment queue. Do you want to mark them all as 'fulfilled'?`,
          value: inQueue.length > 0 ? inQueue.join('\n') : ' ',
          handler: this.fulfillOrders,
          title: 'Fulfill orders',
        },
        this.Modal.showModal,
      );
    } catch (error) {
      console.error(error);
    }
  };

  fulfillOrders = async (confirmed, textarea) => {
    try {
      if (!confirmed) return;
      const date = new Date().toISOString();

      const { uid: userId } = auth.currentUser;
      const orderIds = textarea.includes('\n') ? textarea.split('\n') : [textarea];
      const promises = orderIds.map(orderId =>
        fulfillmentActions.updateQueue(orderId, {
          status: 'fulfilled',
          comment: `Reconciliated ${date} by ${userId}`,
        }),
      );
      if (promises.length === 0) return;
      await Promise.all(promises);
      alert(`All orders marked as 'fulfilled'`);
    } catch (error) {
      alert(JSON.parse('error'));
      console.error(error);
    }
  };

  openModal = async () => {
    this.setState({
      handler: this.checkStorage,
      modalMessage: `Type or paste the id's of the orders that are fulfilled below and press OK`,
      title: 'Search multiple orders',
    });
    this.Modal.showModal();
  };

  render() {
    return (
      <span>
        <ConfirmModal
          ref={instance => {
            this.Modal = instance;
          }}
          handler={this.state.handler}
          title={this.state.title}
          message={this.state.modalMessage}
          textarea={this.state.value}
        />
        <button className="button" type="button" onClick={this.openModal}>
          Reconcile Orders
        </button>
      </span>
    );
  }
}
export default ReconciliateFulfillment;
