import React from 'react';
import { ExportToCsv } from 'export-to-csv';
import { decodeHTML } from 'entities';

class ExportFulfillmentQueue extends React.Component {
  exportData = () => {
    if (this.props.data.length < 1) return alert('Please select at least one row');
    const date = new Date().toISOString().slice(0, 10);
    const data = this.props.data.map(row => {
      return {
        id: decodeHTML(row.id || ''),
        orderId: decodeHTML(row.orderId || ''),
        userId: decodeHTML(row.userId || ''),
        packageId: decodeHTML(row.packageId || ''),
        units: decodeHTML(row.units || ''),
        months: decodeHTML(row.months || ''),
        message: decodeHTML(row.message || ''),
        couponCode: row.couponCode || '',
        status: decodeHTML(row.status || ''),
        type: decodeHTML(row.type || ''),
        fullname: decodeHTML(row.fullname || ''),
        address: decodeHTML(`${row.address.address || ''} ${row.address.address2 || ''}`),
        zipcode: decodeHTML(row.address.zipcode || '').toUpperCase(),
        city: decodeHTML(row.address.city || ''),
        country: decodeHTML(row.address.country || ''),
        countryCode: decodeHTML(row.countryCode || '').toUpperCase(),
        kix: decodeHTML(row.kix || ''),
        date,
      };
    });
    const options = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalseparator: '.',
      filename: `Export_${this.props.title || ''}_${date}`,
      showLabels: true,
      showTitle: false,
      useBom: true,
      headers: Object.keys(data[0]),
    };

    const exportToCsv = new ExportToCsv(options);
    return exportToCsv.generateCsv(data);
  };

  render() {
    return (
      <button type="button" disabled={this.props.disabled} onClick={this.exportData} className="button">
        Export Orders
      </button>
    );
  }
}
export default ExportFulfillmentQueue;
