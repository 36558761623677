import React, { Component } from 'react';
import moment from 'moment';
import paymentActions from 'actions/paymentActions';
import userActions from 'actions/userActions';
import Modal from 'components/helpers/Modal';

class SubscriptionChargeButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  componentDidMount = () => {
    userActions
      .hasRights()
      .then(value => {
        this.setState({ show: value });
      })
      .catch();
  };

  clickHandler = () => {
    paymentActions
      .triggerRecurrenceCharge(this.props.uid)
      .then(result => {
        console.info(result);
        alert(`Payment triggered for user ${this.props.uid} succeeded`);
      })
      .catch(error => {
        alert(`Payment triggered for user ${this.props.uid} errored`);
        console.error(error);
      });
  };

  render() {
    return (
      <span>
        <Modal
          ref={instance => {
            this.modal = instance;
          }}
          handler={this.clickHandler}
          title="Trigger recurrent charge"
          message={`Your are triggering charge for user ${this.props.uid} and period ${moment().format(
            'YYYY-MM',
          )}. Are you sure?`}
        />
        {this.state.show && (
          <button type="submit" className="button" onClick={this.modal.showModal}>
            Trigger recurrence charge
          </button>
        )}
      </span>
    );
  }
}

export default SubscriptionChargeButton;
