import React from 'react';
import { withRouter } from 'react-router-dom';
import Modal from 'components/helpers/Modal';
import { auth } from 'config/base';

class Logout extends React.Component {
  componentDidMount = () => {
    this.modal.showModal();
  };

  logout = async () => {
    try {
      await auth.signOut();
      localStorage.clear();
      this.props.history.push(`/`);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  goBack = () => this.props.history.goBack();

  render() {
    return (
      <span>
        <Modal
          key="logout"
          ref={instance => {
            this.modal = instance;
          }}
          handler={this.logout}
          title="Signing off"
          message="Are you sure you want to logout from the dashboard?"
          cancelFunction={this.goBack}
        />
      </span>
    );
  }
}
export default withRouter(Logout);
