/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from 'react';
import { GenericCopyButton } from 'components/buttons';
import userActions from 'actions/userActions';

class ProfileFormatter extends React.Component {
  constructor(props) {
    super(props);
    this.timer = { cancel: null };
    this.state = {
      userId: '',
      profile: {
        name: '',
        surname: '',
      },
    };
  }

  componentDidMount = () => this.loadData();

  loadData = async () => {
    try {
      const timeout = (ms, value) => {
        const p = new Promise((resolve, reject) => {
          this.timeout = setTimeout(() => {
            resolve(value);
          }, ms);
          this.cancel = err => {
            reject(err || new Error('Timeout'));
            clearTimeout(p.timeout);
          };
        });
        return p;
      };
      this.timer = timeout(500);
      await this.timer.timeout;
      const userId = this.props.value;
      const { profile } = await userActions.getUser(userId);

      this.setState({ profile, userId });
    } catch (error) {
      console.error(error);
    }
  };

  componentDidUpdate = prevProps => {
    if (prevProps.dependentValues === this.props.dependentValues && prevProps.value === this.props.value) return;
    this.loadData();
  };

  componentWillUnmount = () => this.timer.cancel;

  getInitials = names => {
    const initials = names.map(name => {
      if (!name) return undefined;
      return name.charAt(0);
    });
    return initials.join('');
  };

  render = () => {
    const { userId, profile } = this.state;
    const { name, surname, email } = { ...profile };
    // const hasName = name || surname;
    const initials = this.getInitials([name, surname]);
    return (
      <span className="profile-formatter">
        <div role="button" className="user-avatar" onClick={() => this.props.detailOpen(userId)}>
          {initials || '??'}
        </div>

        <div role="button" onClick={() => this.props.detailOpen(userId)} className="name">
          {name || surname ? `${name} ${surname}` : userId}
        </div>

        <GenericCopyButton className="minimal-btn" value={email} />
      </span>
    );
  };
}
export default ProfileFormatter;
