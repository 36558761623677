import React, { Component } from 'react';

class Reload extends Component {
  componentDidMount = () => this.props.history.goBack();

  render() {
    return <div className="home">Reloading page</div>;
  }
}
export default Reload;
