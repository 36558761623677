import React, { Component } from 'react';
import Fulfillment from './Fulfillment';

class ProductQueue extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Fulfillment
        status="fulfilled"
        name="Fulfillment Archive"
        toastOpen={this.props.toastOpen}
        detailOpen={this.props.detailOpen}
        role={this.props.role}
        user={this.props.user}
      />
    );
  }
}

export default ProductQueue;
