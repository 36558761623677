import React from 'react';

import Modal from 'components/helpers/Modal';
import Message from 'components/helpers/Message';

import userActions from 'actions/userActions';
import subscriptionActions from 'actions/subscriptionActions';

class SubscriptionPauseUnpauseButton extends React.Component {
  constructor() {
    super();
    this.state = {
      show: false,
      message: '',
    };
  }

  componentDidMount = () => {
    userActions
      .hasRights()
      .then(value => {
        this.setState({ show: value });
      })
      .catch();
  };

  pauseUser = () => {
    const { onChange } = this.props;

    subscriptionActions
      .pauseSubscription(this.props.uid)
      .then(() => {
        this.setState({ message: `Subscription of: ${this.props.uid} is now Cancled 💤!` });
        this.message.showModal();
        onChange();
      })
      .catch(error => {
        this.setState({ message: `Error:${error}` });
        this.message.showModal();
      });
  };

  unPauseUser = () => {
    const { onChange } = this.props;

    subscriptionActions
      .unpauseSubscription(this.props.uid)
      .then(() => {
        this.setState({ message: `Subscription of: ${this.props.uid} is now Active ❤️!` });
        this.message.showModal();
        onChange();
      })
      .catch(error => {
        this.setState({ message: `Error:${error}` });
        this.message.showModal();
      });
  };

  render() {
    const { status } = this.props;

    return (
      <span>
        {this.state.show && (
          <>
            {status === 'active' ? (
              <button type="button" className="button" onClick={this.pauseModal.showModal}>
                <span role="img" aria-label="emoji">
                  💤{' '}
                </span>
                Pause NOW
              </button>
            ) : (
              <button type="button" className="button" onClick={this.unPauseModal.showModal}>
                <span role="img" aria-label="emoji">
                  ❤️{' '}
                </span>
                UnPause NOW
              </button>
            )}
          </>
        )}
        <Modal
          ref={instance => {
            this.pauseModal = instance;
          }}
          handler={this.pauseUser}
          title="Cancel Subscriptons 💤"
          message={`Subscription from user ${this.props.uid} is going to be canceled. Are you sure?`}
        />
        <Modal
          ref={instance => {
            this.unPauseModal = instance;
          }}
          handler={this.unPauseUser}
          title="Reactivate Subscriptons ❤"
          message={`Subscription from user ${this.props.uid} is going to be reactivated. Are you sure?`}
        />
        <Message
          ref={instance => {
            this.message = instance;
          }}
          text={this.state.message}
        />
      </span>
    );
  }
}
export default SubscriptionPauseUnpauseButton;
