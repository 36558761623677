import orderActions from 'actions/orderActions';
import React from 'react';

class VersionFormatter extends React.Component {
  constructor(props) {
    super(props);
    this.timer = { cancel: null };
    this.state = {
      platform: '',
      version: '',
    };
  }

  componentDidMount = () => this.loadData();

  loadData = async () => {
    try {
      const timeout = (ms, value) => {
        const p = new Promise((resolve, reject) => {
          this.timeout = setTimeout(() => {
            resolve(value);
          }, ms);
          this.cancel = err => {
            reject(err || new Error('Timeout'));
            clearTimeout(p.timeout);
          };
        });
        return p;
      };
      this.timer = timeout(2000);
      await this.timer.timeout;
      const { id, userId } = this.props.dependentValues;
      const order = (await orderActions.getPictureOrder(userId, id))[0];
      const platform = order?.metadata?.platform || '';
      const version = order?.metadata?.version || '';

      this.setState({ platform, version });
    } catch (error) {
      console.error(error);
    }
  };

  componentDidUpdate = prevProps => {
    if (prevProps.dependentValues === this.props.dependentValues && prevProps.value === this.props.value) return;
    this.loadData();
  };

  componentWillUnmount = () => this.timer.cancel;

  render = () => {
    const { platform, version } = this.state;
    return (
      <div
        style={{
          background: 'rgb(204 239 202)',
          borderRadius: '19px',
          fontSize: '8px',
          textAlign: 'center',
          width: 'fit-content',
          padding: '0px 8px',
          color: '#03545f',
          height: '19px',
          lineHeight: '19px',
          textTransform: 'uppercase',
          fontHeight: '600',
          display: 'inline-block',
        }}
      >
        {`${platform.substring(0, 3)} ${version}`}
      </div>
    );
  };
}

export default VersionFormatter;
