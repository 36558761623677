import React from 'react';
import Modal from 'components/helpers/Modal';
import orderActions from 'actions/orderActions';
import userActions from 'actions/userActions';

class PictureOrderCreateButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  componentDidMount = async () => {
    try {
      const show = await userActions.hasRights();
      this.setState({ show });
    } catch (error) {
      console.error(error);
      this.props.toastOpen(error);
    }
  };

  createPictureOrder = async () => {
    try {
      this.setState({ show: false });
      await orderActions.createPictureOrder(this.props.uid);
      this.props.toastOpen(`Picture order created for user ${this.props.uid}`);
      this.setState({ show: true });
    } catch (error) {
      console.error(error);
      this.props.toastOpen(error);
      this.setState({ show: true });
    }
  };

  render() {
    return (
      <span>
        {this.state.show && (
          <button type="button" className="button" onClick={this.modal.showModal}>
            <span role="img" aria-label="emoji">
              Create picture order
            </span>
          </button>
        )}
        <Modal
          ref={instance => {
            this.modal = instance;
          }}
          handler={this.createPictureOrder}
          title="️Create Picture Order"
          message={`Are you sure you want to create a picture order for user ${this.props.uid}?`}
        />
      </span>
    );
  }
}

export default PictureOrderCreateButton;
