import React from 'react';

const loggedInUser = props => {
  const { email, uid } = props.user;
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div role="button" tabIndex={0} className="loggedInUser" onClick={() => props.detailOpen(uid)}>
      <span className="picture">{email[0]}</span>
      <span className="name">
        {email}
        <p className="role">{props.role}</p>
      </span>
    </div>
  );
};

export default loggedInUser;
