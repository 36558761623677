import React, { Component } from 'react';
import userActions from 'actions/userActions';
import Modal from 'components/helpers/Modal';
import orderActions from 'actions/orderActions';
import Message from 'components/helpers/Message';

class PictureOrderResetButton extends Component {
  constructor() {
    super();
    this.state = {
      loaded: false,
    };
  }

  componentDidMount = () => {
    userActions
      .hasRights()
      .then(value => {
        this.setState({ loaded: value });
      })
      .catch();
  };

  removeModalPre = () => {
    if (this.props.oid === undefined) {
      if (this.props.data.length === 0) {
        return alert('Nothing selected.');
      }
    }

    return this.modal.showModal();
  };

  distributeOrders = () => {
    console.info(this.props.data);

    this.props.data.map(i =>
      orderActions.resetPictureOrder(this.props.userId, i.orderId).then(() => this.showModal(i.orderId)),
    );
  };

  showModal(oid) {
    this.setState({ message: `${oid} has been reseted` });
    this.message.showModal();
  }

  render() {
    return (
      <span>
        {this.state.loaded && (
          <span>
            <Message
              ref={instance => {
                this.message = instance;
              }}
              text={this.state.message}
            />
            <Modal
              ref={instance => {
                this.modal = instance;
              }}
              handler={this.distributeOrders}
              title="Reset Order(s)? "
              message={[<br key="space" />, 'Are you sure you want to reset these order(s)? ']}
            />
            <button type="button" className="button" onClick={this.removeModalPre.bind(this)}>
              Reset order(s)
            </button>
          </span>
        )}
      </span>
    );
  }
}

export default PictureOrderResetButton;
