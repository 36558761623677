import moment from 'moment';
import { apiBaseUrl, database } from 'config/base';
import api from 'components/helpers/utils/api';

export default {
  getQueue(status = 'pending', date) {
    // temp fix
    if (status === 'fulfilled') {
      return this.getFulfillmentArchiveTempFix(date);
    }
    return api.fetchAsAdmin('GET', `${apiBaseUrl}/fulfillment/status/${status}`);
  },
  getQueueUser(userId) {
    return api.fetchAsAdmin('GET', `${apiBaseUrl}/fulfillment/user/${userId}`);
  },
  updateQueue(orderId, bodyData) {
    return api.fetchAsAdmin('PUT', `${apiBaseUrl}/fulfillment/${orderId}`, bodyData);
  },
  addOrderToQueue(orderId) {
    return api.fetchAsAdmin('POST', `${apiBaseUrl}/fulfillment/`, { orderId });
  },
  removeFromQueue(orderId) {
    return api.fetchAsAdmin('DELETE', `${apiBaseUrl}/fulfillment/${orderId}`);
  },
  renderSingleImageOfPictureOrder(userId, orderId, fulfillmentId, pictureIdFilter) {
    return api.fetchAsAdmin('POST', `${apiBaseUrl}/fulfillment/${fulfillmentId}/singlePictureOfPictureOrder/render`, {
      userId,
      orderId,
      pictureIdFilter,
    });
  },
  sendOrderToSupplier(fulfillmentId, render = true, idempotent = true) {
    return api.fetchAsAdmin('POST', `${apiBaseUrl}/fulfillment/${fulfillmentId}/supplier/produce`, {
      render,
      idempotent,
    });
  },
  recreatePhotobook(fulfillmentId, render = true, idempotent = true) {
    return api.fetchAsAdmin('POST', `${apiBaseUrl}/fulfillment/${fulfillmentId}/supplier/re-create`, {
      render,
      idempotent,
    });
  },
  getStatusFromSupplier(fulfillmentId) {
    return api.fetchAsAdmin('GET', `${apiBaseUrl}/fulfillment/${fulfillmentId}/supplier/status`);
  },
  createFujiOrder(userId, orderId, idempotent = true) {
    return api.fetchAsAdmin('POST', `${apiBaseUrl}/fuji/orders`, { userId, orderId, idempotent });
  },
  async getFulfillmentArchiveTempFix(date) {
    try {
      const endDate = moment(date)
        .add(1, 'days')
        .format('YYYY-MM-DD');
      const startDate = moment(date).format('YYYY-MM-DD');

      const snap = await database
        .ref('Fulfillment')
        .orderByChild('fulfilledAt')
        .startAt(startDate)
        .endAt(endDate)
        .once('value');
      const data = snap.numChildren() === 0 ? [] : snap.val();
      const rows = Object.entries(data).map(([id, value]) => ({ id, ...value }));

      return rows;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};
