import moment from 'moment';
import actions from 'actions/eventSchedulerActions';

const EVENTS = {
  pause: 'subscription-pause',
  churn: 'subscription-churn',
  activate: 'subscription-activate',
};

export const createPauseEvent = (userId, date) => actions.createEvent(EVENTS.pause, userId, date);

export const createChurnEvent = (userId, date) => actions.createEvent(EVENTS.churn, userId, date);

export const createActivateEvent = (userId, date) => actions.createEvent(EVENTS.activate, userId, date);

export const deleteAllPauseEvents = userId => actions.deleteEvent(EVENTS.pause, userId);

export const deleteAllChurnEvents = userId => actions.deleteEvent(EVENTS.churn, userId);

export const deleteAllActivateEvents = userId => actions.deleteEvent(EVENTS.activate, userId);

export const pauseInTheFuture = async (userId, date) => {
  await Promise.all([deleteAllChurnEvents(userId), deleteAllPauseEvents(userId), deleteAllActivateEvents(userId)]);

  return createPauseEvent(userId, date);
};

export const churnInTheFuture = async (userId, date) => {
  await Promise.all([deleteAllChurnEvents(userId), deleteAllPauseEvents(userId), deleteAllActivateEvents(userId)]);

  return createChurnEvent(userId, date);
};

export const activateInTheFuture = async (userId, date) => {
  await Promise.all([deleteAllChurnEvents(userId), deleteAllPauseEvents(userId), deleteAllActivateEvents(userId)]);

  return createActivateEvent(userId, date);
};

export const willPauseInTheFuture = async userId => {
  const events = await actions.getEvents(userId);

  const currentDate = moment();

  let foundScheduledEvent = false;

  Object.values(events).forEach(scheduledEvent => {
    if (scheduledEvent.event === EVENTS.pause) {
      const eventDate = moment(scheduledEvent.triggerAt);

      if (eventDate > currentDate) {
        foundScheduledEvent = scheduledEvent.triggerAt;
      }
    }
  });

  return foundScheduledEvent;
};

export const willChurnInTheFuture = async userId => {
  const events = await actions.getEvents(userId);

  const currentDate = moment();

  let foundScheduledEvent = false;

  Object.values(events).forEach(scheduledEvent => {
    if (scheduledEvent.event === EVENTS.churn) {
      const eventDate = moment(scheduledEvent.triggerAt);

      if (eventDate > currentDate) {
        foundScheduledEvent = scheduledEvent.triggerAt;
      }
    }
  });

  return foundScheduledEvent;
};

export const willActivateInTheFuture = async userId => {
  const events = await actions.getEvents(userId);

  const currentDate = moment();

  let foundScheduledEvent = false;

  Object.values(events).forEach(scheduledEvent => {
    if (scheduledEvent.event === EVENTS.activate) {
      const eventDate = moment(scheduledEvent.triggerAt);

      if (eventDate > currentDate) {
        foundScheduledEvent = scheduledEvent.triggerAt;
      }
    }
  });

  return foundScheduledEvent;
};
