/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

const AddressFormatter = props => {
  const { value: orderId, noAddressee } = props;
  const { address: adrObj, couponCode, userId, type, detailOpen } = props.dependentValues;
  const tabOrder = type === 'pictures' ? 'pictureOrders' : 'orders';
  const { fullname, address, address2, zipcode, city, country, countryCode } = adrObj || {};
  const zipcodeFormatted =
    countryCode === 'NL' ? `${zipcode.replace(/[^0-9]/g, '')} ${zipcode.replace(/[0-9]/g, '')}` : zipcode;

  return (
    <span className="address">
      {!orderId && (
        <div type="button" onClick={() => detailOpen(userId, tabOrder)}>
          addressId missing
          <br />
          Go to order pictureOrder
        </div>
      )}
      {!adrObj && (
        <span>
          Address Not found: <br /> {orderId}
        </span>
      )}
      {adrObj && (
        <div type="button" onClick={() => detailOpen(userId, 'addresses')}>
          {fullname && !noAddressee && (
            <p style={{ lineHeight: '2px', padding: 0, border: 0, fontWeight: '600' }}>{fullname}</p>
          )}
          {(address || address2) && (
            <p>
              {address} {address2 ? `-${address2}` : ''}
            </p>
          )}
          {(zipcodeFormatted || city) && (
            <p className="upper">
              {zipcodeFormatted} &nbsp;{city}
            </p>
          )}
          {country && country !== 'Netherlands' && <p className="upper">{country}</p>}
          {couponCode && <p>{couponCode}</p>}
        </div>
      )}
    </span>
  );
};

export default AddressFormatter;
