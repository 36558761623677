/* eslint-disable react/no-string-refs */
import React, { Component } from 'react';
import { COUPONTYPES, DiscountType } from '@klikkie/klikkie-schemas/lib/interfaces/Coupon';
import couponActions from '../../actions/couponActions';

class AddCoupon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      couponType: COUPONTYPES.discount,
    };
  }

  // eslint-disable-next-line no-restricted-globals
  isNumeric = number => !isNaN(parseFloat(number)) && isFinite(number);

  postDiscount = async e => {
    try {
      this.setState({ error: false });
      if (
        this.refs.couponName.value === '' ||
        this.refs.discount.value === '' ||
        this.refs.couponRedeemLimit.value === '' ||
        !Object.values(COUPONTYPES).includes(this.state.couponType)
      ) {
        this.setState({ error: true });
        alert('Not everything is filled out');
        return;
      }
      e.preventDefault();

      const limitToPackages = this.refs.limit.value
        .split(',')
        .filter(value => this.isNumeric(value))
        .map(value => Number(value));

      // replace I - 1 and O - 0
      const sanitizedName = this.refs.couponName.value.replace(/I/g, '1').replace(/O/g, '0');

      let sanitizedValue = this.refs.discount.value;
      if (this.state.discountType === DiscountType.Percent) {
        [sanitizedValue] = sanitizedValue.split('.');
        sanitizedValue = Number.parseInt(sanitizedValue, 10);
        if (sanitizedValue > 100) {
          sanitizedValue = 100;
        } else if (sanitizedValue < 0) {
          sanitizedValue = 0;
        }
      } else {
        // XXX: remove decimal separator from discount value due to rounding errors that can occur when multiplying dollar value to get cent value
        sanitizedValue = sanitizedValue.replace(/[^0-9]/g, '');
        sanitizedValue = Number.parseInt(sanitizedValue, 10);
      }

      const body = {
        name: sanitizedName,
        couponType: this.state.couponType,
        discount: sanitizedValue,
        discountType: this.state.discountType,
        limitToPackages,
        redeemLimit: Number(this.refs.couponRedeemLimit.value),
      };
      const { couponCode } = (await couponActions.createCoupon(body))[0];
      console.info(body, couponCode);
      this.props.save(couponCode);
    } catch (error) {
      alert(error);
    }
  };

  clearHandler = e => {
    e.preventDefault();
  };

  onCouponTypeChanged = event => {
    this.setState({
      couponType: event.currentTarget.value,
    });
  };

  onDiscountTypeChanged = event => {
    this.setState({
      discountType: event.currentTarget.value,
    });
  };

  render() {
    // name, discountAmount, packageLimit, redeemLimit
    return (
      <div className="coupon-form">
        {this.props.couponData && <h2>Update Coupon</h2>}
        {!this.props.couponData && <h2>Create new Coupon</h2>}
        <form>
          <fieldset>
            <label htmlFor="name">
              Name *
              <select ref="couponName" name="name" id="couponName" required="required">
                <option value="code">CODE (CS)</option>
                <option value="dogfood">DOGFOOD</option>
              </select>
            </label>
          </fieldset>
          <fieldset>
            <span>Coupon type *</span>
            <label htmlFor="couponType_discount">
              <input
                type="radio"
                id="couponType_discount"
                name="couponType"
                value={COUPONTYPES.discount}
                checked={this.state.couponType === COUPONTYPES.discount}
                onChange={this.onCouponTypeChanged}
              />
              Discount
            </label>
            <label htmlFor="couponType_voucher">
              <input
                type="radio"
                id="couponType_voucher"
                name="couponType"
                value={COUPONTYPES.voucher}
                checked={this.state.couponType === COUPONTYPES.voucher}
                onChange={this.onCouponTypeChanged}
              />
              Voucher
            </label>
            <p>Choose a coupon type.</p>
          </fieldset>
          <fieldset>
            <span>Discount type *</span>
            <label htmlFor="discountType_fixed">
              <input
                type="radio"
                id="discountType_fixed"
                name="discountType"
                value={DiscountType.Fixed}
                checked={this.state.discountType === DiscountType.Fixed}
                onChange={this.onDiscountTypeChanged}
              />
              Fixed
            </label>
            <label htmlFor="discountType_percent">
              <input
                type="radio"
                id="discountType_percent"
                name="discountType"
                value={DiscountType.Percent}
                checked={this.state.discountType === DiscountType.Percent}
                onChange={this.onDiscountTypeChanged}
              />
              Percent
            </label>
            <p>Choose a coupon type.</p>
          </fieldset>
          <fieldset>
            <label htmlFor="discount">
              Discount *
              <input ref="discount" type="number" name="discount" id="couponDiscount" required="required" />
              <p>
                {this.state.discountType === DiscountType.Fixed
                  ? 'Add a discount in euro: 5,00 is €5,00 and 7,95 is €7,95'
                  : 'Add a discount in percent: 10 is 10% and 50.5 is 50% (no half-percents for you!)'}
              </p>
            </label>
          </fieldset>
          <fieldset>
            <label htmlFor="limit">
              Redeem Limit *
              <input
                ref="couponRedeemLimit"
                type="text"
                name="couponRedeemLimit"
                id="couponRedeemLimit"
                required="required"
                defaultValue="1"
              />
              <p>How many times may this coupon be used</p>
            </label>
          </fieldset>
          <fieldset>
            <label htmlFor="limit">
              Package Limit
              <input ref="limit" type="text" name="limit" id="couponLimit" required="required" />
              <p>Add a limit based on productPackageId: 2,3,4,5</p>
            </label>
          </fieldset>

          {this.state.error && <p className="error">fill al inputs</p>}
          <button type="button" className="button" onClick={this.postDiscount.bind(this)} id="couponButton">
            Save coupon
          </button>
        </form>
      </div>
    );
  }
}
export default AddCoupon;
