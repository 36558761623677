import React from 'react';
import SubscriptionEditor from 'components/helpers/SubscriptionEditor';
import ModalContainer from 'components/helpers/ModalContainer';
import subscriptionActions from 'actions/subscriptionActions';
import orderActions from 'actions/orderActions';

/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable jsx-a11y/aria-role */

class SubscriptionEditButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      subscription: {},
    };
  }

  loadSubscription = async () => {
    try {
      const { userId } = this.props;
      const [subscription, orders] = await Promise.all([
        subscriptionActions.getSubscription(userId),
        orderActions.getPictureOrders(userId),
      ]);

      this.setState({ subscription, orders });
    } catch (error) {
      console.error(error);
    }
  };

  showEditSubscriptionModal = async () => {
    await this.loadSubscription();
    this.modal.showModal();
  };

  editSubscriptionHandler = async () => {
    const { userId, onChange } = this.props;
    await subscriptionActions.updateSubscription(userId, this.state.subscription);
    onChange();
  };

  handleSubscriptionUpdate = subscription => {
    this.setState({ subscription });
  };

  render() {
    const { subscription, orders } = this.state;

    return (
      <span className="fieldEditorContainer">
        <button type="button" className="button" onClick={this.showEditSubscriptionModal}>
          Edit Subscription
        </button>
        <ModalContainer
          ref={instance => {
            this.modal = instance;
          }}
          title="Change subscription"
          handler={this.editSubscriptionHandler}
        >
          <SubscriptionEditor subscription={subscription} orders={orders} onChange={this.handleSubscriptionUpdate} />
        </ModalContainer>
      </span>
    );
  }
}

export default SubscriptionEditButton;
