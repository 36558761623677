import React, { Component } from 'react';
import DataGrid from 'components/forms/DataGrid';
import productPackageActions from 'actions/productPackageActions';
import { CentimalToDecimal, TagFormatter } from 'components/helpers/formatters';
import { config, consoleUrl } from 'config/base';

class Products extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        key: 'openDb',
        width: 60,
      },
      {
        key: 'productPackageId',
        name: 'SKU',
        width: 70,
      },
      {
        key: 'title-en',
        name: 'Title',
        width: 200,
        resizable: true,
        sortable: true,
        filterable: true,
      },
      {
        key: 'type',
        name: 'type',
        width: 100,
        resizable: true,
        sortable: true,
        filterable: true,
        formatter: TagFormatter,
      },
      {
        key: 'variant',
        name: 'variant',
        width: 150,
        resizable: true,
        sortable: true,
        filterable: true,
      },
      {
        key: 'active',
        name: 'active',
        width: 75,
        resizable: true,
        sortable: true,
        filterable: true,
        formatter: TagFormatter,
      },
      {
        key: 'text-en',
        name: 'text-en',
        width: 150,
        resizable: true,
        sortable: true,
        filterable: true,
      },
      {
        key: 'textShort-en',
        name: 'textShort-en',
        width: 150,
        resizable: true,
        sortable: true,
        filterable: true,
      },
      {
        key: 'description-en',
        name: 'description-en',
        width: 150,
        resizable: true,
        sortable: true,
        filterable: true,
      },
      {
        key: 'price-GB',
        name: 'price-GB',
        width: 70,
        resizable: true,
        sortable: true,
        filterable: true,
        formatter: CentimalToDecimal,
      },
      {
        key: 'price-NL',
        name: 'price-NL',
        width: 70,
        resizable: true,
        sortable: true,
        filterable: true,
        formatter: CentimalToDecimal,
      },
      {
        key: 'shippingCost-BE',
        name: 'shippingCost-BE',
        width: 70,
        resizable: true,
        sortable: true,
        filterable: true,
        formatter: CentimalToDecimal,
      },
      {
        key: 'shippingCost-GB',
        name: 'shippingCost-GB',
        width: 70,
        resizable: true,
        sortable: true,
        filterable: true,
        formatter: CentimalToDecimal,
      },
      {
        key: 'shippingCost-NL',
        name: 'shippingCost-NL',
        width: 70,
        resizable: true,
        sortable: true,
        filterable: true,
        formatter: CentimalToDecimal,
      },
      {
        key: 'priceMonthly-GB',
        name: 'priceMonthly-GB',
        width: 70,
        resizable: true,
        sortable: true,
        filterable: true,
        formatter: CentimalToDecimal,
      },
      {
        key: 'priceMonthly-NL',
        name: 'priceMonthly-NL',
        width: 70,
        resizable: true,
        sortable: true,
        filterable: true,
        formatter: CentimalToDecimal,
      },
      {
        key: 'delivery-GB',
        name: 'delivery-GB',
        width: 70,
        resizable: true,
        sortable: true,
        filterable: true,
        formatter: CentimalToDecimal,
      },
      {
        key: 'delivery-NL',
        name: 'delivery-NL',
        width: 70,
        resizable: true,
        sortable: true,
        filterable: true,
        formatter: CentimalToDecimal,
      },
    ];
    this.state = {
      rows: undefined,
    };
  }

  componentDidMount = async () => {
    try {
      const rows = await productPackageActions.getPackages();
      const toString = d => {
        const o = d;
        Object.keys(o).forEach(k => {
          if (typeof o[k] === 'object') {
            return toString(o[k]);
          }

          o[k.replace('.', '-')] = `${o[k]}`;
          return true;
        });

        return o;
      };
      const rowStrings = rows.map(toString);
      this.setState({
        rows: rowStrings,
      });
    } catch (error) {
      console.error(error);
      this.props.toastOpen(error, 'error');
    }
  };

  getCellActions = (column, row) => {
    const openDb = [
      {
        icon: 'icon ion-md-more',
        actions: [
          {
            text: 'Edit in Database',
            callback: () =>
              window.open(
                `${consoleUrl}database/${config.projectId}/data/~2FproductPackages_I18n~2F/${row.productPackageId}`,
              ),
          },
        ],
      },
    ];
    const cellActions = {
      openDb,
    };

    return cellActions[column.key];
  };

  render() {
    return (
      <DataGrid
        name={this.props.name}
        actions={this.getCellActions}
        columns={this.columns}
        enableCellSelect
        rows={this.state.rows}
      />
    );
  }
}
export default Products;
