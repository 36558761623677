/* eslint-disable react/no-string-refs */
import React, { Component } from 'react';
import couponActions from 'actions/couponActions';
import { COUPONTYPES, DiscountType } from '@klikkie/klikkie-schemas/lib/interfaces/Coupon';

class AddDiscount extends Component {
  constructor(props) {
    super(props);
    this.options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      filename: 'klikkie-couponCodes',
      showLabels: true,
      showTitle: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    this.state = {
      error: '',
      couponType: COUPONTYPES.discount,
    };
  }

  // eslint-disable-next-line no-restricted-globals
  isNumeric = number => !isNaN(parseFloat(number)) && isFinite(number);

  createDiscount = async e => {
    try {
      e.preventDefault();
      if (
        this.refs.code.value === '' ||
        this.refs.redeemLimit.value === '' ||
        this.refs.discountId.value === '' ||
        this.refs.discount.value === '' ||
        !Object.values(COUPONTYPES).includes(this.state.couponType)
      ) {
        this.setState({ error: true });
        alert('Not everything is filled out');
        return;
      }

      const limitToPackages = this.refs.limit.value
        .split(',')
        .filter(value => this.isNumeric(value))
        .map(value => Number(value));

      let sanitizedValue = this.refs.discount.value;
      if (this.state.discountType === DiscountType.Percent) {
        [sanitizedValue] = sanitizedValue.split('.');
        sanitizedValue = Number.parseInt(sanitizedValue, 10);
        if (sanitizedValue > 100) {
          sanitizedValue = 100;
        } else if (sanitizedValue < 0) {
          sanitizedValue = 0;
        }
      } else {
        // XXX: remove decimal separator from discount value due to rounding errors that can occur when multiplying dollar value to get cent value
        sanitizedValue = sanitizedValue.replace(/[^0-9]/g, '');
        sanitizedValue = Number.parseInt(sanitizedValue, 10);
      }

      const coupon = {
        couponCode: this.refs.code.value,
        redeemLimit: Number(this.refs.redeemLimit.value, 10),
        name: this.refs.discountId.value,
        couponType: this.state.couponType,
        discount: sanitizedValue,
        discountType: this.state.discountType,
        limitToPackages,
      };

      await couponActions.createCoupon(coupon);

      this.props.save(coupon.couponCode);
    } catch (error) {
      alert(JSON.stringify(error));
      console.error(error);
    }
  };

  onCouponTypeChanged = event => {
    this.setState({
      couponType: event.currentTarget.value,
    });
  };

  onDiscountTypeChanged = event => {
    this.setState({
      discountType: event.currentTarget.value,
    });
  };

  render() {
    return (
      <div className="coupon-form">
        <h2>Add Discount Code</h2>
        <p>This form will add a multi-use discount code</p>
        <form>
          <fieldset>
            <label htmlFor="code">
              Code
              <input ref="code" type="text" name="code" id="discountcode" style={{ textTransform: 'uppercase' }} />
              <p>The discount code you want to use. Using a &quot;-&quot; in between is recommended.</p>
            </label>
          </fieldset>
          <fieldset>
            <label htmlFor="redeemLimit">
              Redeem Limit
              <input ref="redeemLimit" type="number" name="redeemLimit" id="redeemLimit" />
              <p>The maximum times this code can be used </p>
            </label>
          </fieldset>
          <fieldset>
            <label htmlFor="discountId">
              Name
              <input
                ref="discountId"
                type="text"
                name="discountId"
                id="discountId"
                style={{ textTransform: 'uppercase' }}
              />
              <p>Name the coupons after a company or buyer</p>
            </label>
          </fieldset>
          <fieldset>
            <span>Coupon type *</span>
            <label htmlFor="couponType_discount">
              <input
                type="radio"
                id="couponType_discount"
                name="couponType"
                value={COUPONTYPES.discount}
                checked={this.state.couponType === COUPONTYPES.discount}
                onChange={this.onCouponTypeChanged}
              />
              Discount
            </label>
            <label htmlFor="couponType_voucher">
              <input
                type="radio"
                id="couponType_voucher"
                name="couponType"
                value={COUPONTYPES.voucher}
                checked={this.state.couponType === COUPONTYPES.voucher}
                onChange={this.onCouponTypeChanged}
              />
              Voucher
            </label>
            <p>Choice a coupon type.</p>
          </fieldset>
          <fieldset>
            <span>Discount type *</span>
            <label htmlFor="discountType_fixed">
              <input
                type="radio"
                id="discountType_fixed"
                name="discountType"
                value={DiscountType.Fixed}
                checked={this.state.discountType === DiscountType.Fixed}
                onChange={this.onDiscountTypeChanged}
              />
              Fixed
            </label>
            <label htmlFor="discountType_percent">
              <input
                type="radio"
                id="discountType_percent"
                name="discountType"
                value={DiscountType.Percent}
                checked={this.state.discountType === DiscountType.Percent}
                onChange={this.onDiscountTypeChanged}
              />
              Percent
            </label>
            <p>Choose a coupon type.</p>
          </fieldset>
          <fieldset>
            <label htmlFor="discount">
              Discount
              <input ref="discount" type="number" name="discount" placeholder="7,95" id="discountDiscount" />
              <p>
                {this.state.discountType === DiscountType.Fixed
                  ? 'Add a discount in euro: 5,00 is €5,00 and 7,95 is €7,95'
                  : 'Add a discount in percent: 10 is 10% and 50.5 is 50% (no half-percents for you!)'}
              </p>
            </label>
          </fieldset>
          <fieldset>
            <label htmlFor="limit">
              Package Limit
              <input ref="limit" type="text" name="limit" placeholder="2,3,4,5" id="discountLimit" />
              <p>Add a limit based on productPackageId</p>
            </label>
          </fieldset>
          {this.state.error && <p className="error">fill al inputs</p>}
          <button type="button" className="button" onClick={this.createDiscount.bind(this)} id="discountButton">
            Save
          </button>
        </form>
      </div>
    );
  }
}

export default AddDiscount;
