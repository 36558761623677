import React from 'react';

const GetLinkToOrder = props => {
  return (
    <a className="id-link" href={`/order/${props.oid}`} rel="noopener noreferrer" target="_blank">
      {props.oid}
    </a>
  );
};

export default GetLinkToOrder;
