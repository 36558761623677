import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class OrderToPrintOldRedirect extends Component {
  componentDidMount = async () => {
    try {
      const { orderId } = this.props.match.params;

      this.props.history.replace(`/fulfillment/${orderId}`);
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    return <span>Redirecting to Order...</span>;
  }
}
export default withRouter(OrderToPrintOldRedirect);
