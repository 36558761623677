import React from 'react';
import { storagePictures } from 'config/base';

class StorageImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: '',
    };
  }

  componentDidMount = () => this.loadData();

  loadData = async () => {
    try {
      const { value } = this.props;
      if (value === '') return;
      const url = await storagePictures.ref(value).getDownloadURL();
      this.setState({ url });
    } catch (error) {
      console.error(error);
    }
  };

  componentDidUpdate = prevProps => {
    if (this.props.value && prevProps.value === this.props.value) return;
    this.loadData();
  };

  render = () => {
    const { url } = this.state;
    const { height, boxShadow, styleObj } = this.props;
    const style = styleObj || { height: height || '50px', margin: '5px', boxShadow: boxShadow || 'none' };

    return (
      <span>
        {url && (
          <a href={url} target="_blank" rel="noopener noreferrer">
            <img alt={url} style={style} src={url} />
          </a>
        )}
      </span>
    );
  };
}
export default StorageImage;
