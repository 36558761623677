import React from 'react';
import DetailOverview from './DetailOverview';

class DetailModal extends React.Component {
  constructor() {
    super();
    this.escFunction = this.escFunction.bind(this);
    this.state = {
      startDate: undefined,
      endDate: undefined,
      submitDate: undefined,
      uid: '',
      oid: '',
      loaded: false,
      visibleContent: 1, // 2
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false);
  }

  componentWillUnmount = () => {
    document.removeEventListener('keydown', this.escFunction, false);
    const blankState = {};
    Object.keys(this.state).forEach(stateKey => {
      blankState[stateKey] = undefined;
    });
    this.setState(blankState);
  };

  escFunction(event) {
    if (event.keyCode !== 27) return;

    this.props.close();
  }

  render() {
    return (
      <div className="detail-wrapper true ">
        <div className="detail-container">
          <button type="button" className="close" onClick={this.props.close}>
            &times;
          </button>
          <DetailOverview
            uid={this.props.uid}
            user={this.props.user}
            oid={this.props.oid}
            tab={this.props.tab}
            toastOpen={this.props.toastOpen}
          />
        </div>
      </div>
    );
  }
}
export default DetailModal;
