import React, { Component } from 'react';
import DataGrid from 'components/forms/DataGrid';
import {
  AddressIdSelector,
  GenericComment,
  Flag,
  FulfillmentOrderIdFormatter,
  ProductPackage,
  ConvertTimestamp2DateTime,
  TagFormatter,
} from 'components/helpers/formatters';
import DataUtils from 'components/helpers/utils/data';
import fulfillmentActions from 'actions/fulfillmentActions';
import orderActions from 'actions/orderActions';
import Klikkie from '_klikkie';
import { MdDelete } from 'react-icons/md';
import {
  ProductQueuePrintCouponLabelButton,
  GenericActionWithConfirmButton,
  GenericButtonSplit,
  GenericStatusSelect,
} from 'components/buttons';

const klikkie = new Klikkie();

const {
  Filters: {
    NumericFilter,
    // AutoCompleteFilter,
    MultiSelectFilter,
    // SingleSelectFilter
  },
} = require('react-data-grid-addons');

class DetailFulfillmentTab extends Component {
  constructor(props) {
    super(props);
    this.dataGrid = React.createRef();
    this.columns = [
      {
        key: 'changeStatus',
        width: 60,
      },
      {
        key: 'id',
        name: 'Id',
        width: 180,
        sortable: true,
        filterable: true,
        resizable: true,
        filterRenderer: MultiSelectFilter,
        formatter: FulfillmentOrderIdFormatter,
      },
      {
        key: 'createdAt',
        name: 'Added to Queue',
        filterable: true,
        resizable: true,
        sortable: true,
        width: 150,
        formatter: ConvertTimestamp2DateTime,
      },
      {
        key: 'fulfilledAt',
        name: 'Fulfilled',
        filterable: true,
        resizable: true,
        sortable: true,
        width: 150,
        formatter: ConvertTimestamp2DateTime,
      },

      {
        key: 'status',
        name: 'status',
        filterable: true,
        width: 100,
        filterRenderer: MultiSelectFilter,
      },
      {
        key: 'type',
        name: 'type',
        filterable: true,
        width: 100,
        filterRenderer: MultiSelectFilter,
        formatter: TagFormatter,
      },
      {
        key: 'packageId',
        name: 'Package',
        filterable: true,
        resizable: true,
        filterRenderer: NumericFilter,
        width: 140,
        getRowMetaData: row => row,
        formatter: ProductPackage,
      },
      {
        key: 'units',
        name: 'Units',
        filterable: true,
        resizable: true,
        filterRenderer: NumericFilter,
        width: 70,
      },
      {
        key: 'message',
        name: 'Message',
        filterable: true,
        resizable: true,
        width: 160,
      },
      {
        key: 'countryCode',
        name: 'Country',
        filterable: true,
        resizable: true,
        filterRenderer: MultiSelectFilter,
        width: 60,
        formatter: Flag,
      },
      {
        key: 'addressId',
        name: 'Address',
        width: 180,
        sortable: true,
        filterable: true,
        resizable: true,
        getRowMetaData: row => {
          return { addresses: this.state.addresses, changeHandler: this.changeAddress, ...row };
        },
        formatter: AddressIdSelector,
      },
      {
        key: 'comment',
        name: 'Comment',
        filterable: true,
        resizable: true,
        width: 300,
        getRowMetaData: row => row,
        formatter: (
          <GenericComment
            changeHandler={(id, comment) => fulfillmentActions.updateQueue(id, { comment })}
            toastOpen={(message, level) => this.props.toastOpen(message, level)}
          />
        ),
      },
    ];
    this.state = {
      rows: undefined,
    };
  }

  componentDidMount = () => this.loadData();

  loadData = async () => {
    try {
      this.setState({
        rows: undefined,
      });
      this.getAddresses();
      const orders = await fulfillmentActions.getQueueUser(this.props.uid);
      const rows = this.formatOrders(orders);
      const length = rows ? rows.length : 0;
      this.props.count(length);
      this.setState({
        rows,
      });
    } catch (error) {
      console.error(error);
      this.props.toastOpen(error, 'error');
    }
  };

  formatOrders = orders => {
    if (!orders) return [];
    const ordersArr = Object.keys(orders).map(key => {
      const order = orders[key];
      order.date = orderActions.compileDate(order.createdAt);
      order.paidAt = orderActions.compileDateAndTimeNoLineBreak(order.paidAt);
      order.addressId = order.address && order.address.addressId;
      if (order.address) {
        order.city = order.address.city;
        order.countryCode = order.address.countryCode;
        order.fullname = order.address.fullname;
      }
      return order;
    });
    ordersArr.sort(DataUtils.dynamicSort('-createdAt'));

    return ordersArr;
  };

  rowDeleteById = id => {
    const { rows } = this.state;

    const updatedRows = rows.filter(item => item.id !== id);

    this.setState({
      rows: updatedRows,
    });
  };

  changeStatusOfSelection = async status => {
    try {
      return this.dataGrid.current.actionOnSelection(order => this.changeStatus(order, status));
    } catch (error) {
      console.error(error);
      return this.props.toastOpen(error, 'error');
    }
  };

  changeStatus = async (order, status) => {
    try {
      const { id } = order;
      const runSideEffects = status !== 'pending';

      await fulfillmentActions.updateQueue(id, { status, runSideEffects });
      // resend to supplier
      if (status === 'pending') {
        await fulfillmentActions.sendOrderToSupplier(id);
      }
      this.loadData();
      this.props.toastOpen(`Order ${id} is set to ${status}`);
    } catch (error) {
      console.error(error);
      this.props.toastOpen(error, 'error');
    }
  };

  changeAddress = async (orderId, addressId) => {
    try {
      const address = this.state.addresses.filter(a => a.addressId === addressId)[0];

      await fulfillmentActions.updateQueue(orderId, { address });
      return this.props.toastOpen('Address updated');
    } catch (error) {
      console.error(error);
      return this.props.toastOpen(error, 'error');
    }
  };

  getAddresses = async () => {
    const { uid: userId } = this.props;
    const addresses = await klikkie
      .users(userId)
      .addresses()
      .get();
    this.setState({ addresses });
  };

  getCellActions = (column, row) => {
    const changeStatus = [
      {
        icon: 'icon ion-md-more',
        actions: [
          {
            text: 'Set as pending',
            callback: () => this.changeStatus(row, 'pending'),
          },
          {
            text: 'Set as rejected',
            callback: () => this.changeStatus(row, 'rejected'),
          },
          {
            text: 'Set as fulfilled',
            callback: () => this.changeStatus(row, 'fulfilled'),
          },
        ],
      },
    ];

    const cellActions = {
      changeStatus,
    };

    return cellActions[column.key];
  };

  render() {
    return (
      <DataGrid
        ref={this.dataGrid}
        columns={this.columns}
        actions={this.getCellActions}
        toastOpen={this.props.toastOpen}
        rows={this.state.rows}
        print={false}
        csv={false}
        getSelection={selection => this.setState({ selection })}
        extraTools={
          <span>
            <button type="button" className="button" onClick={this.loadData}>
              Reload Data
            </button>
            {this.props.status !== 'fulfilled' && (
              <button type="button" className="button" onClick={this.changeStatusOfSelection}>
                Fulfill Selection
              </button>
            )}
            <ProductQueuePrintCouponLabelButton
              labelType="GIFT"
              data={this.state.selection}
              toastOpen={this.props.toastOpen}
            />
            <GenericButtonSplit />
            <GenericStatusSelect exclude={this.props.status} changeStatus={this.changeStatusOfSelection} />
            <GenericActionWithConfirmButton
              actionName="Remove"
              small
              icon={<MdDelete />}
              action={i => fulfillmentActions.removeFromQueue(i.id).then(() => this.rowDeleteById(i.id))}
              data={this.state.selection}
            />
          </span>
        }
      />
    );
  }
}

export default DetailFulfillmentTab;
