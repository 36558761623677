import React from 'react';
import ContentEditable from 'react-contenteditable';

class GenericComment extends React.Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.state = {
      value: '',
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate = prevProps => {
    if (
      prevProps.dependentValues.orderId === this.props.dependentValues.orderId &&
      prevProps.value === this.props.value
    )
      return;
    this.loadData();
  };

  componentDidMount = () => this.loadData();

  loadData = () =>
    this.setState({
      value: this.props.value || '',
      orderId: this.props.idField === 'id' ? this.props.dependentValues.id : this.props.dependentValues.orderId,
    });

  handleChange = event => this.setState({ value: event.target.value });

  onBlur = () => {
    clearTimeout(this.timer);
    this.props.changeHandler(this.state.orderId, this.state.value);
    this.timer = setTimeout(() => this.props.toastOpen('Comment saved'), 1000);
  };

  render() {
    return (
      <ContentEditable
        className="content-editable"
        placeholder="Add a comment"
        html={this.state.value}
        disabled={false}
        onChange={this.handleChange}
        onBlur={this.onBlur}
      />
    );
  }
}

export default GenericComment;
