/* eslint-disable no-underscore-dangle */
import { apiBaseUrl } from 'config/base';
import api from 'components/helpers/utils/api';
import DataUtils from 'components/helpers/utils/data';

const BASE_URL = `${apiBaseUrl}/photobooks/v2`;

export default {
  getLatestPages() {
    return api.fetchAsAdmin('GET', `${BASE_URL}/bq/last-created-pages`);
  },
  async getByUserId(userId) {
    const stories = await api.fetchAsAdmin('GET', `${BASE_URL}/user/${userId}`);
    const storiesFlattened = Object.values(stories).map(story => {
      const data = story;
      if (!story) return {};
      data.createdAt = data.created?.date?._seconds ? data.created.date._seconds * 1000 : '';
      data.updatedAt = data.updated?.date?._seconds ? data.updated.date._seconds * 1000 : '';
      data.spreads = data.order.length + 1;

      return data;
    });

    storiesFlattened.sort(DataUtils.dynamicSort('createdAt'));
    return storiesFlattened;
  },
  async getStoryCover(storyId) {
    const data = await api.fetchAsAdmin('GET', `${BASE_URL}/${storyId}`);

    const { pdfCover } = data[0];
    delete pdfCover.updated;
    delete pdfCover.created;

    return pdfCover;
  },
  async getStory(storyId) {
    const [story] = await api.fetchAsAdmin('GET', `${BASE_URL}/${storyId}`);
    return story;
  },
  getStoryPage(storyId, pageId) {
    return api.fetchAsAdmin('GET', `${BASE_URL}/${storyId}/${pageId}`);
  },
  updateStoryPage(storyId, pageId, page) {
    return api.fetchAsAdmin('PATCH', `${BASE_URL}/${storyId}/${pageId}`, page);
  },
  updateStory(storyId, story) {
    return api.fetchAsAdmin('PATCH', `${BASE_URL}/${storyId}/`, story);
  },
  copyStory(storyId) {
    return api.fetchAsAdmin('PUT', `${BASE_URL}/copy/${storyId}/`);
  },
  deleteStory(storyId) {
    return api.fetchAsAdmin('DELETE', `${BASE_URL}/${storyId}/`);
  },
};
