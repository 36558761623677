import React, { Component } from 'react';
import userActions from 'actions/userActions';
import Klikkie from '_klikkie';
import Modal from 'components/helpers/Modal';

const klikkie = new Klikkie();

class AuthEditPasswordButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  componentDidMount = async () => {
    try {
      const show = await userActions.hasRights();

      this.setState({ show });
    } catch (error) {
      console.error(error);
    }
  };

  resetPassword = async () => {
    try {
      const { uid: userId } = this.props;
      const password = await klikkie.users(userId).resetPassword();
      navigator.clipboard.writeText(password);
      this.props.toastOpen(`New password "${password}" is copied to the clipboard.`);
    } catch (error) {
      console.error(error);
      this.props.toastOpen(error);
    }
  };

  render() {
    return (
      <div className="resetPasswordButton" style={{ display: 'inline-block' }}>
        <Modal
          ref={instance => {
            this.modal = instance;
          }}
          handler={this.resetPassword}
          title="Reset password?"
          message={`for user: ${this.props.uid}`}
        />
        {this.state.show && (
          <button type="button" className="button" onClick={this.modal.showModal}>
            Reset Password{' '}
          </button>
        )}
      </div>
    );
  }
}

export default AuthEditPasswordButton;
