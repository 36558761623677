import React from 'react';
import { GiPeriscope } from 'react-icons/gi';

const TableEmpty = () => {
  const quotes = [
    {
      quote: 'There is nothing either good or bad but thinking makes it so.',
      quotee: 'William Shakespeare',
    },
    {
      quote: 'Do not take life too seriously. You will never get out of it alive.',
      quotee: 'Elbert Hubbard',
    },
    {
      quote: 'Happiness is having a large, loving, caring, close-knit family in another city.',
      quotee: 'George Burns',
    },
    {
      quote: 'Always remember that you are absolutely unique. Just like everyone else.',
      quotee: 'Margaret Mead',
    },
    {
      quote: "One advantage of talking to yourself is that you know at least somebody's listening.",
      quotee: 'Franklin P. Jones',
    },
    {
      quote: "Sometimes something's got to happen before something is going to happen.",
      quotee: 'Johan Cruyff',
    },
    {
      quote: 'Family, all because two people fell in love.',
      quotee: 'Vloer',
    },
    {
      quote: 'Go with the Flo!',
      quotee: 'Flow',
    },
    {
      quote: 'Nee, niet persée',
      quotee: 'MC',
    },
    {
      quote: 'Je hebt mensen en je hebt fietsbellen',
      quotee: 'Arjan',
    },
    {
      quote: 'Gooi het gewoon live',
      quotee: 'Uri',
    },
    {
      quote: `Don't deploy on Friday`,
      quotee: 'Tim',
    },
    {
      quote: 'Well...',
      quotee: 'Anouk',
    },
    {
      quote: 'In terms of time...',
      quotee: 'Jeffrey',
    },
    {
      quote: `That's craaaaaazy`,
      quotee: 'Dylan',
    },
    {
      quote: 'Fijne weekend',
      quotee: 'Pablo',
    },
    {
      quote: 'Noord gestoord! ☠️',
      quotee: 'Collin',
    },
    {
      quote: `I don't need a winter jacket`,
      quotee: 'Simon',
    },
    {
      quote: 'Amaaaaaaazing',
      quotee: 'Anouk',
    },
    {
      quote: 'Amazeballs!',
      quotee: 'Tim',
    },
    {
      quote: `Don't overthink it!`,
      quotee: 'Precious AkA Dierbaar',
    },
    {
      quote: `I'm not sure...`,
      quotee: 'Mahdi',
    },
  ];
  const randomQuote = quotes[Math.floor(Math.random() * quotes.length)];
  return (
    <div
      style={{
        textAlign: 'center',
        color: '#bbb',
        height: '100%',
        backgroundColor: '#eee',
        padding: '100px',
      }}
    >
      <span style={{ fontSize: '90px' }}>
        <GiPeriscope />
      </span>
      <h3>&quot;{randomQuote.quote}&quot;</h3>
      <h4>‒{randomQuote.quotee}</h4>
      <p>P.S. No data found</p>
    </div>
  );
};

export default TableEmpty;
