import React, { Component } from 'react';
import DataGrid from 'components/forms/DataGrid';
import userActions from 'actions/userActions';

const {
  Formatters: { ImageFormatter },
} = require('react-data-grid-addons');

class DetailPaymentProfilesTab extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        key: 'providerLogo',
        name: 'Provider',
        width: 100,
        sortable: true,
        filterable: true,
        resizable: true,
        formatter: ImageFormatter,
      },
      {
        key: 'email',
        name: 'Email',
        width: 200,
        sortable: true,
        filterable: true,
        resizable: true,
      },
      {
        key: 'merchant',
        name: 'Merchant Account',
        width: 200,
        sortable: true,
        filterable: true,
        resizable: true,
      },
      {
        key: 'token',
        name: 'Recurring Token',
        width: 300,
        sortable: true,
        filterable: true,
        resizable: true,
      },
    ];
    this.state = {
      rows: undefined,
    };
  }

  async componentDidMount() {
    try {
      const user = await userActions.getUser(this.props.uid);
      const rows = [user.paymentProfile];
      rows.forEach(row => {
        // eslint-disable-next-line
        row.providerLogo = require(`../../img/${row.provider.toLowerCase()}-logo.png`);
      });
      this.props.count(rows.length);

      this.setState({
        rows,
      });
    } catch (e) {
      console.error(e);
    }
  }

  render() {
    return <DataGrid columns={this.columns} rows={this.state.rows} print={false} />;
  }
}

export default DetailPaymentProfilesTab;
