/* eslint-disable react/no-string-refs */
import React, { Component } from 'react';
import { auth, apiBaseUrl } from 'config/base';
import { COUPONTYPES, DiscountType } from '@klikkie/klikkie-schemas/lib/interfaces/Coupon';

class CouponBatch extends Component {
  constructor(props) {
    super(props);
    this.options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      filename: 'klikkie-couponCodes',
      showLabels: true,
      showTitle: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    this.state = {
      error: '',
      couponType: COUPONTYPES.discount,
    };
  }

  // eslint-disable-next-line no-restricted-globals
  isNumeric = number => !isNaN(parseFloat(number)) && isFinite(number);

  batchCreate = async e => {
    e.preventDefault();

    if (
      this.refs.code.value === '' ||
      this.refs.name.value === '' ||
      this.refs.discount.value === '' ||
      this.refs.discount.value === '' ||
      !Object.values(COUPONTYPES).includes(this.state.couponType)
    ) {
      this.setState({ error: true });
      alert('Not everything is filled out');
      return;
    }

    const limitToPackages = this.refs.limit.value
      .split(',')
      .filter(value => this.isNumeric(value))
      .map(value => Number(value));

    let sanitizedValue = this.refs.discount.value;

    if (this.state.discountType === DiscountType.Percent) {
      [sanitizedValue] = sanitizedValue.split('.');
      sanitizedValue = Number.parseInt(sanitizedValue, 10);
      sanitizedValue = Math.max(0, Math.min(sanitizedValue, 100));
    } else {
      // XXX: remove decimal separator from discount value due to rounding errors that can occur when multiplying dollar value to get cent value
      sanitizedValue = sanitizedValue.replace(/[^0-9]/g, '');
      sanitizedValue = Number.parseInt(sanitizedValue, 10);
    }

    const body = {
      numberOfCoupons: this.refs.code.value,
      name: this.refs.name.value,
      couponType: this.state.couponType,
      discountAmount: sanitizedValue,
      discountType: this.state.discountType,
      limitToPackages,
      expiresAt: this.refs.expiresAt.valueAsNumber,
    };

    try {
      const token = await auth.currentUser.getIdToken();
      const url = `${apiBaseUrl}/coupons/batch`;
      const requestBody = {
        body: JSON.stringify(body),
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(url, requestBody);
      console.info(response);

      if (response.status !== 200) {
        alert(response.statusText);
        return;
      }

      const blob = await response.blob();
      this.props.save(body.name);

      const csvUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = csvUrl;
      link.setAttribute('download', `coupons-${body.name}.csv`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error(error);
    }
  };

  onCouponTypeChanged = event => {
    this.setState({
      couponType: event.currentTarget.value,
    });
  };

  onDiscountTypeChanged = event => {
    this.setState({
      discountType: event.currentTarget.value,
    });
  };

  render() {
    return (
      <div className="coupon-form">
        <h2>Batch create coupons</h2>
        <p>This form will generate the coupon codes</p>
        <form>
          <fieldset>
            <label htmlFor="code">
              Number of coupons*
              <input ref="code" type="number" name="code" id="batchCounponTotal" />
              <p>fill in a number </p>
            </label>
          </fieldset>
          <fieldset>
            <label htmlFor="name">
              Name
              <input ref="name" type="text" name="name" id="batchCouponId" style={{ textTransform: 'uppercase' }} />
              <p>Name the coupons after a company or buyer</p>
            </label>
          </fieldset>
          <fieldset>
            <span>Coupon type *</span>
            <label htmlFor="couponType_discount">
              <input
                type="radio"
                id="couponType_discount"
                name="couponType"
                value={COUPONTYPES.discount}
                checked={this.state.couponType === COUPONTYPES.discount}
                onChange={this.onCouponTypeChanged}
              />
              Discount
            </label>
            <label htmlFor="couponType_voucher">
              <input
                type="radio"
                id="couponType_voucher"
                name="couponType"
                value={COUPONTYPES.voucher}
                checked={this.state.couponType === COUPONTYPES.voucher}
                onChange={this.onCouponTypeChanged}
              />
              Voucher
            </label>
            <p>Choose a coupon type.</p>
          </fieldset>
          <fieldset>
            <span>Discount type *</span>
            <label htmlFor="discountType_fixed">
              <input
                type="radio"
                id="discountType_fixed"
                name="discountType"
                value={DiscountType.Fixed}
                checked={this.state.discountType === DiscountType.Fixed}
                onChange={this.onDiscountTypeChanged}
              />
              Fixed
            </label>
            <label htmlFor="discountType_percent">
              <input
                type="radio"
                id="discountType_percent"
                name="discountType"
                value={DiscountType.Percent}
                checked={this.state.discountType === DiscountType.Percent}
                onChange={this.onDiscountTypeChanged}
              />
              Percent
            </label>
            <p>Choose a coupon type.</p>
          </fieldset>
          <fieldset>
            <label htmlFor="discount">
              Discount
              <input ref="discount" type="number" name="discount" placeholder="7,95" id="batchCouponDiscount" />
              <p>
                {this.state.discountType === DiscountType.Fixed
                  ? 'Add a discount in euro: 5,00 is €5,00 and 7,95 is €7,95'
                  : 'Add a discount in percent: 10 is 10% and 50.5 is 50% (no half-percents for you!)'}
              </p>
            </label>
          </fieldset>
          <fieldset>
            <label htmlFor="limit">
              Package Limit
              <input ref="limit" type="text" name="limit" placeholder="2,3,4,5" id="batchCouponLimit" />
              <p>Add a limit based on productPackageId</p>
            </label>
          </fieldset>
          <fieldset>
            <label htmlFor="expiresAt">
              Expires At
              <input
                ref="expiresAt"
                type="date"
                name="expiresAt"
                defaultValue={new Date(new Date().setMonth(new Date().getMonth() + 18)).toISOString().split('T')[0]}
                id="batchCouponLimit"
              />
              <p>Add an expiration date</p>
            </label>
          </fieldset>
          {this.state.error && <p className="error">fill al inputs</p>}
          <button type="button" className="button" onClick={this.batchCreate.bind(this)} id="batchCouponButton">
            Create batch
          </button>
        </form>
      </div>
    );
  }
}

export default CouponBatch;
