import React, { useState, useEffect } from 'react';
import './_FulfillmentContentPanelAlbum.scss';
import TableEmpty from 'components/helpers/TableEmpty';
import { GenericCopyButton } from 'components/buttons';
import { printApiOrderUrl, storagePictures } from 'config/base';

const FulfillmentContentPanelCard = props => {
  const [editMode, setEditMode] = useState(true);
  const [link, setLink] = useState(null);
  const [story] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        const publicLink = await storagePictures.ref(props.link).getDownloadURL();
        setLink(publicLink);
      } catch (error) {
        console.error('Failed to load PDF', error);
      }
    };
    loadData();
  }, []);

  return (
    <div className="FulfillmentContentPanelAlbum">
      <div className="taskbar">
        <span className="header">POST CARD</span>
        {story?.id && <GenericCopyButton title="Copy story id" text="Story id" value={story.id} />}
        <button className="button" type="button" onClick={() => setEditMode(!editMode)}>
          {editMode ? 'Edit V̶i̶e̶w̶' : 'E̶d̶i̶t̶ View'}
        </button>
        <a className="button" href={`${printApiOrderUrl}/${props.printId}`} target="_blank" rel="noopener noreferrer">
          Dashboard
        </a>
      </div>
      {!editMode && link && (
        <div className="preview">
          <iframe src={`${link}#view=Fit`} title="title" />
        </div>
      )}
      {!editMode && !link && <TableEmpty />}
      {editMode && (
        <div className="editor">
          <div className="pages">
            <div>
              <h1>Click “Edit View” to see the rendered files.</h1>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FulfillmentContentPanelCard;
