import React from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import Modal from 'components/helpers/Modal';
import Message from 'components/helpers/Message';

import userActions from 'actions/userActions';
import {
  pauseInTheFuture,
  activateInTheFuture,
  churnInTheFuture,
  deleteAllPauseEvents,
  deleteAllActivateEvents,
  deleteAllChurnEvents,
} from 'actions/eventSchedulerOperations';

class SubscriptionScheduleChangeButton extends React.Component {
  constructor() {
    super();
    this.state = {
      show: false,
      message: '',
      event: 'paused',
      date: moment.now(),
    };
  }

  componentDidMount() {
    userActions
      .hasRights()
      .then(value => {
        this.setState({ show: value });
      })
      .catch();
  }

  scheduleEvent = () => {
    const { userId } = this.props;
    const { event, date } = this.state;

    switch (event) {
      case 'paused': {
        return pauseInTheFuture(userId, date);
      }
      case 'churned': {
        return churnInTheFuture(userId, date);
      }
      case 'activated': {
        return activateInTheFuture(userId, date);
      }
      default:
        break;
    }
    return true;
  };

  scheduleEventAndShowAlert = () => {
    const { userId, onChange } = this.props;
    const { event, date } = this.state;

    const formattedDate = moment(date).format('YYYY-MM-DD');

    this.scheduleEvent()
      .then(() => {
        this.setState({ message: `Subscription of user: ${userId} will be ${event} on ${formattedDate}` });
        this.message.showModal();
        onChange();
      })
      .catch(error => {
        this.setState({ message: `Error:${error}` });
        this.message.showModal();
      });
  };

  handleEventTypeChange = event => {
    this.setState({ event: event.target.value });
  };

  handleDateChange = date => {
    this.setState({
      date,
    });
  };

  handleRemovePause = () => {
    const { userId, onChange } = this.props;

    deleteAllPauseEvents(userId)
      .then(() => {
        this.setState({ message: `Subscription of user: ${userId} will not be paused in the future` });
        this.message.showModal();
        onChange();
      })
      .catch(error => {
        this.setState({ message: `Error: ${error}` });
        this.message.showModal();
      });
  };

  handleRemoveChurn = () => {
    const { userId, onChange } = this.props;

    deleteAllChurnEvents(userId)
      .then(() => {
        this.setState({ message: `User: ${userId} will not be churned in the future` });
        this.message.showModal();
        onChange();
      })
      .catch(error => {
        this.setState({ message: `Error: ${error}` });
        this.message.showModal();
      });
  };

  handleRemoveReactivate = () => {
    const { userId, onChange } = this.props;

    deleteAllActivateEvents(userId)
      .then(() => {
        this.setState({ message: `User: ${userId} will not be re-activated in the future` });
        this.message.showModal();
        onChange();
      })
      .catch(error => {
        this.setState({ message: `Error: ${error}` });
        this.message.showModal();
      });
  };

  render() {
    const { userId, isPausedInTheFuture, isChurnedInTheFuture, isReactivatedInTheFuture } = this.props;
    const { event, date } = this.state;

    const formattedDate = moment(date).format('YYYY-MM-DD');

    return (
      <span>
        {this.state.show && (
          <>
            <span className="moveOrderButton">
              <select value={event} onChange={this.handleEventTypeChange}>
                {/* <option value="churned">Churn</option> */}
                <option value="paused">Pause</option>
                <option value="activated">Unpause</option>
              </select>
              <DatePicker selected={date} onChange={this.handleDateChange} />
              <button type="button" className="button" onClick={this.modal.showModal}>
                In the future
              </button>
            </span>
            {isPausedInTheFuture && isPausedInTheFuture !== 'no date' && (
              <button type="button" className="button" onClick={this.modalPause.showModal}>
                🗑 Remove Pause in the future
              </button>
            )}
            {isChurnedInTheFuture && isChurnedInTheFuture !== 'no date' && (
              <button type="button" className="button" onClick={this.modalChurned.showModal}>
                🗑 Remove Churn in the future
              </button>
            )}
            {isReactivatedInTheFuture && isReactivatedInTheFuture !== 'no date' && (
              <button type="button" className="button" onClick={this.modalActivate.showModal}>
                🗑 Remove Reactivate in the future
              </button>
            )}
          </>
        )}
        <Modal
          ref={instance => {
            this.modal = instance;
          }}
          handler={this.scheduleEventAndShowAlert}
          title="Schedule event"
          message={`Will the user: ${userId} ${event} on ${formattedDate}?`}
        />
        <Modal
          ref={instance => {
            this.modalPause = instance;
          }}
          handler={this.handleRemovePause}
          title="Remove Pause in the future"
          message={`Should User: ${userId} will not be paused in the future?`}
        />
        <Modal
          ref={instance => {
            this.modalChurned = instance;
          }}
          handler={this.handleRemoveChurn}
          title="Remove Churn in the future"
          message={`Should User: ${userId} will not be churned in the future?`}
        />
        <Modal
          ref={instance => {
            this.modalActivate = instance;
          }}
          handler={this.handleRemoveReactivate}
          title="Remove Re-activate in the future"
          message={`Should User: ${userId} will not be reactivated in the future?`}
        />

        <Message
          ref={instance => {
            this.message = instance;
          }}
          text={this.state.message}
        />
      </span>
    );
  }
}
export default SubscriptionScheduleChangeButton;
