import React from 'react';

const CouponCodeFormatter = props => {
  const { value } = props;
  if (!value) return '';

  const styles = {
    position: 'relative',
    background: '#d9c3f9',
    fontSize: '8px',
    textAlign: 'center',
    width: 'fit-content',
    padding: '0px 8px',
    color: '#36035f',
    height: '19px',
    lineHeight: '19px',
    textTransform: 'uppercase',
    fontWeight: '800',
    fontFamily: 'monospace',
    display: 'inline-block',
    borderRadius: '2px',
  };

  return (
    <a href={`/redeems/${value}`} rel="noopener noreferrer" target="_blank" style={styles}>
      {value || ''}
    </a>
  );
};

export default CouponCodeFormatter;
